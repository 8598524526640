import "../../css/page/invoiceList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { Add, DeleteOutline, FolderOpen } from "@material-ui/icons";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const LIMIT = 10;

const CreditNotes = () => {
  const [creditNotes, setCreditNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [creditNotesCount, setCreditNotesCount] = useState(0);
  const [deleteCreditNoteID, setDeleteCreditNoteID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchCreditNotesCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/creditNotes/count`, {
      params: {
        search: search,
        fields: search ? ["creditNoteNumber"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchCreditNotes = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/creditNotes`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search,
        fields: search ? ["creditNoteNumber"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getCreditNotesCount = async () => {
      const count = await fetchCreditNotesCount();
      setCreditNotesCount(count);
    };

    const getCreditNotes = async () => {
      const creditNotes = await fetchCreditNotes();
      setLoading(false);
      setCreditNotes(creditNotes);
    };

    getCreditNotesCount().catch(() => (err) => {
      console.log(err);
    });

    getCreditNotes().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: "creditNoteNumber", headerName: "N°", flex: 1 },
    {
      field: "client",
      headerName: "Client",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/*{params.row.client.image && <img className="userListImg" src={params.row.client.image} alt="" />}*/}
            {params.row.client.firstname} {params.row.client.lastname}
          </div>
        );
      },
    },
    {
      field: "totalWithTaxes",
      headerName: "Montant total (TVAC)",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="userListUser">{params.row.totalWithTaxes} €</div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {new Date(params.row.createdAt).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/creditNotes/" + params.row._id}>
              <button className="invoiceListEdit">Modifier</button>
            </Link>
            {!params.creditNoteNumber && (
              <DeleteOutline
                className="invoiceListDelete"
                data-id={params.row._id}
                onClick={openDeleteDialog}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePageChange = (page) => {
    if (creditNotes.length <= page * LIMIT) {
      setLoading(true);
      const getCreditNotes = async () => {
        const nextCreditNotes = await fetchCreditNotes(page);
        setLoading(false);
        setCreditNotes((creditNotes) => [...creditNotes, ...nextCreditNotes]);
      };

      getCreditNotes().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();

    const getCreditNotesCount = async () => {
      const count = await fetchCreditNotesCount();
      setCreditNotesCount(count);
    };

    const getCreditNotes = async () => {
      const creditNotes = await fetchCreditNotes();
      setLoading(false);
      setCreditNotes(creditNotes);
    };

    getCreditNotesCount().catch(() => (err) => {
      console.log(err);
    });

    getCreditNotes().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteCreditNoteID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/creditNotes/${deleteCreditNoteID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Note de crédit supprimée avec succès !");

      const creditNotes = await fetchCreditNotes();
      setCreditNotes(creditNotes);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="content">
        <div className="above-table">
          <div className="title-search-container">
            <div className="title">Notes de crédit</div>
            <form onSubmit={onSearchSubmit}>
              <TextField
                id="search-bar"
                style={{ width: 258 }}
                label="Numéro de ticket"
                variant="outlined"
                size="small"
                type="number"
                name="search"
                value={search}
                onChange={onSearchChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
          </div>

          <Link to={"/clients"} className="create-button">
            <div className="create-button-icons">
              <Add />
              <FolderOpen />
            </div>

            <span>Note de crédit</span>
          </Link>
        </div>

        <DataGrid
          rows={creditNotes}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          columns={columns}
          pageSize={LIMIT}
          rowsPerPageOptions={[LIMIT]}
          rowCount={creditNotesCount}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          onPageChange={handlePageChange}
        />
      </div>

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </>
  );
};

export default CreditNotes;
