import { Download, Inventory } from "@mui/icons-material";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../services/axios";
import { CircularProgress } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TicketsMonthlyPaiementReportPDF from "../TicketsMonthlyPaiementReportPDF/TicketsMonthlyPaymentReportPDF";
import Dayjs from "dayjs";
import InventoryPDF from "./InventoryPDF";
import { toast } from "react-toastify";

export default ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState();

  const loadingButton = (
    <div className="create-button-fixed">
      <CircularProgress color="inherit" />
    </div>
  );

  function generatePdf() {
    setLoading(true);
    axiosInstance
      .get(type, {
        params: {
          inventory: true,
          limit: "-1",
          offset: "0",
          sort: "localization itemNumber",
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.result &&
          response.data.result.docs
        ) {
          setPdf(() => {
            return InventoryPDF({ items: response.data.result.docs });
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error("Quelque chose ne s'est pas passé comme prévu");
      });
  }

  return (
    (!pdf &&
      ((!loading && (
        <div className="create-button-fixed" onClick={generatePdf}>
          <>
            <div className="create-button-icons">
              <Inventory />
            </div>
            <span>Générer l'inventaire</span>
          </>
        </div>
      )) ||
        loadingButton)) || (
      <PDFDownloadLink
        document={pdf}
        fileName={`${Dayjs().format("YYYYMMDD")}-${type}-INVENTORY.pdf`}
        style={{ textDecoration: "none" }}
      >
        {({ blob, url, internalLoading, error }) => {
          if (internalLoading) return loadingButton;
          if (error) return <div>Une erreur est survenue</div>;

          return (
            <div className="create-button-fixed">
              <div className="create-button-icons">
                <Download />
              </div>
              <span style={{ fontSize: 16 }}>Télécharger l'inventaire</span>
            </div>
          );
        }}
      </PDFDownloadLink>
    )
  );
};
