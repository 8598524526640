import "../../css/page/userList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { Add, DeleteOutline, Person } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";

const LIMIT = 10;

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchClientNumber, setSearchClientNumber] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [clientsCount, setClientsCount] = useState(0);
  const [deleteClientID, setDeleteClientID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchClientsCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/clients/count`, {
      params: {
        search: searchClientNumber || searchClientName,
        fields: searchClientName
          ? ["lastname", "firstname"]
          : searchClientNumber
          ? ["clientNumber"]
          : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchClients = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/clients`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchClientNumber || searchClientName,
        fields: searchClientName ? ["lastname", "firstname"] : ["clientNumber"],
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteClientID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/clients/${deleteClientID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Client supprimé avec succès !");

      const clients = await fetchClients();
      setClients(clients);
    }

    setDeleteClientID(null);
    setLoading(false);
  };

  useEffect(() => {
    const getClientsCount = async () => {
      const count = await fetchClientsCount();
      setClientsCount(count);
    };

    const getClients = async () => {
      const clients = await fetchClients();
      setLoading(false);
      setClients(clients);
    };

    getClientsCount().catch((err) => {
      console.log(err);
    });

    getClients().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: "clientNumber", headerName: "N°", flex: 1 },
    {
      field: "firstname",
      headerName: "Prénom",
      flex: 1,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.firstname}</div>;
      },
    },
    {
      field: "lastname",
      headerName: "Nom",
      flex: 1,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.lastname}</div>;
      },
    },
    {
      field: "Phone",
      headerName: "Téléphone",
      flex: 1,
      minWidth: 360,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.mobileNumber}{" "}
            {`${params.row.mobileNumber && params.row.phoneNumber && " - "}`}{" "}
            {params.row.phoneNumber}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 360,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/clients/${params.row._id}/invoices`}>
              <button className="userListInvoices">Factures</button>
            </Link>
            <Link to={`/clients/${params.row._id}/creditNotes`}>
              <button className="userListCreditNotes">Notes de crédit</button>
            </Link>
            <Link to={"/clients/" + params.row._id}>
              <button className="userListEdit">Modifier</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              data-id={params.row._id}
              onClick={openDeleteDialog}
            />
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    if (clients.length <= page * LIMIT) {
      setLoading(true);

      const getClients = async () => {
        const nextClients = await fetchClients(page);
        setLoading(false);
        setClients((clients) => [...clients, ...nextClients]);
      };

      getClients().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const onSearchClientNumberChange = (e) => {
    setSearchClientName("");
    setSearchClientNumber(e.target.value);
  };

  const onSearchClientNameChange = (e) => {
    setSearchClientNumber("");
    setSearchClientName(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const getClientsCount = async () => {
      const count = await fetchClientsCount();
      setClientsCount(count);
    };

    const getClients = async () => {
      const clients = await fetchClients();
      setLoading(false);
      setClients(clients);
    };

    getClientsCount().catch((err) => {
      console.log(err);
    });

    getClients().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Clients</div>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-client-number"
              style={{ width: 258 }}
              label="Numéro de client"
              variant="outlined"
              size="small"
              type="number"
              name="search"
              value={searchClientNumber}
              onChange={onSearchClientNumberChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-client-name"
              style={{ width: 258 }}
              label="Nom ou prénom du client"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={searchClientName}
              onChange={onSearchClientNameChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
        </div>

        <Link to={"/newClient"} className="create-button">
          <div className="create-button-icons">
            <Add />
            <Person />
          </div>

          <span>Client</span>
        </Link>
      </div>

      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        rows={clients}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        rowCount={clientsCount}
        onPageChange={handlePageChange}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </div>
  );
};

export default Clients;
