import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Add } from "@material-ui/icons";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import NewClientModal from "./NewClient";
import Client from "../../pages/Client/Client";

const LIMIT = 10;

const ClientsTable = ({
  isModalAddClientOpen,
  closeModalAddClient,
  addClientToInvoice,
}) => {
  const [searchClientNumber, setSearchClientNumber] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [loadingClients, setLoadingClients] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);
  const [isModalCreateNewClientOpen, setIsModalCreateNewClientOpen] =
    useState(false);
  const [isModalEditClientOpen, setIsModalEditClientOpen] = useState(false);
  const [clientToEditId, setClientToEditId] = useState("");

  const fetchClientsCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/clients/count`, {
      params: {
        search: searchClientNumber || searchClientName,
        fields: searchClientName
          ? ["lastname", "firstname"]
          : searchClientNumber
          ? ["clientNumber"]
          : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchClients = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/clients`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchClientNumber || searchClientName,
        fields: searchClientName ? ["lastname", "firstname"] : ["clientNumber"],
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  const onSubmitSearchClient = async (e) => {
    e.preventDefault();

    setLoadingClients(true);

    const getClientsCount = async () => {
      const productsCount = await fetchClientsCount();
      setClientsCount(productsCount);
    };

    const getClients = async () => {
      const nextClients = await fetchClients(0);
      setLoadingClients(false);
      setClients(nextClients);
    };

    getClientsCount().catch((err) => {
      console.error(err);
    });

    getClients().catch((err) => {
      console.log(err);
      setLoadingClients(false);
    });
  };

  const onSearchClientNameChange = (e) => {
    setSearchClientName(e.target.value);
  };

  const onSearchClientNumberChange = (e) => {
    setSearchClientNumber(e.target.value);
  };

  const openModalCreateNewClient = (e) => {
    e.preventDefault();
    setIsModalCreateNewClientOpen(true);
  };

  const closeModalCreateNewClient = () => setIsModalCreateNewClientOpen(false);

  const openModalEditClient = (e) => {
    e.preventDefault();

    const clientId = e.currentTarget.dataset.id;

    if (!clientId) return;

    setClientToEditId(clientId);
    setIsModalEditClientOpen(true);
  };

  const closeModalEditClient = () => {
    setIsModalEditClientOpen(false);
  };

  const columns = [
    { field: "clientNumber", headerName: "N°", width: 150 },
    {
      field: "firstname",
      headerName: "Prénom",
      width: 150,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.firstname}</div>;
      },
    },
    {
      field: "lastname",
      headerName: "Nom",
      width: 150,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.lastname}</div>;
      },
    },
    {
      field: "address",
      headerName: "Adresse",
      width: 380,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.street} - {params.row.postCode} {params.row.city}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <button
              className="userListInvoices"
              data-params={JSON.stringify(params.row)}
              onClick={addClientToInvoice}
            >
              Choisir
            </button>
            <button
              className="userListEdit"
              data-id={params.row.id}
              onClick={openModalEditClient}
            >
              Modifier
            </button>
          </>
        );
      },
    },
  ];

  const handlePageChangeClients = (page) => {
    if (clients.length <= page * LIMIT) {
      setLoadingClients(true);

      const getClientsCount = async () => {
        const clientsCount = await fetchClientsCount();
        setClientsCount(clientsCount);
      };

      const getClients = async () => {
        const nextClients = await fetchClients(page);
        setLoadingClients(false);
        setClients((products) => [...products, ...nextClients]);
      };

      getClientsCount().catch((err) => {
        console.error(err);
      });

      getClients().catch((err) => {
        console.log(err);
        setLoadingClients(false);
      });
    }
  };

  useEffect(() => {
    if (
      isModalAddClientOpen &&
      !isModalCreateNewClientOpen &&
      !isModalEditClientOpen
    ) {
      setLoadingClients(true);

      const getClientsCount = async () => {
        const clientsCount = await fetchClientsCount();
        setClientsCount(clientsCount);
      };

      const getClients = async () => {
        const nextProducts = await fetchClients(0);
        setLoadingClients(false);
        setClients(nextProducts);
      };

      getClientsCount().catch((err) => {
        console.error(err);
      });

      getClients().catch((err) => {
        console.log(err);
        setLoadingClients(false);
      });
    }
  }, [isModalAddClientOpen, isModalCreateNewClientOpen, isModalEditClientOpen]);

  return (
    <>
      <Dialog
        open={isModalAddClientOpen}
        onClose={closeModalAddClient}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Rechercher un client</DialogTitle>
        <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
          <DialogContent style={{ display: "flex" }}>
            <div style={{ display: "flex", gap: "2rem" }}>
              <form onSubmit={onSubmitSearchClient}>
                <TextField
                  id="search-bar-client-number"
                  style={{ width: 258 }}
                  label="Numéro de client"
                  variant="outlined"
                  size="small"
                  type="number"
                  name="search"
                  value={searchClientNumber}
                  onChange={onSearchClientNumberChange}
                />
                <IconButton type="submit" aria-label="search">
                  <SearchIcon style={{ fill: "#E4221D" }} />
                </IconButton>
              </form>
              <form onSubmit={onSubmitSearchClient}>
                <TextField
                  id="search-bar-client-name"
                  style={{ width: 258 }}
                  label="Nom ou prénom du client"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="search"
                  value={searchClientName}
                  onChange={onSearchClientNameChange}
                />
                <IconButton type="submit" aria-label="search">
                  <SearchIcon style={{ fill: "#E4221D" }} />
                </IconButton>
              </form>
            </div>
          </DialogContent>
          <div className="addProductItem">
            <div className="create-button" onClick={openModalCreateNewClient}>
              <div className="create-button-icons">
                <Add />
              </div>
              <span>Client</span>
            </div>
          </div>
        </div>

        <DialogContent style={{ height: 800 }}>
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={loadingClients}
            rows={clients}
            getRowId={(row) => row._id}
            columns={columns}
            pageSize={LIMIT}
            rowsPerPageOptions={[LIMIT]}
            rowCount={clientsCount}
            onPageChange={handlePageChangeClients}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalAddClient}>Annuler</Button>
        </DialogActions>
      </Dialog>

      <NewClientModal
        isModalCreateNewClientOpen={isModalCreateNewClientOpen}
        closeModalCreateNewClient={closeModalCreateNewClient}
      />

      <Dialog
        open={isModalEditClientOpen}
        onClose={closeModalEditClient}
        fullWidth
        maxWidth="lg"
      >
        <Client
          id={clientToEditId}
          closeModalEditClient={closeModalEditClient}
        />
      </Dialog>
    </>
  );
};

export default ClientsTable;
