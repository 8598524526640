import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  invoiceDetails: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 18,
    textAlign: "center",
    fontStyle: "bold",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 10,
    paddingLeft: 8,
    paddingRight: 8,
  },
  container: {
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 18,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "85%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },

  price: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },

});

const TicketMonthlyPaymentTableHeader = () => {

  return (
    <View>
      <View style={styles.container}>
        <Text
          style={styles.description        }
        >
          Méthode de paiement
        </Text>
        <Text style={styles.price}>Total</Text>
      </View>
    </View>
  );
};

export default TicketMonthlyPaymentTableHeader;
