import "../../css/page/productList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  LinearProgress,
  TextField,
} from "@mui/material";
import { endpoint } from "../../utils/API";
import { Download } from "@mui/icons-material";
import { Font, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import TicketsReportPDF from "../../components/TicketsReportPDF/TicketsReportPDF";
import TicketPDF from "../../components/TicketPDF/TicketPDF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kvnz.woff2",
    },
  ],
});

const LIMIT = 10;

const TicketsReport = () => {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchTicketsCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/tickets/count`, {
      params: {
        date: date,
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchTickets = async (date, page) => {
    const { data } = await axiosInstance.get(`${endpoint}/tickets`, {
      params: {
        date: date,
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  const columns = [
    { field: "ticketNumber", headerName: "N°", width: 90 },
    {
      field: "client",
      headerName: "Client",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/*{params.row.client.image && <img className="userListImg" src={params.row.client.image} alt="" />}*/}
            {params.row.client &&
              `${params.row.client.firstname} ${params.row.client.lastname}`}
          </div>
        );
      },
    },
    {
      field: "totalWithTaxes",
      headerName: "Montant total (TVAC)",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">{params.row.totalWithTaxes} €</div>
        );
      },
    },
    {
      field: "payments",
      headerName: "Paiements",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.payments.map((payment, index) => (
              <div key={index}>
                {payment.paymentMethod} - {payment.paymentAmount} €
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: "remaining",
      headerName: "Solde dû",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.totalWithTaxes -
              params.row.payments.reduce(
                (acc, payment) => acc + payment.paymentAmount,
                0
              )}{" "}
            €
          </div>
        );
      },
    },
    {
      field: "infos.structuredCommunication",
      headerName: "Communication structurée",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.infos.structuredCommunication}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {new Date(params.row.createdAt).toLocaleDateString()}
          </div>
        );
      },
    },
  ];

  const handleDateOnChange = async (event) => {
    event.preventDefault();

    setCurrentPage(0);
    setDate(event.target.value);
  };

  const generateTicketName = () => {
    const year = new Date().getFullYear().toString().substring(2);
    const month = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const day = ("0" + new Date().getDate()).slice(-2);

    return `BILAN_TICKETS_${year}${month}${day}`;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (tickets.length <= page * LIMIT) {
      setLoading(true);
      const getTickets = async () => {
        const nextTickets = await fetchTickets(date, page);
        setLoading(false);
        setTickets((tickets) => [...tickets, ...nextTickets]);
      };

      getTickets().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setLoading(true);

    const getTicketsCount = async () => {
      const count = await fetchTicketsCount(date);
      setTicketsCount(count);
    };

    const getTickets = async () => {
      const tickets = await fetchTickets(date, currentPage);
      setLoading(false);
      setTickets(tickets);
    };

    getTicketsCount().catch((err) => {
      console.log(err);
    });

    getTickets().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, [date]);

  return (
    <div className="content">
      <div className="productListTitleContainer">
        <h1 className="title">Bilan journalier des tickets</h1>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: 10,
          margin: 10,
        }}
      >
        <TextField type="date" value={date} onChange={handleDateOnChange} />
        <PDFDownloadLink
          document={<TicketsReportPDF tickets={tickets} />}
          fileName={generateTicketName() + ".pdf"}
          style={{ textDecoration: "none" }}
        >
          {({ blob, url, loading, error }) => {
            if (loading) return <CircularProgress size={24} />;
            if (error) return <div>Une erreur est survenue</div>;

            return (
              <div className="create-button">
                <div className="create-button-icons">
                  <Download />
                </div>

                <span style={{ fontSize: 16 }}>
                  Télécharger le bilan journalier
                </span>
              </div>
            );
          }}
        </PDFDownloadLink>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 10,
          height: "100%",
        }}
      >
        <DataGrid
          rows={tickets}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          columns={columns}
          pageSize={LIMIT}
          rowsPerPageOptions={[LIMIT]}
          rowCount={ticketsCount}
          onPageChange={handlePageChange}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          page={currentPage}
        />
        <PDFViewer
          style={{ flex: 1, height: "100%", width: "100%" }}
          showToolbar={true}
        >
          <TicketsReportPDF tickets={tickets} date={date} />
        </PDFViewer>
      </div>
    </div>
  );
};

export default TicketsReport;
