import { Box, Fade, InputLabel, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import { TEMPLATE_PRODUCT } from "../../templates";
import { handleOnKeyDownEnter } from "utils/misc";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewMaintenanceModal = ({
  isModalCreateNewMaintenanceOpen,
  closeModalCreateNewMaintenance,
}) => {
  const [newProductForm, setNewProductForm] = useState({
    ...TEMPLATE_PRODUCT,
  });

  const addNewProduct = async (e) => {
    e.preventDefault();

    const newProduct = {
      itemNumber: newProductForm.itemNumber,
      name: newProductForm.name,
      description: newProductForm.description,
      sellingPrice: Number.parseFloat(newProductForm.sellingPrice),
      sellingPriceWithTaxes: Number.parseFloat(
        newProductForm.sellingPriceWithTaxes
      ),
    };

    await axiosInstance
      .post(`${endpoint}/maintenances`, newProduct)
      .then(async () => {
        toast.success("Travail ajouté avec succès !");
        setNewProductForm({ ...TEMPLATE_PRODUCT });
        closeModalCreateNewMaintenance();
      })
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'ajout du travail !")
      );
  };

  const updateNewProductForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setNewProductForm({ ...newProductForm, [field.name]: field.value });
  };

  return (
    <Modal
      open={isModalCreateNewMaintenanceOpen}
      onClose={closeModalCreateNewMaintenance}
    >
      <Fade in={isModalCreateNewMaintenanceOpen}>
        <Box sx={boxStyle}>
          <h1 className="addProductTitle">Nouveau travail</h1>
          <form
            className="addProductForm"
            onSubmit={addNewProduct}
            onKeyDown={handleOnKeyDownEnter}
          >
            <div className="addProductItem">
              <InputLabel htmlFor="itemNumber">N° d'article</InputLabel>
              <TextField
                type="text"
                value={newProductForm.itemNumber}
                name="itemNumber"
                onChange={updateNewProductForm}
                placeholder="motul-100000"
                inputProps={{
                  className: "upperCase",
                }}
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="name" required>
                Nom
              </InputLabel>
              <TextField
                type="text"
                value={newProductForm.name}
                name="name"
                onChange={updateNewProductForm}
                placeholder="Apple Airpods"
                required
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="description">Description</InputLabel>
              <TextField
                type="text"
                value={newProductForm.description}
                name="description"
                onChange={updateNewProductForm}
                placeholder="Écouteurs de la marque Apple"
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="sellingPriceWithTaxes" required>
                Prix de vente unitaire TTC
              </InputLabel>
              <TextField
                inputProps={{ step: "0.01" }}
                type="number"
                value={newProductForm.sellingPriceWithTaxes}
                name="sellingPriceWithTaxes"
                onChange={updateNewProductForm}
                required
              />
            </div>
            <button type="submit" className="addProductButton">
              Créer le travail
            </button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewMaintenanceModal;
