import { roundToDecimalPlaces } from "utils/Financials";

const getSellingPrice = (item) => {
  const isVehicleUsed = item.type === "vehicleUsed";
  const isNegativeQuantity = item.quantity < 0;

  return isVehicleUsed && isNegativeQuantity
    ? item.purchasePrice
    : item.sellingPrice;
};

const getDiscount = (item) => {
  const isItemDiscounted = item.discount && item.discount > 0;

  return isItemDiscounted ? roundToDecimalPlaces(item.discount) : 0;
};

const getVatPercentage = (item) => {
  const isVehicleUsed = item.type === "vehicleUsed";
  const isNegativeQuantity = item.quantity < 0;

  if (isVehicleUsed && isNegativeQuantity) {
    return 0;
  }

  return roundToDecimalPlaces(item.vatPercentage, 2, true);
};
const calculateTotalRow = (item) => {
  const isVehicleUsed = item.type === "vehicleUsed";
  const quantity = roundToDecimalPlaces(item.quantity);
  const discount = roundToDecimalPlaces(item.discount);
  const vatPercentage = roundToDecimalPlaces(item.vatPercentage);
  const purchasePrice = roundToDecimalPlaces(item.purchasePrice);
  const sellingPriceWithTaxes = roundToDecimalPlaces(
    item.sellingPriceWithTaxes
  );
  const isNegativeQuantity = quantity < 0;
  const isItemDiscounted = discount && discount > 0;

  let actualPrice = sellingPriceWithTaxes;

  if (isVehicleUsed && isNegativeQuantity) {
    actualPrice = purchasePrice;

    if (isItemDiscounted) {
      actualPrice = roundToDecimalPlaces(
        actualPrice - roundToDecimalPlaces((actualPrice * discount) / 100)
      );
    }

    // A negative quantity means that the item has no taxes so the totalWithoutTaxes === totalWithTaxes
    return roundToDecimalPlaces(actualPrice * quantity);
  }

  if (isItemDiscounted) {
    const sellingPriceWithoutTaxes = roundToDecimalPlaces(
      actualPrice / (1 + roundToDecimalPlaces(vatPercentage / 100))
    );

    actualPrice = roundToDecimalPlaces(
      sellingPriceWithoutTaxes -
        roundToDecimalPlaces((sellingPriceWithoutTaxes * discount) / 100)
    );

    return roundToDecimalPlaces(actualPrice * quantity);
  }

  const totalWithTaxes = roundToDecimalPlaces(actualPrice * quantity);

  return roundToDecimalPlaces(
    totalWithTaxes / (1 + roundToDecimalPlaces(vatPercentage / 100))
  );
};

const calculateTotals = (items) => {
  let totalWithoutTaxes = 0;
  let totalTaxes = 0;
  let totalWithTaxes = 0;

  items.forEach((item) => {
    const isVehicleUsed = item.type === "vehicleUsed";
    const quantity = roundToDecimalPlaces(item.quantity);
    const discount = roundToDecimalPlaces(item.discount);
    const vatPercentage = roundToDecimalPlaces(item.vatPercentage);
    const purchasePrice = roundToDecimalPlaces(item.purchasePrice);
    const sellingPriceWithTaxes = roundToDecimalPlaces(
      item.sellingPriceWithTaxes
    );
    const isNegativeQuantity = quantity < 0;
    const isItemDiscounted = discount && discount > 0;

    let actualPrice = sellingPriceWithTaxes;

    if (isVehicleUsed && isNegativeQuantity) {
      actualPrice = purchasePrice;

      if (isItemDiscounted) {
        actualPrice = roundToDecimalPlaces(
          actualPrice - roundToDecimalPlaces((actualPrice * discount) / 100)
        );
      }

      // A negative quantity means that the item has no taxes so the totalWithoutTaxes === totalWithTaxes
      totalWithoutTaxes = roundToDecimalPlaces(actualPrice * quantity);
      totalWithTaxes = totalWithoutTaxes;
      totalTaxes = roundToDecimalPlaces(totalWithoutTaxes);

      return;
    }

    if (isItemDiscounted) {
      const sellingPriceWithoutTaxes = roundToDecimalPlaces(
        actualPrice / (1 + roundToDecimalPlaces(vatPercentage / 100))
      );

      actualPrice = roundToDecimalPlaces(
        sellingPriceWithoutTaxes -
          roundToDecimalPlaces((sellingPriceWithoutTaxes * discount) / 100)
      );

      totalWithoutTaxes = roundToDecimalPlaces(
        totalWithoutTaxes + roundToDecimalPlaces(actualPrice * quantity)
      );
      totalWithTaxes = roundToDecimalPlaces(
        totalWithTaxes +
          roundToDecimalPlaces(
            actualPrice *
              quantity *
              (1 + roundToDecimalPlaces(vatPercentage / 100))
          )
      );
      totalTaxes = roundToDecimalPlaces(totalWithTaxes - totalWithoutTaxes);

      return;
    }

    totalWithTaxes = roundToDecimalPlaces(
      totalWithTaxes + roundToDecimalPlaces(actualPrice * quantity)
    );

    totalWithoutTaxes = roundToDecimalPlaces(
      totalWithoutTaxes +
        roundToDecimalPlaces(actualPrice * quantity) /
          (1 + roundToDecimalPlaces(vatPercentage / 100))
    );

    totalTaxes = roundToDecimalPlaces(totalWithTaxes - totalWithoutTaxes);
  });

  return [totalWithoutTaxes, totalTaxes, totalWithTaxes];
};

const calculateTotalPaidAmount = (payments) => {
  return payments.reduce((acc, payment) => {
    const paymentAmount = roundToDecimalPlaces(payment.paymentAmount);

    return roundToDecimalPlaces(acc + paymentAmount);
  }, 0);
};

export {
  getSellingPrice,
  getDiscount,
  getVatPercentage,
  calculateTotalRow,
  calculateTotals,
  calculateTotalPaidAmount,
};
