import React, { useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  invoiceDetails: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 10,
    paddingLeft: 8,
    paddingRight: 8,
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rem: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  price: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: "10%",
  },
  amount: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTableHeader = ({
  client,
  invoiceProducts,
  invoiceNumber,
  invoiceDate,
}) => {
  const hasDiscounts = invoiceProducts.some(
    (product) => product.discount && product.discount > 0
  );
  const generateInvoiceNumber = () => {
    if (!invoiceNumber) {
      return "Proforma";
    }

    const year = new Date().getFullYear().toString().substring(2);
    const month = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const day = ("0" + new Date().getDate()).slice(-2);
    const invoiceNumberFormatted = ("0000" + invoiceNumber).slice(-4);

    return `${year}${month}${day}${invoiceNumberFormatted}`;
  };

  return (
    <View>
      <View style={styles.invoiceDetails}>
        <Text>
          Facture {generateInvoiceNumber()} du{" "}
          {new Date(invoiceDate).toLocaleDateString()}
        </Text>
        <Text>Client n° {client.clientNumber}</Text>
      </View>
      <View style={styles.container}>
        <Text
          style={
            hasDiscounts
              ? styles.description
              : { ...styles.description, width: "50%" }
          }
        >
          Description
        </Text>
        <Text style={styles.price}>Prix unitaire</Text>
        <Text style={styles.qty}>Qté</Text>
        {hasDiscounts && <Text style={styles.rem}>Remise (%)</Text>}
        <Text style={styles.rate}>TVA (%)</Text>
        <Text style={styles.amount}>Total (HTVA)</Text>
      </View>
    </View>
  );
};

export default InvoiceTableHeader;
