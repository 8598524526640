import "../../css/page/newInvoice.css";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { endpoint } from "../../utils/API";
import BasicTableProducts from "../../components/BasicTableProducts";
import BasicTableComments from "../../components/BasicTableComments";
import BasicTablePayments from "../../components/BasicTablePayments";
import INFOS from "../../utils/CompanyInfos";
import InvoicePDF from "../../components/InvoicePDF/InvoicePDF";
import {
  Add,
  Person,
  Print,
  Receipt,
  Settings,
  Storefront,
  TwoWheeler,
} from "@material-ui/icons";
import { Download, TireRepair } from "@mui/icons-material";
import Select from "@mui/material/Select";
import ProductsTable from "../../components/Modals/ProductsTable";
import MaintenancesTable from "../../components/Modals/MaintenancesTable";
import VehiclesTable from "../../components/Modals/VehiclesTable";
import VehiclesUsedTable from "../../components/Modals/VehiclesUsedTable";
import ClientsTable from "../../components/Modals/ClientsTable";
import { TEMPLATE_INVOICE } from "../../templates";
import { getStructuredCommunication } from "../../services/Invoices/Invoices.service";

const Invoice = () => {
  const { invoiceId } = useParams();
  const [infos, setInfos] = useState({ ...INFOS });
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoiceDate, setInvoiceDate] = useState("");
  const [inputInvoiceDate, setInputInvoiceDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [comments, setComments] = useState([]);
  const [payments, setPayments] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Virement");
  const [client, setClient] = useState({
    ...TEMPLATE_INVOICE.client,
  });
  const [item, setItem] = useState({});
  const [invoice, setInvoice] = useState({ ...TEMPLATE_INVOICE });
  const [isModalAddMaintenanceOpen, setIsModalAddMaintenanceOpen] =
    useState(false);
  const [isModalAddProductOpen, setIsModalAddProductOpen] = useState(false);
  const [isModalAddVehicleOpen, setIsModalAddVehicleOpen] = useState(false);
  const [isModalAddQuantityOpen, setIsModalAddQuantityOpen] = useState(false);
  const [isModalAddVehicleUsedOpen, setIsModalAddVehicleUsedOpen] =
    useState(false);
  const [isModalAddClientOpen, setIsModalAddClientOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [newPaymentAmount, setNewPaymentAmount] = useState("");

  const fetchInvoice = async () => {
    const { data } = await axiosInstance.get(`/invoices/${invoiceId}`);

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return null;
  };

  useEffect(() => {
    const getInvoice = async () => {
      const result = await fetchInvoice();

      setInvoice(result);
      setInfos(result.infos);
      setInvoiceNumber(result.invoiceNumber);
      setInvoiceDate(result.invoiceDate);
      setInputInvoiceDate(
        new Date(result.invoiceDate).toISOString().split("T")[0]
      );
      setInvoiceProducts(result.products);
      setComments(result.comments);
      setPayments(result.payments);
      setClient(result.client);
    };

    getInvoice().catch((err) => console.log(err));
  }, []);

  const addClientToInvoice = async (e) => {
    const client = JSON.parse(e.target.dataset.params);

    if (!client) {
      closeModalAddClient();
      toast.error("Impossible d'ajouter le client !");

      return;
    }

    await updateToDatabase("client", client)
      .then(() => {
        setClient(client);
        closeModalAddClient();
        toast.success("Client assigné avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const addItemToInvoice = (e) => {
    const item = JSON.parse(e.target.dataset.params);

    if (!item) {
      closeModalAddMaintenance();
      closeModalAddProduct();
      closeModalAddVehicle();
      closeModalAddVehicleUsed();
      toast.error("Impossible de trouver l'article !'");

      return;
    }

    setItem(item);
    openModalAddQuantity();
  };

  const addQuantity = async (e) => {
    e.preventDefault();

    const quantity = Number.parseFloat(e.currentTarget.quantity.value);

    if (isNaN(quantity)) {
      closeModalAddQuantity();
      toast.error("Quantité incorrecte !");

      return;
    }

    const existingProduct = invoiceProducts.find(
      (invoiceProduct) => invoiceProduct._id === item._id
    );

    if (existingProduct) {
      const updatedExistingProduct = {
        ...existingProduct,
        quantity: existingProduct.quantity + quantity,
      };

      const updatedInvoiceProducts = invoiceProducts.map((invoiceProduct) => {
        if (invoiceProduct._id === item._id) {
          return updatedExistingProduct;
        }

        return invoiceProduct;
      });

      await updateToDatabase("products", updatedInvoiceProducts)
        .then(() => {
          setInvoiceProducts(updatedInvoiceProducts);
          closeModals();
        })
        .catch(() => toast.error("Erreur rencontrée !"));

      return;
    }

    const invoiceProduct = {
      ...item,
      quantity: quantity,
    };

    await updateToDatabase("products", [...invoiceProducts, invoiceProduct])
      .then(() => {
        setInvoiceProducts([...invoiceProducts, invoiceProduct]);
        closeModals();
        toast.success("Article ajouté avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const closeModals = () => {
    closeModalAddQuantity();
    closeModalAddMaintenance();
    closeModalAddProduct();
    closeModalAddVehicle();
    closeModalAddVehicleUsed();
  };

  const addComment = async (e) => {
    e.preventDefault();

    if (newComment.length === 0) {
      toast.error("Veuillez ajouter un commentaire !");

      return;
    }

    updateToDatabase("comments", [...comments, newComment])
      .then(() => {
        setComments([...comments, newComment]);
        setNewComment("");
        toast.success("Commentaire ajouté avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const handleCommentChange = (e) => {
    setNewComment(e.currentTarget.value);
  };

  const addPayment = (e) => {
    e.preventDefault();

    const paymentAmount = Number.parseFloat(newPaymentAmount.replace(",", "."));

    const newPayment = {
      paymentDate: e.currentTarget.paymentDate.value,
      paymentAmount: paymentAmount,
      paymentMethod: e.currentTarget.paymentMethod.value,
    };

    if (newPayment.length === 0) {
      toast.error("Veuillez ajouter un paiement !");

      return;
    }

    updateToDatabase("payments", [...payments, newPayment])
      .then(() => {
        setPayments([...payments, newPayment]);
        setNewPaymentAmount("");
        toast.success("Paiment ajouté avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const handlePaymentAmountChange = (e) => {
    setNewPaymentAmount(e.currentTarget.value);
  };

  const updateToDatabase = async (fieldToUpdate, fieldValue) => {
    if (!client._id || client._id === "") {
      toast.error("Veuillez sélectionner un client !");

      return;
    }

    const invoiceToUpdate = {
      invoiceDate: invoiceDate,
      client: client,
      products: invoiceProducts,
      infos: infos,
      payments: payments,
      comments: comments,
      [fieldToUpdate]: fieldValue,
    };

    return await axiosInstance
      .put(`${endpoint}/invoices/${invoice._id}`, invoiceToUpdate)
      .then((response) => response)
      .catch((err) => err);
  };

  useEffect(() => {
    setInfos((prevState) => ({
      ...prevState,
      structuredCommunication: getStructuredCommunication(
        invoiceDate,
        invoiceNumber
      ),
    }));
  }, [invoiceDate, invoiceNumber]);

  const generateInvoiceNumber = () => {
    const year = new Date().getFullYear().toString().substring(2);
    const month = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const day = ("0" + new Date().getDate()).slice(-2);
    const invoiceNumber = (
      invoice.invoiceNumber ? "0000" + invoice.invoiceNumber : "00000"
    ).slice(-4);

    return `${year}${month}${day}${invoiceNumber}`;
  };

  const handleChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const openModalAddClient = useCallback(() => {
    setIsModalAddClientOpen(true);
  }, []);

  const closeModalAddClient = useCallback(() => {
    setIsModalAddClientOpen(false);
  }, []);

  const openModalAddMaintenance = useCallback(() => {
    setIsModalAddMaintenanceOpen(true);
  }, []);

  const closeModalAddMaintenance = useCallback(() => {
    setIsModalAddMaintenanceOpen(false);
  }, []);

  const openModalAddProduct = useCallback(() => {
    setIsModalAddProductOpen(true);
  }, []);

  const closeModalAddProduct = useCallback(() => {
    setIsModalAddProductOpen(false);
  }, []);

  const openModalAddVehicle = useCallback(() => {
    setIsModalAddVehicleOpen(true);
  }, []);

  const closeModalAddVehicle = useCallback(() => {
    setIsModalAddVehicleOpen(false);
  }, []);

  const openModalAddVehicleUsed = useCallback(() => {
    setIsModalAddVehicleUsedOpen(true);
  }, []);

  const closeModalAddVehicleUsed = useCallback(() => {
    setIsModalAddVehicleUsedOpen(false);
  }, []);

  const openModalAddQuantity = useCallback(() => {
    setIsModalAddQuantityOpen(true);
  }, []);

  const closeModalAddQuantity = useCallback(() => {
    setIsModalAddQuantityOpen(false);
  }, []);

  const applyInvoiceNumber = async () => {
    if (!client._id || client._id === "") {
      toast.error("Veuillez sélectionner un client !");

      return;
    }

    if (invoiceProducts.length === 0) {
      toast.error("Veuillez ajouter au moins un produit !");

      return;
    }

    updateToDatabase("applyInvoiceNumber", true)
      .then((response) => {
        if (response.data.success) {
          const result = response.data.result;

          setInvoice(result);
          setInfos(result.infos);
          setInvoiceNumber(result.invoiceNumber);
          setInvoiceDate(result.invoiceDate);
          setInvoiceProducts(result.products);
          setComments(result.comments);
          setPayments(result.payments);
          setClient(result.client);
          toast.success("Numéro de facture associé avec succès !");

          return;
        }

        toast.error("Erreur rencontrée !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const printPDF = () => {
    const invoicePDF = document.getElementById("invoicePDF");

    if (!invoicePDF) {
      return;
    }

    invoicePDF.contentWindow.focus();
    invoicePDF.contentWindow.print();
  };

  const handleInvoiceDateChange = (event) => {
    const date = new Date(event.target.value);

    if (isNaN(date.getTime())) {
      return;
    }

    const invoiceDateIso = date.toISOString().split("T")[0];
    setInputInvoiceDate(invoiceDateIso);
  };

  const updateInvoiceDate = () => {
    const date = new Date(inputInvoiceDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const invoiceDateIso = date.toISOString();

    updateToDatabase("invoiceDate", invoiceDateIso)
      .then(() => {
        setInvoiceDate(invoiceDateIso);
        toast.success("Date modifiée avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Modifier la facture</div>
          <div className="invoice-number">
            <span>Facture N° </span>
            <span>{invoice.invoiceNumber}</span>
          </div>
        </div>

        <div className="download-print-buttons">
          <PDFDownloadLink
            document={
              <InvoicePDF
                invoiceNumber={invoiceNumber}
                invoiceDate={invoiceDate}
                client={client}
                infos={infos}
                invoiceProducts={invoiceProducts}
                comments={comments}
                payments={payments}
              />
            }
            fileName={generateInvoiceNumber() + ".pdf"}
            style={{ textDecoration: "none" }}
          >
            {({ blob, url, loading, error }) => {
              if (loading)
                return (
                  <CircularProgress
                    size={24}
                    color="error"
                    style={{ margin: "0 130px" }}
                  />
                );
              if (error) return <div>Une erreur est survenue</div>;

              return (
                <div className="create-button">
                  <div className="create-button-icons">
                    <Download />
                  </div>

                  <span>Télécharger la facture</span>
                </div>
              );
            }}
          </PDFDownloadLink>

          <div className="create-button" onClick={printPDF}>
            <div className="create-button-icons">
              <Print />
            </div>

            <span>Imprimer la facture</span>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
        <div className="section">
          <div style={{ flex: 0.5 }}>
            <div className="addProductItem">
              {invoiceNumber ? (
                <div className="create-button disabled">
                  <div className="create-button-icons">
                    <span>{invoice.invoiceNumber}</span>
                  </div>
                </div>
              ) : (
                <div className="create-button" onClick={applyInvoiceNumber}>
                  <div className="create-button-icons">
                    <Receipt />
                  </div>
                  <span>Attribuer un numéro de facture</span>
                </div>
              )}
            </div>

            <div className="divider" />

            <div className="addProductItem">
              <div className="clientInfos">
                {client._id ? (
                  <>
                    <span>N°{client.clientNumber}</span> -
                    <span>{client.firstname}</span>
                    <span>{client.lastname}</span>
                  </>
                ) : (
                  <span>Veuillez sélectionner un client</span>
                )}
              </div>
              <div>
                <div className="addProductItem">
                  <div className="create-button" onClick={openModalAddClient}>
                    <div className="create-button-icons">
                      <Person />
                    </div>
                    <span>Rechercher un client</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="divider" />

            <div className="search-products-container">
              <div className="addProductItem">
                <div
                  className="create-button"
                  onClick={openModalAddMaintenance}
                >
                  <div className="create-button-icons">
                    <Settings />
                  </div>
                  <span>Travail</span>
                </div>
              </div>

              <div className="addProductItem">
                <div className="create-button" onClick={openModalAddProduct}>
                  <div className="create-button-icons">
                    <Storefront />
                  </div>
                  <span>Produit</span>
                </div>
              </div>

              <div className="addProductItem">
                <div className="create-button" onClick={openModalAddVehicle}>
                  <div className="create-button-icons">
                    <TwoWheeler />
                  </div>
                  <span>Véhicule</span>
                </div>
              </div>

              <div className="addProductItem">
                <div
                  className="create-button"
                  onClick={openModalAddVehicleUsed}
                >
                  <div className="create-button-icons">
                    <TireRepair />
                  </div>
                  <span>Véhicule d'occasion</span>
                </div>
              </div>
            </div>

            {invoiceProducts.length > 0 && (
              <BasicTableProducts
                invoiceProducts={invoiceProducts}
                setInvoiceProducts={setInvoiceProducts}
                updateToDatabase={updateToDatabase}
              />
            )}
          </div>

          <div style={{ flex: 0.5 }}>
            {comments.length > 0 && (
              <BasicTableComments
                comments={comments}
                setComments={setComments}
                updateToDatabase={updateToDatabase}
              />
            )}
            <form onSubmit={addComment}>
              <div className="addProductItem">
                <TextField
                  id="comment"
                  name="comment"
                  label="Commentaire"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={newComment}
                  onChange={handleCommentChange}
                />
              </div>

              <button type="submit" className="create-button-outline">
                <div className="add-button-icons">
                  <Add />
                </div>
              </button>
            </form>

            <div className="divider" />

            {payments.length > 0 && (
              <BasicTablePayments
                payments={payments}
                setPayments={setPayments}
                updateToDatabase={updateToDatabase}
              />
            )}

            <form onSubmit={addPayment} style={{ marginBottom: 16 }}>
              <div style={{ display: "flex", gap: 16 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Date du paiement</label>
                  <TextField
                    type="date"
                    name="paymentDate"
                    required
                    defaultValue={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label>Montant du paiement</label>
                  <TextField
                    inputProps={{ step: "0.01" }}
                    type="number"
                    placeholder="100"
                    name="paymentAmount"
                    required
                    value={newPaymentAmount}
                    onChange={handlePaymentAmountChange}
                  />
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel id="paymentMethodLabel">
                  Méthode de paiement
                </InputLabel>
                <div style={{ display: "flex", gap: 16 }}>
                  <Select
                    labelId="paymentMethodLabel"
                    id="demo-simple-select"
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={handleChangePaymentMethod}
                    required
                    style={{ flex: 1 }}
                  >
                    <MenuItem value={"Virement"}>Virement</MenuItem>
                    <MenuItem value={"Espèces"}>Espèces</MenuItem>
                    <MenuItem value={"Chèque"}>Chèque</MenuItem>
                    <MenuItem value={"Bancontact"}>Bancontact</MenuItem>
                    <MenuItem value={"Mastercard"}>Mastercard</MenuItem>
                    <MenuItem value={"Visa"}>Visa</MenuItem>
                  </Select>

                  <button type="submit" className="create-button-outline">
                    <div className="add-button-icons">
                      <Add />
                    </div>
                  </button>
                </div>
              </div>
            </form>

            <div className="divider" />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <label>Date de facture</label>
              <TextField
                type="date"
                required
                onBlur={updateInvoiceDate}
                value={inputInvoiceDate}
                onChange={handleInvoiceDateChange}
              />
            </div>
          </div>

          <PDFViewer
            style={{ flex: 1, height: "75vh" }}
            showToolbar={false}
            id="invoicePDF"
          >
            <InvoicePDF
              infos={infos}
              invoiceNumber={invoiceNumber}
              invoiceDate={invoiceDate}
              invoiceProducts={invoiceProducts}
              comments={comments}
              client={client}
              payments={payments}
            />
          </PDFViewer>
        </div>
      </div>

      <ProductsTable
        isModalAddProductOpen={isModalAddProductOpen}
        closeModalAddProduct={closeModalAddProduct}
        addItemToInvoice={addItemToInvoice}
      />

      <MaintenancesTable
        isModalAddMaintenanceOpen={isModalAddMaintenanceOpen}
        closeModalAddMaintenance={closeModalAddMaintenance}
        addItemToInvoice={addItemToInvoice}
      />

      <VehiclesTable
        isModalAddVehicleOpen={isModalAddVehicleOpen}
        closeModalAddVehicle={closeModalAddVehicle}
        addItemToInvoice={addItemToInvoice}
      />

      <VehiclesUsedTable
        isModalAddVehicleOpen={isModalAddVehicleUsedOpen}
        closeModalAddVehicle={closeModalAddVehicleUsed}
        addItemToInvoice={addItemToInvoice}
      />

      <ClientsTable
        isModalAddClientOpen={isModalAddClientOpen}
        closeModalAddClient={closeModalAddClient}
        addClientToInvoice={addClientToInvoice}
      />

      <Dialog
        open={isModalAddQuantityOpen}
        onClose={closeModalAddQuantity}
        fullWidth
      >
        <DialogTitle>Ajouter une quantité</DialogTitle>
        <DialogContent>
          <form className="addProductForm" onSubmit={addQuantity}>
            <div className="addProductItem">
              <InputLabel htmlFor="quantity" required>
                Quantité
              </InputLabel>
              <Input
                type="number"
                name="quantity"
                required
                defaultValue={1}
                inputProps={{ step: "0.01" }}
              />
            </div>
            <button className="addProductButton">Ajouter la quantité</button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalAddQuantity}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Invoice;
