import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DeleteOutline } from "@material-ui/icons";
import { toast } from "react-toastify";
import { roundToDecimalPlaces } from "../utils/Financials";

const BasicTablePayments = ({ payments, setPayments, updateToDatabase }) => {
  const handleDelete = (event) => {
    const index = event.currentTarget.dataset.index;
    const updatedPayments = payments.filter(
      (comment, i) => i !== parseInt(index)
    );

    updateToDatabase("payments", updatedPayments)
      .then(() => {
        setPayments(updatedPayments);
        toast.success("Paiment modifié avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  return (
    <TableContainer component={Paper} style={{ marginBottom: 16 }}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Montant</TableCell>
            <TableCell>Méthode</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {new Date(row.paymentDate).toLocaleDateString()}
              </TableCell>

              <TableCell>
                {roundToDecimalPlaces(row.paymentAmount, 2, true)}
              </TableCell>
              <TableCell>{row.paymentMethod}</TableCell>

              <TableCell align="right">
                <DeleteOutline
                  className="productListDelete"
                  data-index={index}
                  onClick={handleDelete}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTablePayments;
