import "../../css/page/invoiceList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { Add, DeleteOutline, FolderOpen, TwoWheeler } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { endpoint } from "../../utils/API";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Download } from "@mui/icons-material";
import InvoicePDF from "../../components/InvoicePDF/InvoicePDF";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import { roundToDecimalPlaces } from "../../utils/Financials";
import { calculateAmountDueByClient } from "../../services/Invoices/Invoices.service";

const LIMIT = 10;

const Invoices = () => {
  // const { state } = useLocation();
  // const [invoice, setInvoice] = useState(state?.invoice);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [invoicesCount, setInvoicesCount] = useState(0);
  const [deleteInvoiceID, setDeleteInvoiceID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchInvoicesCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/invoices/count`, {
      params: {
        search: search,
        fields: search ? ["infos.structuredCommunication"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchInvoices = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/invoices`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: search,
        fields: search ? ["infos.structuredCommunication"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getInvoicesCount = async () => {
      const count = await fetchInvoicesCount();
      setInvoicesCount(count);
    };

    const getInvoices = async () => {
      const invoices = await fetchInvoices();
      setLoading(false);
      setInvoices(invoices);
    };

    getInvoicesCount().catch(() => (err) => {
      console.log(err);
    });

    getInvoices().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: "invoiceNumber", headerName: "N°", width: 80 },
    {
      field: "client",
      headerName: "Client",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/*{params.row.client.image && <img className="userListImg" src={params.row.client.image} alt="" />}*/}
            {params.row.client.firstname} {params.row.client.lastname}
          </div>
        );
      },
    },
    {
      field: "totalWithTaxes",
      headerName: "Montant total (TVAC)",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="userListUser">{params.row.totalWithTaxes} €</div>
        );
      },
    },
    {
      field: "payments",
      headerName: "Paiements",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="userListUser"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {params.row.payments.map((payment, index) => (
              <div key={index}>
                {payment.paymentMethod} - {payment.paymentAmount.toFixed(2)} €
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: "remaining",
      headerName: "Solde dû",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {calculateAmountDueByClient(params.row).toFixed(2)} €
          </div>
        );
      },
    },
    {
      field: "infos.structuredCommunication",
      headerName: "Communication structurée",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.infos.structuredCommunication}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {new Date(params.row.createdAt).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/invoices/" + params.row._id}>
              <button className="invoiceListEdit">Modifier</button>
            </Link>
            {!params.row.invoiceNumber && (
              <DeleteOutline
                className="invoiceListDelete"
                data-id={params.row._id}
                onClick={openDeleteDialog}
              />
            )}
          </>
        );
      },
    },
  ];

  // useEffect(() => {
  //   if (state && state.askForDownload) {
  //     setOpenDialog(() => true);
  //   }
  // }, [state]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePageChange = (page) => {
    if (invoices.length <= page * LIMIT) {
      setLoading(true);
      const getInvoices = async () => {
        const nextInvoices = await fetchInvoices(page);
        setLoading(false);
        setInvoices((invoices) => [...invoices, ...nextInvoices]);
      };

      // getInvoicesCount and count
      getInvoices().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  // const generateFileName = () => {
  //   if (state && state.invoice) {
  //     const year = new Date().getFullYear().toString().substring(2);
  //     const month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  //     const day = ("0" + new Date().getDate()).slice(-2);
  //     const invoiceNumber = ("0000" + state.invoice.invoiceNumber).slice(-4);
  //
  //     return `${year}${month}${day}${invoiceNumber}`;
  //   }
  //
  //   return "";
  // };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();

    const getInvoicesCount = async () => {
      const count = await fetchInvoicesCount();
      setInvoicesCount(count);
    };

    const getInvoices = async () => {
      const invoices = await fetchInvoices();
      setLoading(false);
      setInvoices(invoices);
    };

    getInvoicesCount().catch(() => (err) => {
      console.log(err);
    });

    getInvoices().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteInvoiceID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/invoices/${deleteInvoiceID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Facture supprimée avec succès !");

      const invoices = await fetchInvoices();
      setInvoices(invoices);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="content">
        <div className="above-table">
          <div className="title-search-container">
            <div className="title">Factures</div>
            <form onSubmit={onSearchSubmit}>
              <TextField
                id="search-bar"
                style={{ width: 258 }}
                label="Communication structurée"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={search}
                onChange={onSearchChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
          </div>

          <Link to={"/clients"} className="create-button">
            <div className="create-button-icons">
              <Add />
              <FolderOpen />
            </div>

            <span>Facture</span>
          </Link>
        </div>

        <DataGrid
          rows={invoices}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          columns={columns}
          pageSize={LIMIT}
          rowsPerPageOptions={[LIMIT]}
          rowCount={invoicesCount}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          onPageChange={handlePageChange}
        />
      </div>

      {/*{invoice && (*/}
      {/*  <>*/}
      {/*    <Dialog*/}
      {/*      open={openDialog}*/}
      {/*      onClose={handleCloseDialog}*/}
      {/*      aria-labelledby="alert-dialog-title"*/}
      {/*      aria-describedby="alert-dialog-description"*/}
      {/*    >*/}
      {/*      <DialogTitle id="alert-dialog-title">*/}
      {/*        {"Facture créée avec succès !"}*/}
      {/*      </DialogTitle>*/}
      {/*      <DialogContent>*/}
      {/*        <DialogContentText id="alert-dialog-description">*/}
      {/*          Voulez-vous télécharger votre facture ?*/}
      {/*        </DialogContentText>*/}
      {/*      </DialogContent>*/}
      {/*      <DialogActions>*/}
      {/*        <Button onClick={handleCloseDialog}>Ne pas télécharger</Button>*/}

      {/*        <PDFDownloadLink*/}
      {/*          document={*/}
      {/*            <InvoicePDF*/}
      {/*              invoiceNumber={invoice.invoiceNumber}*/}
      {/*              invoiceDate={new Date(*/}
      {/*                invoice.invoiceDate*/}
      {/*              ).toLocaleDateString()}*/}
      {/*              client={invoice.client}*/}
      {/*              infos={invoice.infos}*/}
      {/*              invoiceProducts={invoice.products}*/}
      {/*              comments={invoice.comments}*/}
      {/*              payments={invoice.payments}*/}
      {/*            />*/}
      {/*          }*/}
      {/*          fileName={generateFileName() + ".pdf"}*/}
      {/*          style={{ textDecoration: "none" }}*/}
      {/*        >*/}
      {/*          {({ blob, url, loading, error }) => {*/}
      {/*            if (loading) return <CircularProgress size={24} />;*/}
      {/*            if (error) return <div>Une erreur est survenue</div>;*/}

      {/*            return (*/}
      {/*              <Button*/}
      {/*                variant="contained"*/}
      {/*                color="primary"*/}
      {/*                startIcon={<Download />}*/}
      {/*              >*/}
      {/*                Télécharger la facture*/}
      {/*              </Button>*/}
      {/*            );*/}
      {/*          }}*/}
      {/*        </PDFDownloadLink>*/}
      {/*      </DialogActions>*/}
      {/*    </Dialog>*/}
      {/*  </>*/}
      {/*)}*/}

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </>
  );
};

export default Invoices;
