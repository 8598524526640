import axios from "axios";
import { endpoint } from "../utils/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const axiosInstance = axios.create({
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${endpoint}/refreshToken`, {
      refreshToken: localStorage.getItem("refreshToken"),
    })
    .then((tokenRefreshResponse) => {
      const { accessToken } = tokenRefreshResponse.data.result;

      localStorage.setItem("accessToken", accessToken);

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + accessToken;

      return Promise.resolve();
    })
    .catch((error) => {
      console.log("Refreshing the token has failed !");
      localStorage.setItem("accessToken", null);
      localStorage.setItem("refreshToken", null);

      return Promise.reject(error);
    });

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export default axiosInstance;
