import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { roundForCash, roundToDecimalPlaces } from "utils/Financials";
import TotalForCash from "components/TotalForCash/TotalForCash";
import {
  calculateTotals,
  calculateTotalPaidAmount,
} from "services/Products/Product.service";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    // fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  infos: {
    flexDirection: "column",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    justifyContent: "space-between",
    flexGrow: 1,
    marginTop: 10,
    padding: 8,
  },
});

const InvoiceTableFooter = ({ items, infos, payments }) => {
  const [totalWithoutTaxes, totalTaxes, totalWithTaxes] =
    calculateTotals(items);
  const totalPaid = calculateTotalPaidAmount(payments);
  let cash = false;

  payments.forEach(({ paymentMethod }) => {
    if (paymentMethod === "Espèces") {
      cash = true;
    }
  });

  const totalDue =
    cash && roundForCash(totalWithTaxes) - totalPaid === 0
      ? 0
      : roundToDecimalPlaces(totalWithTaxes - totalPaid, 2);
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.description}>Total (HTVA)</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalWithoutTaxes, 2, true)} €
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>TVA</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalTaxes, 2, true)} €
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total facture</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalWithTaxes, 2, true)} €
        </Text>
      </View>
      <TotalForCash value={totalWithTaxes} />
      <View style={styles.row}>
        <Text style={styles.description}>Solde dû</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalDue, 2, true)} €
        </Text>
      </View>
      {payments.length > 0 && (
        <View style={styles.infos} break>
          {payments.map((payment, index) => (
            <Text key={index}>
              {new Date(payment.paymentDate).toLocaleDateString()} -{" "}
              {payment.paymentMethod} -{" "}
              {roundToDecimalPlaces(payment.paymentAmount, 2, true)} €
            </Text>
          ))}
        </View>
      )}

      <View style={styles.infos} break>
        {}
        {totalDue > 0 && (
          <Text>
            A payer {roundToDecimalPlaces(totalDue, 2, true)} € avec la
            référence *** {infos.structuredCommunication} ***
          </Text>
        )}

        <Text>
          IBAN : {infos.iban} - BIC : {infos.bic}
        </Text>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
