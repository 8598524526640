import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../css/page/product.css";
// import Chart from '../../components/Chart';
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { endpoint } from "../../utils/API";
import { InputLabel, TextField } from "@mui/material";
import { roundToDecimalPlaces } from "../../utils/Financials";
import { Person, Update } from "@material-ui/icons";
import { TEMPLATE_PRODUCT } from "../../templates";
import { handleOnKeyDownEnter } from "utils/misc";

const Maintenance = ({ id, closeModalEditMaintenance }) => {
  const { maintenanceId } = useParams();
  const maintenanceToEdit = id || maintenanceId;
  const navigate = useNavigate();
  const [maintenance, setMaintenance] = useState({ ...TEMPLATE_PRODUCT });
  const [maintenanceDetails, setMaintenanceDetails] = useState({
    ...TEMPLATE_PRODUCT,
  });
  const [isItemNumberExists, setIsItemNumberExists] = useState(true);
  const [isItemNumberSameAsBefore, setIsItemNumberSameAsBefore] =
    useState(true);

  const fetchMaintenance = async () => {
    const { data } = await axiosInstance.get(
      `/maintenances/${maintenanceToEdit}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return null;
  };

  useEffect(() => {
    const getMaintenance = async () => {
      const result = await fetchMaintenance();

      setMaintenance(result);
      setMaintenanceDetails(result);
    };

    getMaintenance().catch((err) => console.log(err));
  }, []);

  const updateForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setMaintenance({ ...maintenance, [field.name]: field.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const updatedMaintenance = {
      itemNumber: maintenance.itemNumber,
      name: maintenance.name,
      description: maintenance.description,
      sellingPrice: roundToDecimalPlaces(
        maintenance.sellingPriceWithTaxes / 1.21,
        6
      ),
      sellingPriceWithTaxes: roundToDecimalPlaces(
        maintenance.sellingPriceWithTaxes,
        2
      ),
      vatPercentage: 21,
    };

    const response = await axiosInstance.put(
      `${endpoint}/maintenances/${maintenance._id}`,
      updatedMaintenance
    );

    if (response.status === 200) {
      toast.success("Travail modifié avec succès !");
      setMaintenanceDetails({ ...updatedMaintenance });

      if (id) {
        closeModalEditMaintenance();

        return;
      }

      navigate("/maintenances");
    } else {
      toast.error("Une erreur est survenue !");
    }
  };

  const checkItemNumberExists = async () => {
    setIsItemNumberSameAsBefore(true);
    setIsItemNumberExists(false);
    const itemNumber = maintenance.itemNumber;

    if (itemNumber) {
      await axiosInstance
        .get(`${endpoint}/maintenances/find/${itemNumber}`)
        .then((response) => {
          if (response.data.result) {
            if (response.data.result._id !== maintenance._id) {
              setIsItemNumberSameAsBefore(false);
            }
            setIsItemNumberExists(true);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <div className="content">
      <div className="productTitleContainer">
        <h1 className="title">Travail</h1>
      </div>
      <div className="editProductContainer">
        <div className="productTop">
          {/*<div className="productTopLeft">*/}
          {/*    <Chart data={productData} dataKey="Sales" title="Ventes"/>*/}
          {/*</div>*/}
          <div className="productTopRight">
            <div className="productInfoTop">
              {/*<img*/}
              {/*  src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"*/}
              {/*  alt=""*/}
              {/*  className="productInfoImg"*/}
              {/*/>*/}
              <span className="productName">{maintenanceDetails.name}</span>
            </div>
            <div className="productInfoBottom">
              <div className="productInfoItem">
                <span className="productInfoKey">N° d'article : </span>
                <span className="productInfoValue">
                  {maintenanceDetails.itemNumber}
                </span>
              </div>
              <div className="productInfoItem">
                <span className="productInfoKey">Description : </span>
                <span className="productInfoValue">
                  {maintenanceDetails.description}
                </span>
              </div>
              <div className="productInfoItem">
                <span className="productInfoKey">
                  Prix de vente unitaire HTVA :{" "}
                </span>
                <span className="productInfoValue">
                  {maintenanceDetails.sellingPrice} €
                </span>
              </div>
              <div className="productInfoItem">
                <span className="productInfoKey">Taux T.V.A. : </span>
                <span className="productInfoValue">
                  {maintenanceDetails.vatPercentage} %
                </span>
              </div>
              <div className="productInfoItem">
                <span className="productInfoKey">
                  Prix de vente unitaire TTC :{" "}
                </span>
                <span className="productInfoValue">
                  {maintenanceDetails.sellingPriceWithTaxes} €
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="productBottom">
          <form
            className="productForm"
            onSubmit={onSubmit}
            onKeyDown={handleOnKeyDownEnter}
          >
            <div className="productFormLeft">
              <div className="addProductItem">
                <InputLabel htmlFor="itemNumber">N° d'article</InputLabel>
                <TextField
                  type="text"
                  value={maintenance.itemNumber}
                  name="itemNumber"
                  onChange={updateForm}
                  placeholder="motul-100000"
                  onBlur={checkItemNumberExists}
                  helperText={
                    isItemNumberExists
                      ? isItemNumberSameAsBefore
                        ? "Référence actuelle"
                        : "Cette référence a déjà été assignée !"
                      : "Référence disponible !"
                  }
                  inputProps={{
                    className: "upperCase",
                  }}
                />
              </div>
              <div className="addProductItem">
                <InputLabel htmlFor="name" required>
                  Nom
                </InputLabel>
                <TextField
                  type="text"
                  value={maintenance.name}
                  name="name"
                  onChange={updateForm}
                  placeholder="Apple Airpods"
                  required
                />
              </div>
              <div className="addProductItem">
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  type="text"
                  value={maintenance.description}
                  name="description"
                  onChange={updateForm}
                  placeholder="Écouteurs de la marque Apple"
                />
              </div>
              <div className="addProductItem">
                <InputLabel htmlFor="sellingPriceWithTaxes" required>
                  Prix de vente unitaire TTC
                </InputLabel>
                <TextField
                  inputProps={{ step: "0.01" }}
                  type="number"
                  value={maintenance.sellingPriceWithTaxes}
                  name="sellingPriceWithTaxes"
                  onChange={updateForm}
                  required
                />
              </div>
            </div>

            <button type="submit" className="create-button">
              <div className="homeLinkIcons">
                <Update />
                <Person />
              </div>
              <span>Mettre à jour</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
