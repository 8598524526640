import React, { useState } from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
const borderColor = "#363636";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontSize: 8,
    padding: 20,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#363636",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "#363636",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 12,
    fontStyle: "bold",
  },
  tableRowSecondary: {
    backgroundColor: "#e1e1e1",
  },
  headerContainer: {
    flexDirection: "row",
    color: "#FFFFFF",
    borderBottomColor: "#363636",
    backgroundColor: "#363636",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 14,
    textAlign: "center",
    flexGrow: 1,
  },
  loc: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "14%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  ref: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  name: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  price: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  total: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  qty: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "7%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  nqty: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "9%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InventoryPDF = ({ items }) => {
  const itemsPerPage = 63;
  const itemChunks = [];
  let total = 0;
  for (let i = 0; i < items.length; i += itemsPerPage) {
    itemChunks.push(items.slice(i, i + itemsPerPage));
  }

  return (
    <Document>
      {itemChunks.map((chunk, chunkIndex) => (
        <Page key={chunkIndex} size="A4" style={styles.page} wrap>
          <View style={styles.container}>
            <View style={styles.tableContainer}>
              <View>
                <View style={styles.headerContainer}>
                  <Text style={styles.ref}>Référence</Text>
                  <Text style={styles.loc}>Localisation</Text>
                  <Text style={styles.qty}>Qté</Text>
                  <Text style={styles.price}>Prix</Text>
                  <Text style={styles.total}>Total</Text>
                  <Text style={styles.name}>Nom</Text>
                  <Text style={styles.nqty}>NQté</Text>
                </View>
              </View>
              {chunk.map((item, index) => {
                total += item.purchasePrice * item.stock;
                let styleRow = styles.tableRow;
                if (index > 0 && index % 2 !== 0) {
                  styleRow = { ...styleRow, ...styles.tableRowSecondary };
                }
                return (
                  <View style={styleRow} key={item._id} wrap>
                    <Text style={styles.ref}>{item.itemNumber || "-"}</Text>
                    <Text style={styles.loc}>{item.localization || "-"}</Text>
                    <Text style={styles.qty}>{item.stock || "-"}</Text>
                    <Text style={styles.price}>
                      {item.purchasePrice || "-"}
                    </Text>
                    <Text style={styles.total}>
                      {(item.purchasePrice * item.stock).toFixed(2) || "-"}
                    </Text>
                    <Text style={styles.name}>{item.name || "-"}</Text>
                    <Text style={styles.nqty}> </Text>
                  </View>
                );
              })}
            </View>
          </View>
          {chunkIndex === itemChunks.length - 1 && (
            <View style={styles.container}>
              <View>
                <View style={styles.headerContainer}>
                  <Text>Total: {total.toFixed(2)} €</Text>
                </View>
              </View>
            </View>
          )}
          <Text
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              fontSize: 8,
            }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber}/${totalPages}`
            }
            fixed
          />
        </Page>
      ))}
    </Document>
  );
};

export default InventoryPDF;
