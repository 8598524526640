import "../../css/page/newProduct.css";
import React, { useContext, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { endpoint } from "../../utils/API";
import { InputAdornment, InputLabel, TextField } from "@mui/material";
import { CalculateSharp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Add, Refresh, Storefront } from "@material-ui/icons";
import { FormContext } from "../../contexts/FormContext";
import { TEMPLATE_PRODUCT } from "../../templates";
import { handleOnKeyDownEnter } from "utils/misc";
import {
  computeMargin,
  computePurchasePrice,
  computeSellingPrice,
  computeSellingPriceWithTaxes,
} from "utils/Forms";

const NewProduct = () => {
  const navigate = useNavigate();
  const { form, setForm } = useContext(FormContext);
  const [isItemNumberExists, setIsItemNumberExists] = useState(false);

  const updateForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setForm({
      ...form,
      product: { ...form.product, [field.name]: field.value },
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newProduct = {
      brand: form.product.brand,
      itemNumber: form.product.itemNumber,
      name: form.product.name,
      description: form.product.description,
      stock: Number.parseInt(form.product.stock),
      purchasePrice: Number.parseFloat(form.product.purchasePrice),
      sellingPrice: Number.parseFloat(form.product.sellingPrice),
      margin: Number.parseFloat(form.product.margin),
      vatPercentage: Number.parseFloat(form.product.vatPercentage),
      sellingPriceWithTaxes: Number.parseFloat(
        form.product.sellingPriceWithTaxes
      ),
      localization: form.product.localization,
    };

    await axiosInstance
      .post(`${endpoint}/products`, newProduct)
      .then(() => {
        toast.success("Produit ajouté avec succès !");
        setForm({ ...form, product: { ...TEMPLATE_PRODUCT } });
        navigate("/products");
      })
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'ajout du produit !")
      );
  };

  const resetForm = () => {
    setForm({ ...form, product: { ...TEMPLATE_PRODUCT } });
  };

  const checkItemNumberExists = async () => {
    setIsItemNumberExists(false);
    const itemNumber = form.product.itemNumber;

    if (itemNumber) {
      await axiosInstance
        .get(`${endpoint}/products/find/${itemNumber}`)
        .then((response) => {
          if (response.data.result) {
            setIsItemNumberExists(true);
          }
        })
        .catch(() => {});
    }
  };

  const handleComputePurchasePrice = () => {
    const purchasePrice = computePurchasePrice(form.product);

    setForm({
      ...form,
      product: { ...form.product, purchasePrice: purchasePrice },
    });
  };

  const handleComputeSellingPrice = () => {
    const sellingPrice = computeSellingPrice(form.product);

    setForm({
      ...form,
      product: { ...form.product, sellingPrice: sellingPrice },
    });
  };

  const handleComputeMargin = () => {
    const margin = computeMargin(form.product);

    setForm({
      ...form,
      product: { ...form.product, margin: margin },
    });
  };

  const handleComputeSellingPriceWithTaxes = () => {
    const sellingPriceWithTaxes = computeSellingPriceWithTaxes(form.product);

    setForm({
      ...form,
      product: {
        ...form.product,
        sellingPriceWithTaxes: sellingPriceWithTaxes,
      },
    });
  };

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-refresh-container">
          <h1 className="title">Nouveau produit</h1>
          <button onClick={resetForm} className="refresh-button">
            <div className="homeLinkIcons">
              <Refresh />
            </div>
          </button>
        </div>
      </div>
      <form
        className="addProductForm"
        onSubmit={onSubmit}
        onKeyDown={handleOnKeyDownEnter}
      >
        <div className="addProductItem">
          <InputLabel htmlFor="brand">Marque</InputLabel>
          <TextField
            type="text"
            value={form.product.brand}
            name="brand"
            onChange={updateForm}
            placeholder="BIR"
            inputProps={{
              className: "upperCase",
            }}
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="itemNumber">N° d'article</InputLabel>
          <TextField
            type="text"
            value={form.product.itemNumber}
            name="itemNumber"
            onChange={updateForm}
            placeholder="motul-100000"
            onBlur={checkItemNumberExists}
            error={isItemNumberExists}
            helperText={
              isItemNumberExists
                ? "Cette référence a déjà été assignée !"
                : "Référence disponible !"
            }
            inputProps={{
              className: "upperCase",
            }}
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="name" required>
            Nom
          </InputLabel>
          <TextField
            type="text"
            value={form.product.name}
            name="name"
            onChange={updateForm}
            placeholder="Apple Airpods"
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="description">Description</InputLabel>
          <TextField
            type="text"
            value={form.product.description}
            name="description"
            onChange={updateForm}
            placeholder="Écouteurs de la marque Apple"
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="stock" required>
            Quantité en stock
          </InputLabel>
          <TextField
            type="number"
            value={form.product.stock}
            name="stock"
            onChange={updateForm}
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="purchasePrice" required>
            Prix d'achat unitaire
          </InputLabel>
          <TextField
            inputProps={{ step: "0.01" }}
            type="number"
            value={form.product.purchasePrice}
            name="purchasePrice"
            onChange={updateForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton onClick={handleComputePurchasePrice}>
                    <CalculateSharp />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="sellingPrice" required>
            Prix de vente unitaire
          </InputLabel>
          <TextField
            inputProps={{ step: "0.01" }}
            type="number"
            value={form.product.sellingPrice}
            name="sellingPrice"
            onChange={updateForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton onClick={handleComputeSellingPrice}>
                    <CalculateSharp />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="margin" required>
            Marge de base (%)
          </InputLabel>
          <TextField
            inputProps={{ step: "0.01" }}
            type="number"
            value={form.product.margin}
            name="margin"
            onChange={updateForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton onClick={handleComputeMargin}>
                    <CalculateSharp />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
        </div>

        <div className="addProductItem">
          <InputLabel htmlFor="vatPercentage" required>
            Taux T.V.A. (%)
          </InputLabel>
          <TextField
            inputProps={{ step: "0.01" }}
            type="number"
            value={form.product.vatPercentage}
            name="vatPercentage"
            onChange={updateForm}
            placeholder="21"
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="sellingPriceWithTaxes" required>
            Prix de vente unitaire TTC
          </InputLabel>
          <TextField
            inputProps={{ step: "0.01" }}
            type="number"
            value={form.product.sellingPriceWithTaxes}
            name="sellingPriceWithTaxes"
            onChange={updateForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton onClick={handleComputeSellingPriceWithTaxes}>
                    <CalculateSharp />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="localization">Localisation</InputLabel>
          <TextField
            type="text"
            value={form.product.localization}
            name="localization"
            onChange={updateForm}
            placeholder="H00103"
            inputProps={{
              className: "upperCase",
            }}
          />
        </div>
        <button type="submit" className="create-button">
          <div className="homeLinkIcons">
            <Add />
            <Storefront />
          </div>
          <span>Produit</span>
        </button>
      </form>
    </div>
  );
};

export default NewProduct;
