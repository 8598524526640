import {
  Box,
  Fade,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CalculateSharp } from "@mui/icons-material";
import React, { useState } from "react";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import { TEMPLATE_PRODUCT } from "../../templates";
import { handleOnKeyDownEnter } from "utils/misc";
import {
  computeMargin,
  computePurchasePrice,
  computeSellingPrice,
  computeSellingPriceWithTaxes,
} from "utils/Forms";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewVehicleUsedModal = ({
  isModalCreateNewVehicleOpen,
  closeModalCreateNewVehicle,
}) => {
  const [newProductForm, setNewProductForm] = useState({ ...TEMPLATE_PRODUCT });

  const addNewProduct = async (e) => {
    e.preventDefault();

    const newProduct = {
      brand: newProductForm.brand,
      itemNumber: newProductForm.itemNumber,
      name: newProductForm.name,
      description: newProductForm.description,
      stock: Number.parseInt(newProductForm.stock),
      purchasePrice: Number.parseFloat(newProductForm.purchasePrice),
      sellingPrice: Number.parseFloat(newProductForm.sellingPrice),
      sellingPriceWithTaxes: Number.parseFloat(
        newProductForm.sellingPriceWithTaxes
      ),
      margin: Number.parseFloat(newProductForm.margin),
      vatPercentage: Number.parseFloat(newProductForm.vatPercentage),
      localization: newProductForm.localization,
    };

    await axiosInstance
      .post(`${endpoint}/vehicles-used`, newProduct)
      .then(async () => {
        toast.success("Véhicule d'occasion ajouté avec succès !");
        setNewProductForm({ ...TEMPLATE_PRODUCT });
        closeModalCreateNewVehicle();
      })
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'ajout du véhicule !")
      );
  };

  const updateNewProductForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setNewProductForm({ ...newProductForm, [field.name]: field.value });
  };

  const handleComputePurchasePrice = () => {
    const purchasePrice = computePurchasePrice(newProductForm);

    setNewProductForm({
      ...newProductForm,
      purchasePrice: purchasePrice,
    });
  };

  const handleComputeSellingPrice = () => {
    const sellingPrice = computeSellingPrice(newProductForm);

    setNewProductForm({
      ...newProductForm,
      sellingPrice: sellingPrice,
    });
  };

  const handleComputeMargin = () => {
    const margin = computeMargin(newProductForm);

    setNewProductForm({
      ...newProductForm,
      margin: margin,
    });
  };

  const handleComputeSellingPriceWithTaxes = () => {
    const sellingPriceWithTaxes = computeSellingPriceWithTaxes(newProductForm);

    setNewProductForm({
      ...newProductForm,
      sellingPriceWithTaxes: sellingPriceWithTaxes,
    });
  };

  return (
    <Modal
      open={isModalCreateNewVehicleOpen}
      onClose={closeModalCreateNewVehicle}
    >
      <Fade in={isModalCreateNewVehicleOpen}>
        <Box sx={boxStyle}>
          <h1 className="addProductTitle">Nouveau véhicule d'occasion</h1>
          <form
            className="addProductForm"
            onSubmit={addNewProduct}
            onKeyDown={handleOnKeyDownEnter}
          >
            <div className="addProductItem">
              <InputLabel htmlFor="brand">Marque</InputLabel>
              <TextField
                type="text"
                value={newProductForm.brand}
                name="brand"
                onChange={updateNewProductForm}
                placeholder="BIR"
                inputProps={{
                  className: "upperCase",
                }}
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="itemNumber">Référence</InputLabel>
              <TextField
                type="text"
                value={newProductForm.itemNumber}
                name="itemNumber"
                onChange={updateNewProductForm}
                placeholder="motul-100000"
                inputProps={{
                  className: "upperCase",
                }}
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="name">Nom</InputLabel>
              <TextField
                type="text"
                value={newProductForm.name}
                name="name"
                onChange={updateNewProductForm}
                placeholder="Apple Airpods"
                required
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="description">Description</InputLabel>
              <TextField
                type="text"
                value={newProductForm.description}
                name="description"
                onChange={updateNewProductForm}
                placeholder="Écouteurs de la marque Apple"
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="stock">Quantité en stock</InputLabel>
              <TextField
                type="number"
                value={newProductForm.stock}
                name="stock"
                onChange={updateNewProductForm}
                required
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="purchasePrice">
                Prix d'achat unitaire
              </InputLabel>
              <TextField
                inputProps={{ step: "0.01" }}
                type="number"
                value={newProductForm.purchasePrice}
                name="purchasePrice"
                onChange={updateNewProductForm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton onClick={handleComputePurchasePrice}>
                        <CalculateSharp />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="addProductItem">
              <InputLabel htmlFor="sellingPrice">
                Prix de vente unitaire
              </InputLabel>
              <TextField
                inputProps={{ step: "0.01" }}
                type="number"
                value={newProductForm.sellingPrice}
                name="sellingPrice"
                onChange={updateNewProductForm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton onClick={handleComputeSellingPrice}>
                        <CalculateSharp />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="margin">Marge de base (%)</InputLabel>
              <TextField
                inputProps={{ step: "0.01" }}
                type="number"
                value={newProductForm.margin}
                name="margin"
                onChange={updateNewProductForm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton onClick={handleComputeMargin}>
                        <CalculateSharp />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="addProductItem">
              <InputLabel htmlFor="vatPercentage">Taux T.V.A. (%)</InputLabel>
              <TextField
                inputProps={{ step: "0.01" }}
                type="number"
                value={newProductForm.vatPercentage}
                name="vatPercentage"
                onChange={updateNewProductForm}
                placeholder="21"
                required
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="sellingPriceWithTaxes">
                Prix de vente unitaire TTC
              </InputLabel>
              <TextField
                inputProps={{ step: "0.01" }}
                type="number"
                value={newProductForm.sellingPriceWithTaxes}
                name="sellingPriceWithTaxes"
                onChange={updateNewProductForm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton onClick={handleComputeSellingPriceWithTaxes}>
                        <CalculateSharp />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <div className="addProductItem">
              <InputLabel htmlFor="localization">Localisation</InputLabel>
              <TextField
                type="text"
                value={newProductForm.localization}
                name="localization"
                onChange={updateNewProductForm}
                placeholder="H00103"
                inputProps={{
                  className: "upperCase",
                }}
              />
            </div>
            <button type="submit" className="addProductButton">
              Créer le véhicule d'occasion
            </button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewVehicleUsedModal;
