import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { roundToDecimalPlaces } from "utils/Financials";
import { calculateTotals } from "services/Products/Product.service";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    // fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  infos: {
    flexDirection: "column",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    justifyContent: "space-between",
    flexGrow: 1,
    marginTop: 10,
    padding: 8,
  },
});

const CreditNoteTableFooter = ({ items, payments }) => {
  const [totalWithoutTaxes, totalTaxes, totalWithTaxes] =
    calculateTotals(items);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.description}>Total (HTVA)</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalWithoutTaxes, 2, true)} €
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>TVA</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalTaxes, 2, true)} €
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total note de crédit</Text>
        <Text style={styles.total}>
          {roundToDecimalPlaces(totalWithTaxes, 2, true)} €
        </Text>
      </View>

      {payments.length > 0 && (
        <View style={styles.infos} break>
          {payments.map((payment, index) => (
            <Text key={index}>
              {new Date(payment.paymentDate).toLocaleDateString()} -{" "}
              {payment.paymentMethod} -{" "}
              {roundToDecimalPlaces(payment.paymentAmount, 2, true)} €
            </Text>
          ))}
        </View>
      )}
    </View>
  );
};

export default CreditNoteTableFooter;
