import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import { Add } from "@material-ui/icons";
import NewMaintenanceModal from "./NewMaintenance";
import { roundToDecimalPlaces } from "../../utils/Financials";
import Maintenance from "../../pages/Maintenance/Maintenance";

const LIMIT = 10;

const MaintenancesTable = ({
  isModalAddMaintenanceOpen,
  closeModalAddMaintenance,
  addItemToInvoice,
}) => {
  const [searchMaintenance, setSearchMaintenance] = useState("");
  const [loadingMaintenances, setLoadingMaintenances] = useState(false);
  const [maintenances, setMaintenances] = useState([]);
  const [maintenancesCount, setMaintenancesCount] = useState(0);
  const [isModalCreateNewMaintenanceOpen, setIsModalCreateNewMaintenanceOpen] =
    useState(false);
  const [isModalEditMaintenanceOpen, setIsModalEditMaintenanceOpen] =
    useState(false);
  const [maintenanceToEditId, setMaintenanceToEditId] = useState("");

  const fetchMaintenancesCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/maintenances/count`, {
      params: {
        search: searchMaintenance,
        fields: searchMaintenance ? ["itemNumber"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchMaintenances = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/maintenances`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchMaintenance,
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  const onSubmitSearchMaintenance = async (e) => {
    e.preventDefault();

    setLoadingMaintenances(true);

    const getMaintenancesCount = async () => {
      const maintenancesCount = await fetchMaintenancesCount();
      setMaintenancesCount(maintenancesCount);
    };

    const getMaintenances = async () => {
      const nextMaintenances = await fetchMaintenances(0);
      setLoadingMaintenances(false);
      setMaintenances(nextMaintenances);
    };

    getMaintenancesCount().catch((err) => {
      console.error(err);
    });

    getMaintenances().catch((err) => {
      console.log(err);
      setLoadingMaintenances(false);
    });
  };

  const onSearchMaintenanceChange = (e) => {
    setSearchMaintenance(e.target.value);
  };

  const openModalCreateNewMaintenance = () => {
    setIsModalCreateNewMaintenanceOpen(true);
  };

  const closeModalCreateNewMaintenance = () =>
    setIsModalCreateNewMaintenanceOpen(false);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Référence",
      width: 220,
    },
    {
      field: "name",
      headerName: "Nom",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 260,
    },
    {
      field: "sellingPriceWithTaxes",
      headerName: "Prix de vente unitaire TTC",
      width: 220,
      align: "right",
      renderCell: (params) => {
        return (
          <div className="productList__price">
            {roundToDecimalPlaces(params.row.sellingPriceWithTaxes, 2, true)} €
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <button
              className="userListInvoices"
              data-params={JSON.stringify(params.row)}
              onClick={addItemToInvoice}
            >
              Ajouter
            </button>
            <button
              className="userListEdit"
              data-id={params.row.id}
              onClick={openModalEditMaintenance}
            >
              Modifier
            </button>
          </>
        );
      },
    },
  ];

  const handlePageChangeMaintenances = (page) => {
    if (maintenances.length <= page * LIMIT) {
      setLoadingMaintenances(true);

      const getMaintenancesCount = async () => {
        const productsCount = await fetchMaintenancesCount();
        setMaintenancesCount(productsCount);
      };

      const getMaintenances = async () => {
        const nextMaintenances = await fetchMaintenances(page);
        setLoadingMaintenances(false);
        setMaintenances((maintenances) => [
          ...maintenances,
          ...nextMaintenances,
        ]);
      };

      getMaintenancesCount().catch((err) => {
        console.error(err);
      });

      getMaintenances().catch((err) => {
        console.log(err);
        setLoadingMaintenances(false);
      });
    }
  };

  useEffect(() => {
    if (
      isModalAddMaintenanceOpen &&
      !isModalCreateNewMaintenanceOpen &&
      !isModalEditMaintenanceOpen
    ) {
      setLoadingMaintenances(true);

      const getMaintenancesCount = async () => {
        const maintenancesCount = await fetchMaintenancesCount();
        setMaintenancesCount(maintenancesCount);
      };

      const getMaintenances = async () => {
        const nextMaintenances = await fetchMaintenances(0);
        setLoadingMaintenances(false);
        setMaintenances(nextMaintenances);
      };

      getMaintenancesCount().catch((err) => {
        console.error(err);
      });

      getMaintenances().catch((err) => {
        console.log(err);
        setLoadingMaintenances(false);
      });
    }
  }, [
    isModalAddMaintenanceOpen,
    isModalCreateNewMaintenanceOpen,
    isModalEditMaintenanceOpen,
  ]);

  const openModalEditMaintenance = (e) => {
    e.preventDefault();

    const maintenanceId = e.currentTarget.dataset.id;

    if (!maintenanceId) return;

    setMaintenanceToEditId(maintenanceId);
    setIsModalEditMaintenanceOpen(true);
  };

  const closeModalEditMaintenance = () => {
    setIsModalEditMaintenanceOpen(false);
  };

  return (
    <>
      <Dialog
        open={isModalAddMaintenanceOpen}
        onClose={closeModalAddMaintenance}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Rechercher un travail</DialogTitle>
        <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
          <DialogContent>
            <form
              onSubmit={onSubmitSearchMaintenance}
              style={{ marginTop: 10 }}
            >
              <TextField
                id="search-bar"
                style={{ width: 258 }}
                label="Référence du travail"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={searchMaintenance}
                onChange={onSearchMaintenanceChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
          </DialogContent>
          <div className="addProductItem">
            <div
              className="create-button"
              onClick={openModalCreateNewMaintenance}
            >
              <div className="create-button-icons">
                <Add />
              </div>
              <span>Travail</span>
            </div>
          </div>
        </div>
        <DialogContent style={{ height: 800 }}>
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={loadingMaintenances}
            rows={maintenances}
            getRowId={(row) => row._id}
            columns={columns}
            pageSize={LIMIT}
            rowsPerPageOptions={[LIMIT]}
            rowCount={maintenancesCount}
            onPageChange={handlePageChangeMaintenances}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalAddMaintenance}>Annuler</Button>
        </DialogActions>
      </Dialog>

      <NewMaintenanceModal
        isModalCreateNewMaintenanceOpen={isModalCreateNewMaintenanceOpen}
        closeModalCreateNewMaintenance={closeModalCreateNewMaintenance}
      />

      <Dialog
        open={isModalEditMaintenanceOpen}
        onClose={closeModalEditMaintenance}
        fullWidth
        maxWidth="lg"
      >
        <Maintenance
          id={maintenanceToEditId}
          closeModalEditMaintenance={closeModalEditMaintenance}
        />
      </Dialog>
    </>
  );
};

export default MaintenancesTable;
