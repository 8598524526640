import "../../css/page/home.css";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../contexts/UserContext";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import styles from "./Login.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {

  const [logo, setLogo] = useState('');

  import(`assets/img/${process.env.REACT_APP_LOGO_PATH||""}TT2000_Logo.png`).then((module) => {
    setLogo(module.default);
  });
  const location = useLocation();
  const navigate = useNavigate();
  const from = "" + location.state;
  const { setUser } = useContext(UserContext);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await axiosInstance
      .post(`${endpoint}/login`, {
        username: credentials.username,
        password: credentials.password,
      })
      .catch(() => {
        toast.error("Identifiants incorrects !");
      });

    if (response && response.status === 200) {
      toast.success("Connexion réussie !");
      setUser({
        name: response.data.result.name,
        accessToken: response.data.result.accessToken,
        refreshToken: response.data.result.refreshToken,
      });
      localStorage.setItem("accessToken", response.data.result.accessToken);
      localStorage.setItem("refreshToken", response.data.result.refreshToken);

      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.result.accessToken}`;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const checkToken = async () => {
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;

        const { data } = await axiosInstance.get(`${endpoint}/me`);

        if (data) {
          setUser({
            ...data.result,
          });

          if (from) {
            navigate(from);
          }
        }
      }
    };

    checkToken();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img src={logo} alt="Logo TT2000" className={styles.logo} />
      <div className={styles.login}>
        <h1>Connexion</h1>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormControl>
            <TextField
              label="Nom d'utilisateur"
              variant="outlined"
              name="username"
              value={credentials.username}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl>
            <TextField
              label="Mot de passe"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={credentials.password}
              onChange={handleChange}
              adornmentposition="end"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl>
            <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }}>
              Se connecter
            </Button>
          </FormControl>
        </form>
      </div>
    </Box>
  );
};

export default Login;
