import Barcode from "react-barcode";
import Logo from "../../assets/img/TT2000_Logo.png";
import { roundToDecimalPlaces } from "../../utils/Financials";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./LabelProduct.css";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { toast } from "react-toastify";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
const LabelProduct = ({ item, type, setItem }) => {
  const navigate = useNavigate();
  const [zplCode, setZplCode] = React.useState();
  const [margin, setMargin] = useState(215);
  const [lessMargin, setLessMargin] = useState(false);

  const changeMarge = () => {
    setLessMargin(!lessMargin);
    if (lessMargin) {
      setMargin(215);
    } else {
      setMargin(20);
    }
  };

  useEffect(() => {
    if (item && item.barcode) {
      let zpl = `^XA
                ^FX FIRST section with bar code.
                ^BY3,3,80
                ^FO${margin},20^BC^FD${item.barcode}^FS
                ^FX Second section with bar code.
                ^FO${margin},150^GFA,1044,1044,18,,::3gLFE01F807F,::::::3gLFE,3gNFC03F8,:::::3gNFC,3gPF807F,:::::3gPFC,3gHF3FF1FF1IF8,3QF8004003E07C0FC07C07FF8,3QF8004003803807803803FF8,3QF8004003001003003801FF8,3QF8004003K03001001FF8,3RF03F83E0C00C00C1040FF8,3RF03F83E0800C00C10E0FF8,3RF03F83FFI0C00C00E0JF,3RF03F83FE020C00C00E0JF,3RF03F83F8060C00C10E0JF,3RF03F83FJ0C10C1041JF,3RF03F83F001003001001JF,3RF03F83E001003803803JF,Q061,I01M061,06868L031K0202,0IC4L032K0603,19984J0401J0CI02,1130C1840E070645B18A062071C,I3083EECE0D0E69939A4E66CB4,3F30824D88191A79B69ADA6CD2,062186CF88121CI353094EC868,042104DD98321022643090C886,0C3206D9A8341072C42090CC84,04180010281808160620088043,N03L04,U04,::,:::^FS
                ^FO${margin + 180},150^A0N,18,18^FD${item.name}^FS
                ^FT${margin + 180},190^A0N,20,20^FD${roundToDecimalPlaces(
        item.sellingPriceWithTaxes,
        2,
        true
      )}^FS
                ^FT^A0N,20,20^FH_^FD_15^FS
                ^FT^A0N,18,18^FD/PC^FS
                ^FT${margin},230^A0N,20,20^FDREF: ${item.itemNumber}^FS
                ^XZ`;
      setZplCode(zpl);
    }
  }, [setZplCode, item, setItem, margin]);

  /*  const displayPrint = () => {
    window.print();
  };*/

  function generateBarcode() {
    axiosInstance
      .put(`${endpoint}/${type}/${item._id}`, item)
      .then((response) => {
        setItem(() => {
          return response.data.result;
        });
      })
      .catch((e) => {
        toast.error("Erreur lors de la génération du code barre", {
          autoClose: 5000,
        });
        console.log(e);
      });
  }
  async function printZpl() {
    try {
      // Create a new instance of the object

      const browserPrint = new ZebraBrowserPrintWrapper();
      // Select default printer
      const defaultPrinter = await browserPrint.getDefaultPrinter();

      // Set the printer
      browserPrint.setPrinter(defaultPrinter);

      // Check printer status
      const printerStatus = await browserPrint.checkPrinterStatus();

      // Check if the printer is ready
      if (printerStatus.isReadyToPrint) {
        // ZPL script to print a simple barcode

        browserPrint.print(zplCode);
        toast.success("Commande d'impression envoyée");
      } else {
        console.log("Error/s", printerStatus.errors);
        toast.error(
          `Erreurs lors de l'impression : 
          marge: ${margin} 
          error: 
            ${JSON.stringify(printerStatus.errors)}`,
          { autoClose: 10000 }
        );
      }
    } catch (error) {
      toast.error(
        `marge: ${margin} Impression impossible vérifiez que l'imprimante est allumée et que le pilote browser print est installé (zebra)`,
        { autoClose: 10000 }
      );
    }
  }

  const goBack = () => {
    navigate("/products");
  };

  return (
    <div className={"centerContainer"}>
      {item && item.barcode ? (
        <>
          <div id="selectionToPrint">
            <div className="containerBarcode">
              <Barcode
                value={item.barcode}
                width={1.5}
                height={30}
                displayValue={false}
              />
            </div>
            <div className="flexContainer">
              <img src={Logo} style={{ width: 60, height: 30 }} />
              <div className="columnContainer">
                <p className="itemName">{item.name}</p>
                <p className="itemPrice">
                  {roundToDecimalPlaces(item.sellingPriceWithTaxes, 2, true)} €
                  <span>/pc</span>
                </p>
                <p className="itemReference">
                  Ref : {item.itemNumber}/{item.barcode}
                </p>
              </div>
            </div>
            <p className="itemDescription">
              <span>Description :</span> {item.description}
            </p>
            <div id="zplCodeToPrint">
              <label htmlFor={"lessMargin"}>Moins de marge</label>
              <input
                key={"lessMargin"}
                type={"checkbox"}
                {...(lessMargin ? { checked: true } : "")}
                onClick={changeMarge}
              />
              {zplCode && (
                <Button onClick={printZpl} id={"printZPL"}>
                  Imprimer l'étiquette de prix
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        (!item && (
          <div className={"centerNoProduct"}>
            <p>Pas de produit sélectionné</p>
          </div>
        )) || (
          <div className={"centerNoProduct"}>
            <div>
              <p>
                Il n'existe pas encore de code barre pour le produit selectionné
              </p>
              <Button onClick={generateBarcode} id={"generateBarcode"}>
                Générer un codebarre
              </Button>
            </div>
            <Button onClick={goBack} id={"returnButton"}>
              Retour
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default LabelProduct;
