import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import DeleteDialog from "./DeleteDialog/DeleteDialog";
import { useState } from "react";
import EditComment from "./EditDialog/EditComment";
import { toast } from "react-toastify";

const BasicTableComments = ({ comments, setComments, updateToDatabase }) => {
  const [editCommentIndex, setEditCommentIndex] = useState(-1);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteCommentIndex, setDeleteCommentIndex] = useState(-1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleEdit = (e) => {
    e.preventDefault();

    const updatedComment = e.target.comment.value;
    const updatedComments = [...comments];

    updatedComments[editCommentIndex] = updatedComment;

    updateToDatabase("comments", updatedComments)
      .then(() => {
        setComments(updatedComments);
        toast.success("Commentaire modifié avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const handleDelete = () => {
    const updatedComments = comments.filter((comment, i) => {
      return i !== deleteCommentIndex;
    });

    updateToDatabase("comments", updatedComments)
      .then(() => {
        setComments(updatedComments);
        toast.success("Commentaire supprimé avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const openEditDialog = (e) => {
    e.preventDefault();

    const index = Number.parseInt(e.currentTarget.dataset.index);

    setEditCommentIndex(() => index);
    setEditDialogOpen(true);
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const index = Number.parseInt(e.currentTarget.dataset.index);

    setDeleteCommentIndex(index);
    setDeleteDialogOpen(true);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ marginBottom: 20 }}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Commentaire</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comments.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
                <TableCell align="right">
                  <EditOutlined
                    className="productListEditComment"
                    data-index={index}
                    onClick={openEditDialog}
                  />
                  <DeleteOutline
                    className="productListDeleteComment"
                    data-index={index}
                    onClick={openDeleteDialog}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EditComment
        handleEdit={handleEdit}
        open={editDialogOpen}
        setOpen={setEditDialogOpen}
        comment={comments[editCommentIndex]}
      />

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </>
  );
};

export default BasicTableComments;
