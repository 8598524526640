import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { roundToDecimalPlaces } from "../../utils/Financials";
import {
  calculateTotalRow,
  getDiscount,
  getSellingPrice,
  getVatPercentage,
} from "../../services/Products/Product.service";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  rowCommentContainer: {
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
  },
  rowComment: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold",
  },
  comment: {
    width: "100%",
    textAlign: "left",
    paddingLeft: 8,
    color: "blue",
  },
  description: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  rem: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  price: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const TicketTableRow = ({ items, comments }) => {
  let hasDiscount = false;

  for (let i in items) {
    hasDiscount = items[i].discount && items[i].discount > 0 && true;
    if (hasDiscount) {
      break;
    }
  }

  const rows = items.map((item) => (
    <View style={styles.row} key={item._id} wrap>
      <Text
        style={
          item.discount && item.discount > 0
            ? styles.description
            : { ...styles.description, width: "50%" }
        }
      >
        {item.brand} - {item.itemNumber} - {item.name}
      </Text>
      <Text style={styles.price}>{getSellingPrice(item)} €</Text>
      <Text style={styles.qty}>{item.quantity}</Text>
      {hasDiscount ? (
        <Text style={styles.rate}>{getDiscount(item)}</Text>
      ) : null}
      <Text style={styles.rate}>{getVatPercentage(item)}</Text>
      <Text style={styles.amount}>{calculateTotalRow(item)} €</Text>
    </View>
  ));

  const commentsRow = comments.map((comment, index) => (
    <View style={styles.rowComment} key={index}>
      <Text style={styles.comment}> - {comment}</Text>
    </View>
  ));

  return (
    <>
      <div style={styles.rowCommentContainer}>{commentsRow}</div>
      <>{rows}</>
    </>
  );
};

export default TicketTableRow;
