import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { roundToDecimalPlaces } from "../../utils/Financials";
import {
  calculateTotalRow,
  getDiscount,
  getSellingPrice,
  getVatPercentage,
} from "../../services/Products/Product.service";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  price: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
});

const TicketMonthlyPaymentTableRow = ({ items }) => {
  return Object.keys(items).map((key) => (
    <View style={styles.row} key={`reportMT${key}`} wrap>
      <Text style={styles.description}>{key}</Text>
      <Text style={styles.price}>{roundToDecimalPlaces(items[key])} €</Text>
    </View>
  ));
};

export default TicketMonthlyPaymentTableRow;
