import "../../css/page/productList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import {
  Add,
  DeleteOutline,
  PanoramaFishEye,
  Person,
  ShoppingCartOutlined,
  Visibility,
} from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { roundToDecimalPlaces } from "../../utils/Financials";
import ItemCard from "../../components/ItemCard/ItemCard";
import { Inventory } from "@mui/icons-material";
import InventoryButton from "../../components/Inventory/InventoryButton";

const LIMIT = 10;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBarcode, setSearchBarcode] = useState("");

  const [searchItemNumber, setSearchItemNumber] = useState("");
  const [searchLocalization, setSearchLocalization] = useState("");
  const [productsCount, setProductsCount] = useState(0);
  const [deleteProductID, setDeleteProductID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isItemCardOpen, setIsItemCardOpen] = useState(false);
  const [page, setPage] = useState(0);

  const fetchProductsCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/products/count`, {
      params: {
        search: searchItemNumber || searchLocalization,
        fields: searchLocalization
          ? ["localization"]
          : searchItemNumber
          ? ["itemNumber"]
          : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchProducts = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/products`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchItemNumber || searchLocalization || searchBarcode,
        fields:
          (searchItemNumber && ["itemNumber"]) ||
          (searchLocalization && ["localization"]) ||
          (searchBarcode && ["barcode"]) ||
          "",
      },
    });
    setPage(page);
    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getProductsCount = async () => {
      const count = await fetchProductsCount();
      setProductsCount(count);
    };

    const getProducts = async () => {
      const products = await fetchProducts();
      setLoading(false);
      setProducts(products);
    };

    getProductsCount().catch((err) => {
      console.log(err);
      setLoading(false);
    });

    getProducts().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Référence",
      flex: 1,
      width: 10,
    },
    {
      field: "brand",
      headerName: "Marque",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "sellingPriceWithTaxes",
      headerName: "Prix de vente TTC",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="productList__price">
            {roundToDecimalPlaces(params.row.sellingPriceWithTaxes, 2, true)} €
          </div>
        );
      },
    },
    { field: "stock", headerName: "Qté en stock", flex: 1 },
    {
      field: "localization",
      headerName: "Localisation",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Visibility
              className="productListView"
              data-id={params.row._id}
              onClick={openItemCardDialog}
            />
            <Link to={"/products/" + params.row._id}>
              <button className="productListEdit">Modifier</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              data-id={params.row._id}
              onClick={openDeleteDialog}
            />
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    if (products.length <= page * LIMIT) {
      setLoading(true);
      const getProducts = async () => {
        const nextProducts = await fetchProducts(page);
        setLoading(false);
        setProducts((products) => [...products, ...nextProducts]);
      };

      getProducts().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const openItemCardDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;
    for (let i in products) {
      if (products[i]._id === id) {
        setSelectedProduct(() => {
          return products[i];
        });
        break;
      }
    }
    setIsItemCardOpen(true);
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteProductID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/products/${deleteProductID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Produit supprimé avec succès !");

      const products = await fetchProducts();
      setProducts(products);
    }

    setLoading(false);
  };

  const onSearchItemNumberChange = (e) => {
    setSearchLocalization("");
    setSearchItemNumber(e.target.value);
  };

  const onSearchLocalizationChange = (e) => {
    setSearchItemNumber("");
    setSearchLocalization(e.target.value);
  };

  const onSearchCodeBarre = (e) => {
    setSearchItemNumber("");
    setSearchBarcode(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const getProductsCount = async () => {
      const count = await fetchProductsCount();
      setProductsCount(count);
    };

    const getProducts = async () => {
      const products = await fetchProducts();
      setLoading(false);
      setProducts(products);
    };

    getProductsCount().catch((err) => {
      console.log(err);
    });

    getProducts().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const closeItemCard = useCallback(() => {
    setIsItemCardOpen(false);
    fetchProducts(page).then((products) => {
      setProducts(products);
    });
  }, []);

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Produits</div>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-item-number"
              style={{ width: 258 }}
              label="Référence du produit"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={searchItemNumber}
              onChange={onSearchItemNumberChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-item-localization"
              style={{ width: 258 }}
              label="Localisation"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={searchLocalization}
              onChange={onSearchLocalizationChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>

          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-item-localization"
              style={{ width: 258 }}
              label="Code Barre"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={searchBarcode}
              onChange={onSearchCodeBarre}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
        </div>

        <div className={"buttonsPanel"}>
          <Link to={"/newProduct"} className="create-button">
            <div className="create-button-icons">
              <Add />
              <ShoppingCartOutlined />
            </div>

            <span>Produit</span>
          </Link>

          <InventoryButton type={"products"} />
        </div>
      </div>

      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        rows={products.map((product) => {
          return {
            ...product,
          };
        })}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        rowCount={productsCount}
        onPageChange={handlePageChange}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />

      <ItemCard
        open={isItemCardOpen}
        onClose={closeItemCard}
        item={selectedProduct}
        setItem={setSelectedProduct}
        type={"products"}
      />

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </div>
  );
};

export default Products;
