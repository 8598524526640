const TEMPLATE_PRODUCT = {
  brand: "",
  itemNumber: "",
  name: "",
  description: "",
  stock: 1,
  purchasePrice: 0,
  sellingPrice: 0,
  margin: 0,
  vatPercentage: 21,
  sellingPriceWithTaxes: 0,
  localization: "",
};

const TEMPLATE_INVOICE = {
  invoiceNumber: "",
  invoiceDate: new Date().toLocaleDateString(),
  client: {
    clientNumber: "",
    firstname: "",
    lastname: "",
    emailBusiness: "",
    phoneNumber: "",
    address: "",
    vatNumber: "",
  },

  products: [],
  paymentMethod: "",
  paid: false,
};

const TEMPLATE_TICKET = {
  ticketNumber: "",
  ticketDate: new Date().toLocaleDateString(),
  client: {
    clientNumber: "",
    firstname: "",
    lastname: "",
    emailBusiness: "",
    phoneNumber: "",
    address: "",
    vatNumber: "",
  },

  products: [],
  paymentMethod: "",
  paid: false,
};

const TEMPLATE_CREDIT_NOTE = {
  creditNoteNumber: "",
  creditNoteDate: new Date().toLocaleDateString(),
  client: {
    clientNumber: "",
    firstname: "",
    lastname: "",
    emailBusiness: "",
    phoneNumber: "",
    address: "",
    vatNumber: "",
  },

  products: [],
  paymentMethod: "",
  paid: false,
};

const TEMPLATE_CLIENT = {
  firstname: "",
  lastname: "",

  street: "",
  postCode: "",
  city: "",
  country: "BE",

  phoneNumber: "",
  mobileNumber: "",

  birthDate: new Date().toISOString().split("T")[0],
  vatNumber: "",
  emailBusiness: "",
  emailPrivate: "",
  additionalInfo: "",
};

export {
  TEMPLATE_PRODUCT,
  TEMPLATE_INVOICE,
  TEMPLATE_TICKET,
  TEMPLATE_CREDIT_NOTE,
  TEMPLATE_CLIENT,
};
