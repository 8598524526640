import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditComment = ({ open, setOpen, comment, handleEdit }) => {
  const [editedComment, setEditComment] = useState(comment || "");

  const handleClose = () => {
    setEditComment(comment);
    setOpen(false);
  };

  const handleEditAndClose = (e) => {
    handleEdit(e);
    setEditComment(comment);
    setOpen(false);
  };

  useEffect(() => {
    setEditComment(comment);

    return () => {
      setEditComment("");
    };
  }, [comment]);

  const onChange = (e) => {
    setEditComment(e.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Modifier le commentaire"}</DialogTitle>
        <form onSubmit={handleEditAndClose}>
          <DialogContent>
            <textarea
              name={"comment"}
              autoFocus
              value={editedComment}
              onChange={onChange}
              rows={10}
              cols={50}
              data-comment={editedComment}
              style={{ fontFamily: "Roboto" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Confirmer</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default EditComment;
