import "../../css/page/invoiceList.css";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {Add, DeleteOutline, PictureAsPdf} from "@material-ui/icons";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../services/axios";
import {toast} from "react-toastify";
import {LinearProgress} from "@mui/material";
import {endpoint} from "../../utils/API";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {StickyNote2} from "@mui/icons-material";
import INFOS from "../../utils/CompanyInfos";
import {calculateAmountDueByClient} from "../../services/Invoices/Invoices.service";
import ReportGeneratorDialog from "../../components/ReportGenerator/ReportGeneratorDialog";



const LIMIT = 10;

const Tickets = () => {
    const navigate = useNavigate();

    const [infos, setInfos] = useState({...INFOS});
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [ticketsCount, setTicketsCount] = useState(0);
    const [deleteTicketID, setDeleteTicketID] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const fetchTicketsCount = async () => {
        const {data} = await axiosInstance.get(`${endpoint}/tickets/count`, {
            params: {
                search: search,
                fields: search ? ["ticketNumber"] : "",
            },
        });

        if (data) {
            if (!data.success) {
                toast.error(data.message);
                return;
            }

            return data.result;
        }

        return 0;
    };

    const fetchTickets = async (page) => {
        const {data} = await axiosInstance.get(`${endpoint}/tickets`, {
            params: {
                limit: LIMIT,
                offset: !page ? 0 : page * LIMIT,
                search,
                fields: search ? ["ticketNumber"] : "",
            },
        });

        if (data) {
            if (!data.success) {
                toast.error(data.message);
                return;
            }

            return data.result.docs;
        }

        return [];
    };

    useEffect(() => {
        const getTicketsCount = async () => {
            const count = await fetchTicketsCount();
            setTicketsCount(count);
        };

        const getTickets = async () => {
            const tickets = await fetchTickets();
            setLoading(false);
            setTickets(tickets);
        };

        getTicketsCount().catch((err) => {
            console.log(err);
        });

        getTickets().catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }, []);

    const columns = [
        {field: "ticketNumber", headerName: "N°", width: 180},
        {
            field: "totalWithTaxes",
            headerName: "Montant total (TVAC)",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="userListUser">{params.row.totalWithTaxes} €</div>
                );
            },
        },
        {
            field: "payments",
            headerName: "Paiements",
            width: 200,
            renderCell: (params) => {
                return (
                    <div
                        className="userListUser"
                        style={{display: "flex", flexDirection: "column"}}
                    >
                        {params.row.payments.map((payment, index) => (
                            <div key={index}>
                                {payment.paymentMethod} - {payment.paymentAmount.toFixed(2)} €
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "remaining",
            headerName: "Solde dû",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {calculateAmountDueByClient(params.row).toFixed(2)} €
                    </div>
                );
            },
        },
        {
            field: "createdAt",
            headerName: "Date de création",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {new Date(params.row.createdAt).toLocaleDateString()}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/tickets/" + params.row._id}>
                            <button className="invoiceListEdit">Modifier</button>
                        </Link>
                        {!params.row.ticketNumber && (
                            <DeleteOutline
                                className="invoiceListDelete"
                                data-id={params.row._id}
                                onClick={openDeleteDialog}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    const handlePageChange = (page) => {
        if (tickets.length <= page * LIMIT) {
            setLoading(true);
            const getTickets = async () => {
                const nextTickets = await fetchTickets(page);
                setLoading(false);
                setTickets((tickets) => [...tickets, ...nextTickets]);
            };

            getTickets().catch((err) => {
                console.log(err);
                setLoading(false);
            });
        }
    };

    const openDeleteDialog = (e) => {
        e.preventDefault();

        const id = e.currentTarget.dataset.id;

        setDeleteTicketID(id);
        setDeleteDialogOpen(true);
    };

    const handleDelete = async () => {
        setLoading(true);

        const {data} = await axiosInstance.delete(
            `${endpoint}/tickets/${deleteTicketID}`
        );

        if (data) {
            if (!data.success) {
                toast.error(data.message);

                return;
            }

            toast.success("Ticket supprimé avec succès !");

            const tickets = await fetchTickets();
            setTickets(tickets);
        }

        setLoading(false);
    };

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const getTicketsCount = async () => {
            const count = await fetchTicketsCount();
            setTicketsCount(count);
        };

        const getTickets = async () => {
            const tickets = await fetchTickets();
            setLoading(false);
            setTickets(tickets);
        };

        getTicketsCount().catch((err) => {
            console.log(err);
        });

        getTickets().catch((err) => {
            console.log(err);
            setLoading(false);
        });
    };

    const addNewTicket = async () => {
        await axiosInstance
            .post(`${endpoint}/tickets`, {
                products: [],
                infos: infos,
                payments: [],
                comments: [],
            })
            .then((response) => {
                if (response.data.success) {
                    const {_id} = response.data.result;

                    toast.success("Ticket créé avec succès !");
                    navigate(`/tickets/${_id}`);

                    return;
                }

                toast.error(response.data.message);
            })
            .catch(() =>
                toast.error("Une erreur est survenue lors de la création du ticket !")
            );
    };




    return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Tickets</div>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar"
              style={{ width: 258 }}
              label="Numéro de ticket"
              variant="outlined"
              size="small"
              type="number"
              name="search"
              value={search}
              onChange={onSearchChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
        </div>

          <div className="button-options-container">
              <div className="create-button" onClick={addNewTicket}>
                  <div className="create-button-icons">
                      <Add/>
                      <StickyNote2/>
                  </div>
                  <span>Ticket</span>
              </div>
              <ReportGeneratorDialog api={"/tickets"}/>
          </div>
      </div>

      <DataGrid
        rows={tickets}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        rowCount={ticketsCount}
        onPageChange={handlePageChange}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />

            <DeleteDialog
                handleDelete={handleDelete}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
            />
        </div>
    );
};

export default Tickets;
