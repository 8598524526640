import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../css/page/product.css";
// import Chart from '../../components/Chart';
import { TwoWheeler, Update, Visibility } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { endpoint } from "../../utils/API";
import { InputAdornment, InputLabel, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CalculateSharp } from "@mui/icons-material";
import { TEMPLATE_PRODUCT } from "../../templates";
import ItemCard from "../../components/ItemCard/ItemCard";
import { handleOnKeyDownEnter } from "utils/misc";
import {
  computeMargin,
  computePurchasePrice,
  computeSellingPrice,
  computeSellingPriceWithTaxes,
} from "utils/Forms";

const VehicleUsed = ({ id, closeModalEditVehicleUsed }) => {
  const { vehicleUsedId } = useParams();
  const vehicleUsedToEditId = id || vehicleUsedId;
  const navigate = useNavigate();
  const [product, setProduct] = useState({ ...TEMPLATE_PRODUCT });
  const [productDetails, setProductDetails] = useState({ ...TEMPLATE_PRODUCT });
  const [isItemNumberExists, setIsItemNumberExists] = useState(true);
  const [isItemNumberSameAsBefore, setIsItemNumberSameAsBefore] =
    useState(true);
  const [isItemCardOpen, setIsItemCardOpen] = useState(false);
  const [page, setPage] = useState(0);

  const fetchVehicleUsed = async () => {
    const { data } = await axiosInstance.get(
      `/vehicles-used/${vehicleUsedToEditId}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return null;
  };

  useEffect(() => {
    const getVehicleUsed = async () => {
      const result = await fetchVehicleUsed();

      setProduct(result);
      setProductDetails(result);
    };

    getVehicleUsed().catch((err) => console.log(err));
  }, []);

  const updateForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setProduct({ ...product, [field.name]: field.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const updatedProduct = {
      brand: product.brand,
      itemNumber: product.itemNumber,
      name: product.name,
      description: product.description,
      stock: Number.parseInt(product.stock),
      purchasePrice: Number.parseFloat(product.purchasePrice),
      sellingPrice: Number.parseFloat(product.sellingPrice),
      sellingPriceWithTaxes: Number.parseFloat(product.sellingPriceWithTaxes),
      margin: Number.parseFloat(product.margin),
      vatPercentage: Number.parseFloat(product.vatPercentage),
      localization: product.localization,
    };

    const response = await axiosInstance.put(
      `${endpoint}/vehicles-used/${product._id}`,
      updatedProduct
    );

    if (response.status === 200) {
      toast.success("Véhicule modifié avec succès !");
      setProductDetails({ ...product });

      if (id) {
        closeModalEditVehicleUsed();

        return;
      }

      navigate("/vehiclesUsed");
    } else {
      toast.error("Une erreur est survenue !");
    }
  };

  const handleComputePurchasePrice = () => {
    const purchasePrice = computePurchasePrice(product);

    setProduct({
      ...product,
      purchasePrice: purchasePrice,
    });
  };

  const handleComputeSellingPrice = () => {
    const sellingPrice = computeSellingPrice(product);

    setProduct({
      ...product,
      sellingPrice: sellingPrice,
    });
  };

  const handleComputeMargin = () => {
    const margin = computeMargin(product);

    setProduct({
      ...product,
      margin: margin,
    });
  };

  const handleComputeSellingPriceWithTaxes = () => {
    const sellingPriceWithTaxes = computeSellingPriceWithTaxes(product);

    setProduct({
      ...product,
      sellingPriceWithTaxes: sellingPriceWithTaxes,
    });
  };

  const checkItemNumberExists = async () => {
    setIsItemNumberSameAsBefore(true);
    setIsItemNumberExists(false);
    const itemNumber = product.itemNumber;

    if (itemNumber) {
      await axiosInstance
        .get(`${endpoint}/vehicles-used/find/${itemNumber}`)
        .then((response) => {
          if (response.data.result) {
            if (response.data.result._id !== product._id) {
              setIsItemNumberSameAsBefore(false);
            }
            setIsItemNumberExists(true);
          }
        })
        .catch(() => {});
    }
  };

  const displayItemCard = (e) => {
    e.preventDefault();

    setIsItemCardOpen(true);
  };

  const closeItemCard = useCallback(() => {
    setIsItemCardOpen(false);
    fetchVehicleUsed(page).then((result) => {
      setProduct(result);
      setProductDetails(result);
    });
  }, []);

  return (
    <>
      <div className="product">
        <div className="productTitleContainer">
          <h1 className="productTitle">Véhicule d'occasion</h1>
        </div>
        <div className="editProductContainer">
          <div className="productTop">
            {/*<div className="productTopLeft">*/}
            {/*    <Chart data={productData} dataKey="Sales" title="Ventes"/>*/}
            {/*</div>*/}
            <div className="productTopRight">
              <div className="productInfoTop">
                {/*<img*/}
                {/*  src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"*/}
                {/*  alt=""*/}
                {/*  className="productInfoImg"*/}
                {/*/>*/}
                <span className="productName">{productDetails.name}</span>
              </div>
              <div className="productInfoBottom">
                <div className="productInfoItem">
                  <span className="productInfoKey">Marque : </span>
                  <span className="productInfoValue">
                    {productDetails.brand}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">N° d'article : </span>
                  <span className="productInfoValue">
                    {productDetails.itemNumber}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">Description : </span>
                  <span className="productInfoValue">
                    {productDetails.description}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">Quantité en stock : </span>
                  <span className="productInfoValue">
                    {productDetails.stock}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">
                    Prix d'achat unitaire :{" "}
                  </span>
                  <span className="productInfoValue">
                    {productDetails.purchasePrice}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">Marge de base : </span>
                  <span className="productInfoValue">
                    {productDetails.margin && `${productDetails.margin} %`}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">
                    Prix de vente unitaire :{" "}
                  </span>
                  <span className="productInfoValue">
                    {productDetails.sellingPrice}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">Taux T.V.A. : </span>
                  <span className="productInfoValue">
                    {productDetails.vatPercentage &&
                      `${productDetails.vatPercentage} %`}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">
                    Prix de vente unitaire TTC :{" "}
                  </span>
                  <span className="productInfoValue">
                    {productDetails.sellingPriceWithTaxes}
                  </span>
                </div>
                <div className="productInfoItem">
                  <span className="productInfoKey">Localisation : </span>
                  <span className="productInfoValue">
                    {productDetails.localization}
                  </span>
                </div>
                {/*<div className="productInfoItem">*/}
                {/*    <span className="productInfoKey">Ventes : </span>*/}
                {/*    <span className="productInfoValue">0</span>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="productBottom">
            <form
              className="productForm"
              onSubmit={onSubmit}
              onKeyDown={handleOnKeyDownEnter}
            >
              <div className="productFormLeft">
                <div className="addProductItem">
                  <InputLabel htmlFor="brand">Marque</InputLabel>
                  <TextField
                    type="text"
                    value={product.brand}
                    name="brand"
                    onChange={updateForm}
                    placeholder="BIR"
                    inputProps={{
                      className: "upperCase",
                    }}
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="brand">N° d'article</InputLabel>
                  <TextField
                    type="text"
                    value={product.itemNumber}
                    name="itemNumber"
                    onChange={updateForm}
                    placeholder="motul-100000"
                    onBlur={checkItemNumberExists}
                    helperText={
                      isItemNumberExists
                        ? isItemNumberSameAsBefore
                          ? "Référence actuelle"
                          : "Cette référence a déjà été assignée !"
                        : "Référence disponible !"
                    }
                    inputProps={{
                      className: "upperCase",
                    }}
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="name" required>
                    Nom
                  </InputLabel>
                  <TextField
                    type="text"
                    value={product.name}
                    name="name"
                    onChange={updateForm}
                    placeholder="Apple Airpods"
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="description">Description</InputLabel>
                  <TextField
                    type="text"
                    value={product.description}
                    name="description"
                    onChange={updateForm}
                    placeholder="Écouteurs de la marque Apple"
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="stock" required>
                    Quantité en stock
                  </InputLabel>
                  <TextField
                    type="number"
                    value={product.stock}
                    name="stock"
                    onChange={updateForm}
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="purchasePrice" required>
                    Prix d'achat unitaire
                  </InputLabel>
                  <TextField
                    inputProps={{ step: "0.01" }}
                    type="number"
                    value={product.purchasePrice}
                    name="purchasePrice"
                    onChange={updateForm}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          <IconButton onClick={handleComputePurchasePrice}>
                            <CalculateSharp />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="sellingPrice" required>
                    Prix de vente unitaire
                  </InputLabel>
                  <TextField
                    inputProps={{ step: "0.01" }}
                    type="number"
                    value={product.sellingPrice}
                    name="sellingPrice"
                    onChange={updateForm}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          <IconButton onClick={handleComputeSellingPrice}>
                            <CalculateSharp />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="margin" required>
                    Marge de base (%)
                  </InputLabel>
                  <TextField
                    inputProps={{ step: "0.01" }}
                    type="number"
                    value={product.margin}
                    name="margin"
                    onChange={updateForm}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          <IconButton onClick={handleComputeMargin}>
                            <CalculateSharp />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="vatPercentage">
                    Taux T.V.A. (%)
                  </InputLabel>
                  <TextField
                    inputProps={{ step: "0.01" }}
                    type="number"
                    value={product.vatPercentage}
                    name="vatPercentage"
                    onChange={updateForm}
                    placeholder="21"
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="sellingPriceWithTaxes" required>
                    Prix de vente unitaire TTC
                  </InputLabel>
                  <TextField
                    inputProps={{ step: "0.01" }}
                    type="number"
                    value={product.sellingPriceWithTaxes}
                    name="sellingPriceWithTaxes"
                    onChange={updateForm}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          <IconButton
                            onClick={handleComputeSellingPriceWithTaxes}
                          >
                            <CalculateSharp />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
                <div className="addProductItem">
                  <InputLabel htmlFor="localization">Localisation</InputLabel>
                  <TextField
                    type="text"
                    value={product.localization}
                    name="localization"
                    onChange={updateForm}
                    placeholder="H00103"
                    inputProps={{
                      className: "upperCase",
                    }}
                  />
                </div>
              </div>
              <button className="preview-button" onClick={displayItemCard}>
                <div className="homeLinkIcons">
                  <Visibility />
                </div>
                <span>Prévisualiser l'étiquette de prix</span>
              </button>
              <button type="submit" className="create-button">
                <div className="homeLinkIcons">
                  <Update />
                  <TwoWheeler />
                </div>
                <span>Mettre à jour</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <ItemCard
        open={isItemCardOpen}
        onClose={closeItemCard}
        item={product}
        setItem={setProduct}
        type={"vehicles-used"}
      />
    </>
  );
};

export default VehicleUsed;
