import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AddCircle, RemoveCircle, DeleteOutline } from "@material-ui/icons";
import { Input } from "@mui/material";
import { useState } from "react";
import { roundToDecimalPlaces } from "../utils/Financials";
import { toast } from "react-toastify";
import {
  calculateTotalRow,
  getDiscount,
  getSellingPrice,
} from "../services/Products/Product.service";

const BasicTableProducts = ({
  invoiceProducts,
  setInvoiceProducts,
  updateToDatabase,
}) => {
  const handleDelete = (event) => {
    const id = event.currentTarget.dataset.id;
    const updatedInvoiceProducts = invoiceProducts.filter(
      (invoiceProduct) => invoiceProduct._id !== id
    );

    updateToDatabase("products", updatedInvoiceProducts)
      .then(() => {
        setInvoiceProducts(updatedInvoiceProducts);
        toast.success("Produit retiré avec succès !");
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const handleQtyChange = (event) => {
    const behavior = event.currentTarget.dataset.behavior;
    const id = event.currentTarget.dataset.id;

    const oldQuantity = invoiceProducts.find(
      (invoiceProduct) => invoiceProduct._id === id
    )?.quantity;

    if (oldQuantity === null || oldQuantity === undefined) return;

    const updatedInvoiceProducts = invoiceProducts.map((product) =>
      product._id === id
        ? {
            ...product,
            quantity:
              behavior === "decrease" ? oldQuantity - 1 : oldQuantity + 1,
          }
        : product
    );

    updateToDatabase("products", updatedInvoiceProducts)
      .then(() => {
        setInvoiceProducts(updatedInvoiceProducts);
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  const setProductDiscount = (event) => {
    const id = event.currentTarget.dataset.id;
    const discount = Number.parseFloat(event.currentTarget.value);

    const updatedInvoiceProducts = invoiceProducts.map((product) =>
      product._id === id
        ? {
            ...product,
            discount: discount,
          }
        : product
    );

    updateToDatabase("products", updatedInvoiceProducts)
      .then(() => {
        setInvoiceProducts(updatedInvoiceProducts);
      })
      .catch(() => toast.error("Erreur rencontrée !"));
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        marginBottom: 16,
        overflowY: "auto",
        maxHeight: 400,
      }}
    >
      <Table sxaria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Produit</TableCell>
            <TableCell align="center">Quantité</TableCell>
            <TableCell align="right">Prix unit.</TableCell>
            <TableCell align="right">Remise (%)</TableCell>
            <TableCell align="right">Prix total (HTVA)</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceProducts.map((row) => (
            <TableRow
              key={row._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ alignContent: "center" }}
            >
              <TableCell>{row.name}</TableCell>
              <TableCell align="center">
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <RemoveCircle
                    data-behavior="decrease"
                    data-id={row._id}
                    onClick={handleQtyChange}
                    fontSize={"small"}
                    style={{ cursor: "pointer" }}
                    color={"error"}
                  />

                  <span>{row.quantity}</span>

                  <AddCircle
                    data-behavior="increase"
                    data-id={row._id}
                    onClick={handleQtyChange}
                    fontSize={"small"}
                    color={"primary"}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </TableCell>
              <TableCell align="right">{getSellingPrice(row)}</TableCell>
              <TableCell align="right">
                <Input
                  onBlur={setProductDiscount}
                  defaultValue={getDiscount(row)}
                  type="number"
                  inputProps={{
                    min: "0",
                    max: "100",
                    step: "0.01",
                    "data-id": row._id,
                    style: { textAlign: "center" },
                  }}
                  style={{
                    fontSize: "0.875rem",
                    width: 60,
                  }}
                />
              </TableCell>
              <TableCell align="right">{calculateTotalRow(row)}</TableCell>
              <TableCell align="right">
                <DeleteOutline
                  className="productListDelete"
                  data-id={row._id}
                  onClick={handleDelete}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTableProducts;
