import React from "react";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Barcode from "react-barcode";
import "./ItemCard.css";
import { roundToDecimalPlaces } from "../../utils/Financials";
import LabelProduct from "../LabelProduct/LabelProduct";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemCard = ({ open, onClose, item, setItem, type }) => {
  const displayPrint = () => {
    window.print();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      className="item-card"
    >
      {item && (
        <>
          <div id="item-price-label-print">
            <DialogTitle className="item-card-name">{item.name}</DialogTitle>
            <DialogContent className="item-card-content">
              <LabelProduct item={item} setItem={setItem} type={type} />
            </DialogContent>
          </div>
          <DialogActions id="item-price-label-hide">
            <Button onClick={onClose}>Fermer</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ItemCard;
