import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import TicketTableHeader from "../TicketTable/TicketTableHeader";
import TicketTableRow from "../TicketTable/TicketTableRow";
import TicketTableFooter from "../TicketTable/TicketTableFooter";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    height: 84,
    backgroundColor: "#F90000",
    position: "relative",
  },
  headerLeft: {
    position: "absolute",
    top: 27,
    left: 169,
    width: "50%",
  },
  invoiceDate: {
    flexDirection: "row",
    color: "#FFFFFF",
    fontSize: 12,
    marginBottom: 8,
  },
  clientNumber: {
    flexDirection: "row",
    color: "#FFFFFF",
    fontSize: 12,
  },
  headerRight: {
    position: "absolute",
    top: 31,
    left: 454,
    width: "50%",
  },
  invoiceNumber: {
    fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 18,
  },
  page: {
    flexDirection: "column",

    fontSize: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    padding: 20,
  },
  section: {
    padding: 10,
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: "#000000",
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#000000",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#000000",
  },
});

const TicketsReportPDF = ({ tickets, date }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.container}>
          <View style={styles.row}>
            <Text style={styles.title}>
              Bilan journalier des tickets -{" "}
              {new Date(date).toLocaleDateString()}
            </Text>
          </View>
          {tickets.length > 0 &&
            tickets.map((ticket, index) => (
              <View key={index} style={styles.tableContainer} wrap={false}>
                <TicketTableHeader
                  client={ticket.client}
                  ticketNumber={ticket.ticketNumber}
                  ticketDate={ticket.ticketDate}
                  invoiceProducts={ticket.invoiceProducts}
                  hideDate={true}
                />
                <TicketTableRow
                  items={ticket.products}
                  comments={ticket.comments}
                />
                <TicketTableFooter
                  items={ticket.products}
                  infos={ticket.infos}
                  payments={ticket.payments}
                />
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
};

export default React.memo(TicketsReportPDF);
