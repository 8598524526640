import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Add } from "@material-ui/icons";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import NewProductModal from "./NewProduct";
import { roundToDecimalPlaces } from "../../utils/Financials";
import Product from "../../pages/Product/Product";

const LIMIT = 10;

const ProductsTable = ({
  isModalAddProductOpen,
  closeModalAddProduct,
  addItemToInvoice,
}) => {
  const [searchItemNumber, setSearchItemNumber] = useState("");
  const [searchLocalization, setSearchLocalization] = useState("");
  const [searchCodeBarre, setSearchCodeBarre] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [isModalCreateNewProductOpen, setIsModalCreateNewProductOpen] =
    useState(false);
  const [isModalEditProductOpen, setIsModalEditProductOpen] = useState(false);
  const [productToEditId, setProductToEditId] = useState("");

  const fetchProductsCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/products/count`, {
      params: {
        search: searchItemNumber || searchLocalization,
        fields: searchLocalization
          ? ["localization"]
          : searchItemNumber
          ? ["itemNumber"]
          : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchProducts = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/products`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchItemNumber || searchLocalization,
        fields: searchItemNumber ? ["itemNumber"] : ["localization"],
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  const onSubmitSearchProduct = async (e) => {
    e.preventDefault();

    setLoadingProducts(true);

    const getProductsCount = async () => {
      const productsCount = await fetchProductsCount();
      setProductsCount(productsCount);
    };

    const getProducts = async () => {
      const products = await fetchProducts();
      setLoadingProducts(false);
      setProducts(products);
    };

    getProductsCount().catch((err) => {
      console.log(err);
    });

    getProducts().catch((err) => {
      console.log(err);
      setLoadingProducts(false);
    });
  };

  const onSearchItemNumberChange = (e) => {
    setSearchLocalization("");
    setSearchItemNumber(e.target.value);
  };

  const onSearchLocalizationChange = (e) => {
    setSearchItemNumber("");
    setSearchLocalization(e.target.value);
  };

  const onSearchCodeBarre = (e) => {
    setSearchItemNumber("");
    setSearchCodeBarre(e.target.value);
  };

  const openModalCreateNewProduct = (e) => {
    e.preventDefault();
    setIsModalCreateNewProductOpen(true);
  };

  const closeModalCreateNewProduct = () =>
    setIsModalCreateNewProductOpen(false);

  const openModalEditProduct = (e) => {
    e.preventDefault();

    const productId = e.currentTarget.dataset.id;

    if (!productId) return;

    setProductToEditId(productId);
    setIsModalEditProductOpen(true);
  };

  const closeModalEditProduct = () => {
    setIsModalEditProductOpen(false);
  };

  const columns = [
    {
      field: "itemNumber",
      headerName: "Référence",
      width: 180,
    },
    {
      field: "brand",
      headerName: "Marque",
      width: 120,
    },
    {
      field: "name",
      headerName: "Nom",
      width: 180,
    },
    {
      field: "sellingPriceWithTaxes",
      headerName: "Prix de vente unitaire TTC",
      width: 200,
      align: "right",
      renderCell: (params) => {
        return (
          <div className="productList__price">
            {roundToDecimalPlaces(params.row.sellingPriceWithTaxes, 2, true)} €
          </div>
        );
      },
    },
    {
      field: "stock",
      headerName: "Stock",
      width: 100,
      align: "right",
    },
    {
      field: "localization",
      headerName: "Localisation",
      width: 160,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <button
              className="userListInvoices"
              data-params={JSON.stringify(params.row)}
              onClick={addItemToInvoice}
            >
              Ajouter
            </button>
            <button
              className="userListEdit"
              data-id={params.row.id}
              onClick={openModalEditProduct}
            >
              Modifier
            </button>
          </>
        );
      },
    },
  ];

  const handlePageChangeProducts = (page) => {
    if (products.length <= page * LIMIT) {
      setLoadingProducts(true);

      const getProductsCount = async () => {
        const productsCount = await fetchProductsCount();
        setProductsCount(productsCount);
      };

      const getProducts = async () => {
        const nextProducts = await fetchProducts(page);
        setLoadingProducts(false);
        setProducts((products) => [...products, ...nextProducts]);
      };

      getProductsCount().catch((err) => {
        console.error(err);
      });

      getProducts().catch((err) => {
        console.log(err);
        setLoadingProducts(false);
      });
    }
  };

  useEffect(() => {
    if (
      isModalAddProductOpen &&
      !isModalCreateNewProductOpen &&
      !isModalEditProductOpen
    ) {
      setLoadingProducts(true);

      const getProductsCount = async () => {
        const productsCount = await fetchProductsCount();
        setProductsCount(productsCount);
      };

      const getProducts = async () => {
        const nextProducts = await fetchProducts(0);
        setLoadingProducts(false);
        setProducts(nextProducts);
      };

      getProductsCount().catch((err) => {
        console.error(err);
      });

      getProducts().catch((err) => {
        console.log(err);
        setLoadingProducts(false);
      });
    }
  }, [
    isModalAddProductOpen,
    isModalCreateNewProductOpen,
    isModalEditProductOpen,
  ]);

  return (
    <>
      <Dialog
        open={isModalAddProductOpen}
        onClose={closeModalAddProduct}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Rechercher un produit</DialogTitle>
        <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
          <DialogContent>
            <form onSubmit={onSubmitSearchProduct}>
              <TextField
                id="search-bar-item-number"
                style={{ width: 258 }}
                label="Référence du produit"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={searchItemNumber}
                onChange={onSearchItemNumberChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
            <form onSubmit={onSubmitSearchProduct}>
              <TextField
                id="search-bar-item-localization"
                style={{ width: 258 }}
                label="Localisation"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={searchLocalization}
                onChange={onSearchLocalizationChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
            <form onSubmit={onSubmitSearchProduct}>
              <TextField
                id="search-bar-item-localization"
                style={{ width: 258 }}
                label="Code Barre"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={searchCodeBarre}
                onChange={onSearchCodeBarre}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
          </DialogContent>
          <div className="addProductItem">
            <div className="create-button" onClick={openModalCreateNewProduct}>
              <div className="create-button-icons">
                <Add />
              </div>
              <span>Produit</span>
            </div>
          </div>
        </div>

        <DialogContent style={{ height: 800 }}>
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={loadingProducts}
            rows={products}
            getRowId={(row) => row._id}
            columns={columns}
            pageSize={LIMIT}
            rowsPerPageOptions={[LIMIT]}
            rowCount={productsCount}
            onPageChange={handlePageChangeProducts}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalAddProduct}>Annuler</Button>
        </DialogActions>
      </Dialog>

      <NewProductModal
        isModalCreateNewProductOpen={isModalCreateNewProductOpen}
        closeModalCreateNewProduct={closeModalCreateNewProduct}
      />

      <Dialog
        open={isModalEditProductOpen}
        onClose={closeModalEditProduct}
        fullWidth
        maxWidth="lg"
      >
        <Product
          id={productToEditId}
          closeModalEditProduct={closeModalEditProduct}
        />
      </Dialog>
    </>
  );
};

export default ProductsTable;
