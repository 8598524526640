import "../../css/page/productList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import {
  Add,
  DeleteOutline,
  ShoppingCartOutlined,
  TwoWheeler,
  Visibility,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { TireRepair } from "@mui/icons-material";
import { roundToDecimalPlaces } from "../../utils/Financials";
import ItemCard from "../../components/ItemCard/ItemCard";
import InventoryButton from "../../components/Inventory/InventoryButton";

const LIMIT = 10;

const VehiclesUsed = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchItemNumber, setSearchItemNumber] = useState("");
  const [searchLocalization, setSearchLocalization] = useState("");
  const [vehiclesCount, setVehiclesCount] = useState(0);
  const [deleteVehicleID, setDeleteVehicleID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedVehicleUsed, setSelectedVehicleUsed] = useState(null);
  const [isItemCardOpen, setIsItemCardOpen] = useState(false);

  const fetchVehiclesCount = async () => {
    const { data } = await axiosInstance.get(
      `${endpoint}/vehicles-used/count`,
      {
        params: {
          search: searchItemNumber || searchLocalization,
          fields: searchLocalization
            ? ["localization"]
            : searchItemNumber
            ? ["itemNumber"]
            : "",
        },
      }
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchVehicles = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/vehicles-used`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchItemNumber || searchLocalization,
        fields: searchItemNumber ? ["itemNumber"] : ["localization"],
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getVehiclesCount = async () => {
      const count = await fetchVehiclesCount();
      setVehiclesCount(count);
    };

    const getVehicles = async () => {
      const vehicles = await fetchVehicles();
      setLoading(false);
      setVehicles(vehicles);
    };

    getVehiclesCount().catch((err) => {
      console.log(err);
    });

    getVehicles().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Numéro de chassis",
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Marque",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "sellingPriceWithTaxes",
      headerName: "Prix de vente TTC",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className="productList__price">
            {roundToDecimalPlaces(params.row.sellingPriceWithTaxes, 2, true)} €
          </div>
        );
      },
    },
    { field: "stock", headerName: "Quantité en stock", flex: 1 },
    {
      field: "localization",
      headerName: "Localisation",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Visibility
              className="productListView"
              data-id={params.row._id}
              onClick={displayItemCard}
            />
            <Link to={"/vehiclesUsed/" + params.row._id}>
              <button className="productListEdit">Modifier</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              data-id={params.row._id}
              onClick={openDeleteDialog}
            />
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    if (vehicles.length <= page * LIMIT) {
      setLoading(true);
      const getVehicles = async () => {
        const nextVehicles = await fetchVehicles(page);
        setLoading(false);
        setVehicles((vehicles) => [...vehicles, ...nextVehicles]);
      };

      getVehicles().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteVehicleID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/vehicles-used/${deleteVehicleID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Véhicule supprimé avec succès !");

      const vehicles = await fetchVehicles();
      setVehicles(vehicles);
    }

    setLoading(false);
  };

  const onSearchItemNumberChange = (e) => {
    setSearchLocalization("");
    setSearchItemNumber(e.target.value);
  };

  const onSearchLocalizationChange = (e) => {
    setSearchItemNumber("");
    setSearchLocalization(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const getVehiclesCount = async () => {
      const count = await fetchVehiclesCount();
      setVehiclesCount(count);
    };

    const getVehicles = async () => {
      const vehicles = await fetchVehicles();
      setLoading(false);
      setVehicles(vehicles);
    };

    getVehiclesCount().catch((err) => {
      console.log(err);
    });

    getVehicles().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const displayItemCard = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    const item = vehicles.find((vehicleUsed) => vehicleUsed._id === id);

    if (!item) {
      toast.error("Véhicule d'occasion introuvable !");
      return;
    }

    setSelectedVehicleUsed(item);
    setIsItemCardOpen(true);
  };

  const closeItemCard = useCallback(() => {
    setIsItemCardOpen(false);
  }, []);

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Véhicules d'occasion</div>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-item-number"
              style={{ width: 258 }}
              label="Référence du véhicule d'occasion"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={searchItemNumber}
              onChange={onSearchItemNumberChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar-item-localization"
              style={{ width: 258 }}
              label="Localisation"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={searchLocalization}
              onChange={onSearchLocalizationChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
        </div>

        <div className={"buttonsPanel"}>
          <Link to={"/newVehicleUsed"} className="create-button">
            <div className="create-button-icons">
              <Add />
              <TireRepair />
            </div>

            <span>Véhicule d'occasion</span>
          </Link>
          <InventoryButton type={"vehicles-used"} />
        </div>
      </div>

      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        rows={vehicles}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        rowCount={vehiclesCount}
        onPageChange={handlePageChange}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />

      <ItemCard
        open={isItemCardOpen}
        onClose={closeItemCard}
        item={selectedVehicleUsed}
      />

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </div>
  );
};

export default VehiclesUsed;
