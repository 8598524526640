import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 8,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderLeftColor: "#bff0fd",
    borderRightColor: "#bff0fd",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    padding: 8,
  },
});

const Conditions = () => {
  return (
    <View style={styles.row} break>
      <Text>
        Toutes les factures sont payables au grand comptant. Toute facture
        impayée à l'échéance (trente jours de la date), sera de plein droit sans
        mise en demeure préalable, augmentée d'un intérêt de retard égal au taux
        conventionnel de 10%, ainsi que d'une clause pénale égale à 15% du
        montant total de la facture avec un minimum de 50€. Lorsque le crédit de
        l'acheteur se détériore, nous nous réservons le droit même après
        l'exécution partielle du marché, d'exiger de l'acheteur les garanties
        que nous jugeons convenables en vue de l'exécution des engagements pris.
      </Text>
    </View>
  );
};

export default Conditions;
