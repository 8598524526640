import {
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  PhoneLocked,
  Business,
  DateRange,
  InfoRounded,
  Update,
  Person,
} from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import "../../css/page/user.css";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { endpoint } from "../../utils/API";
import { MailLock } from "@mui/icons-material";
import { TEMPLATE_CLIENT } from "../../templates";
import { TextField } from "@mui/material";
import { mongoDateToInputDate } from "../../utils/Dates";
import { handleOnKeyDownEnter } from "utils/misc";
import { frFR as localeDatePickerFR } from "@mui/x-date-pickers/locales/frFR";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayJs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const Client = ({ id, closeModalEditClient }) => {
  const { clientId } = useParams();
  const clientToEditId = id || clientId;
  const navigate = useNavigate();
  const [client, setClient] = useState({ ...TEMPLATE_CLIENT });
  const [clientDetails, setClientDetails] = useState({ ...TEMPLATE_CLIENT });

  const fetchClient = async () => {
    const { data } = await axiosInstance.get(`/clients/${clientToEditId}`);

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      data.result.birthDate = mongoDateToInputDate(data.result.birthDate);

      return data.result;
    }

    return null;
  };

  useEffect(() => {
    const getClient = async () => {
      const result = await fetchClient();

      setClient(result);
      setClientDetails(result);
    };

    getClient().catch((err) => console.log(err));
  }, []);

  const updateForm = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const updateBirthdayForm = (date) => {
    date = dayJs(date).format("YYYY-MM-DD");
    setClient({ ...client, birthDate: date });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const response = await axiosInstance.put(
      `${endpoint}/clients/${client._id}`,
      client
    );

    if (response.status === 200) {
      toast.success("Client modifié avec succès !");
      setClientDetails({ ...client });

      if (id) {
        closeModalEditClient();

        return;
      }

      navigate("/clients");
    } else {
      toast.error("Une erreur est survenue !");
    }
  };

  const retrieveCity = async (e) => {
    e.preventDefault();

    if (client.postCode.length !== 4 || isNaN(client.postCode)) {
      return;
    }

    const { data } = await axiosInstance.get(`${endpoint}/belgiumCities`, {
      params: {
        postCode: client.postCode,
      },
    });

    if (data) {
      if (data.length === 0) {
        toast.error("Aucune ville trouvée pour ce code postal !");
        return;
      }

      setClient((prevState) => ({
        ...prevState,
        city: data.result.fields.column_2,
        country: "BE",
      }));
    }
  };

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Mettre à jour la fiche du client</h1>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            {/*<img*/}
            {/*  src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"*/}
            {/*  alt=""*/}
            {/*  className="userShowImg"*/}
            {/*/>*/}
            <div className="userShowTopTitle">
              <span className="userShowUsername">
                {clientDetails.firstname} {clientDetails.lastname}
              </span>
              <span className="userShowUserTitle"></span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Détails du client</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.firstname} {clientDetails.lastname}
              </span>
            </div>
            <div className="userShowInfo">
              <Business className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.vatNumber}
              </span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.emailBusiness}
              </span>
            </div>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.phoneNumber}
              </span>
            </div>
            <div className="userShowInfo">
              <MailLock className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.emailPrivate}
              </span>
            </div>
            <div className="userShowInfo">
              <PhoneLocked className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.mobileNumber}
              </span>
            </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.street} - {clientDetails.postCode}{" "}
                {clientDetails.city} - {clientDetails.country}
              </span>
            </div>
            <div className="userShowInfo">
              <DateRange className="userShowIcon" />
              <span className="userShowInfoTitle">
                {new Date(clientDetails.birthDate).toLocaleDateString()}
              </span>
            </div>
            <div className="userShowInfo">
              <InfoRounded className="userShowIcon" />
              <span className="userShowInfoTitle">
                {clientDetails.additionalInfo}
              </span>
            </div>
          </div>
        </div>
        <div className="userUpdate">
          <span className="userUpdateTitle">Modifier</span>
          <form
            className="addProductForm"
            onSubmit={onSubmit}
            onKeyDown={handleOnKeyDownEnter}
          >
            <div className="newUserItem">
              <label>Numéro de TVA (vide si particulier)</label>
              <TextField
                type="text"
                placeholder="BE 0000.000.000"
                name="vatNumber"
                value={client.vatNumber}
                onChange={updateForm}
              />
            </div>
            <div className="newUserItem">
              <label>Prénom</label>
              <TextField
                type="text"
                className="userUpdateInput"
                value={client.firstname}
                name="firstname"
                onChange={updateForm}
              />
            </div>
            <div className="newUserItem">
              <label>Nom</label>
              <TextField
                type="text"
                className="userUpdateInput"
                value={client.lastname}
                name="lastname"
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>Rue n°</label>
              <TextField
                type="text"
                placeholder="Rue de la réussite, 7"
                name="street"
                value={client.street}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>Code Postal</label>
              <TextField
                type="text"
                placeholder="7000"
                name="postCode"
                value={client.postCode}
                onChange={updateForm}
                onBlur={retrieveCity}
              />
            </div>

            <div className="newUserItem">
              <label>Localité</label>
              <TextField
                type="text"
                placeholder="Mons"
                name="city"
                value={client.city}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>Pays</label>
              <TextField
                type="text"
                placeholder="BE"
                name="country"
                value={client.country}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>Téléphone</label>
              <TextField
                type="text"
                placeholder="069/00.00.00"
                name="phoneNumber"
                value={client.phoneNumber}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>GSM</label>
              <TextField
                type="text"
                placeholder="0475/00.00.00"
                name="mobileNumber"
                value={client.mobileNumber}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>Date de naissance</label>
              <LocalizationProvider
                localeText={
                  localeDatePickerFR.components.MuiLocalizationProvider
                    .defaultProps.localeText
                }
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={["day", "month", "year"]}
                  label=""
                  helperText="Selectionnez un jour, un mois et  une année "
                  value={client.birthDate}
                  onChange={updateBirthdayForm}
                  renderInput={(props) => <TextField {...props} />}
                />
              </LocalizationProvider>
            </div>

            <div className="newUserItem">
              <label>E-mail (business)</label>
              <TextField
                type="email"
                placeholder="john.smith@business.com"
                name="emailBusiness"
                value={client.emailBusiness}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>E-mail (privé)</label>
              <TextField
                type="email"
                placeholder="john.smith@gmail.com"
                name="emailPrivate"
                value={client.emailPrivate}
                onChange={updateForm}
              />
            </div>

            <div className="newUserItem">
              <label>Informations complémentaires</label>
              <TextField
                multiline
                name="additionalInfo"
                value={client.additionalInfo}
                onChange={updateForm}
              />
            </div>
            {/*<div className="userUpdateUpload">*/}
            {/*  <img*/}
            {/*    className="userUpdateImg"*/}
            {/*    src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"*/}
            {/*    alt=""*/}
            {/*  />*/}
            {/*  <label htmlFor="file">*/}
            {/*    <Publish className="userUpdateIcon" />*/}
            {/*  </label>*/}
            {/*  <input type="file" id="file" style={{ display: "none" }} />*/}
            {/*</div>*/}
            <button type="submit" className="create-button">
              <div className="homeLinkIcons">
                <Update />
                <Person />
              </div>
              <span>Mettre à jour</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Client;
