import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "../../assets/img/TT2000_Logo.png";
import Suzuki from "../../assets/img/logo_suzuki.png";
import Benelli from "../../assets/img/logo_benelli.png";
import Sym from "../../assets/img/logo_sym.png";
import Peugeot from "../../assets/img/logo_peugeot.png";
import CreditNoteTableHeader from "../CreditNoteTable/CreditNoteTableHeader";
import CreditNoteTableRow from "../CreditNoteTable/CreditNoteTableRow";
import CreditNoteTableFooter from "../CreditNoteTable/CreditNoteTableFooter";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    height: 84,
    backgroundColor: "#F90000",
    position: "relative",
  },
  headerLeft: {
    position: "absolute",
    top: 27,
    left: 169,
    width: "50%",
  },
  invoiceDate: {
    flexDirection: "row",
    // fontFamily: 'Roboto',
    // fontStyle: 'normal',
    // fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 12,
    marginBottom: 8,
    // lineHeight: 14,
  },
  clientNumber: {
    flexDirection: "row",
    // fontFamily: 'Roboto',
    // fontStyle: 'normal',
    // fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 12,
    // lineHeight: 14,
  },
  headerRight: {
    position: "absolute",
    top: 31,
    left: 454,
    width: "50%",
  },
  invoiceNumber: {
    // fontFamily: 'Roboto',
    // fontStyle: 'normal',
    fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 18,
    // lineHeight: 21
  },
  page: {
    flexDirection: "column",

    fontSize: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    padding: 20,
  },
  section: {
    padding: 10,
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const CreditNotePDF = ({
  creditNoteNumber,
  creditNoteDate,
  client,
  infos,
  creditNoteProducts,
  comments,
  payments,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.section}>
              <Image src={Logo} style={{ width: 200, height: 100 }} />
              <View style={styles.row}>
                <Image src={Suzuki} style={{ width: 40, height: 40 }} />
                <Image src={Benelli} style={{ width: 40, height: 40 }} />
                <Image src={Sym} style={{ width: 40, height: 40 }} />
                <Image src={Peugeot} style={{ width: 40, height: 40 }} />
              </View>
              <Text>
                {infos.street}, {infos.streetNumber}
              </Text>
              <Text>
                {infos.postalCode} {infos.city}
              </Text>
              <Text>TVA : {infos.vatNumber}</Text>
              <Text>Tel : {infos.phone}</Text>
              <Text>&ensp;</Text>
              <Text>Web : {infos.website}</Text>
              <Text>E-mail : {infos.email}</Text>
            </View>
            <View style={styles.section}>
              <Text>{infos.titleText}</Text>
              <Text>&ensp;</Text>
              <Text>&ensp;</Text>
              {client && (
                <>
                  <Text>
                    {client.clientNumber &&
                      `N° de client : ${client.clientNumber}`}
                  </Text>
                  <Text>
                    {client.firstname} {client.lastname}
                  </Text>
                  <Text>{client.street}</Text>
                  <Text>
                    {client.postCode} {client.city}
                  </Text>
                  <Text>{client.vatNumber && `TVA : ${client.vatNumber}`}</Text>
                  <Text>
                    {client.phoneNumber && `Tel : ${client.phoneNumber}`}
                  </Text>
                  <Text>
                    {client.emailBusiness && `E-mail : ${client.emailBusiness}`}
                  </Text>
                </>
              )}
            </View>
          </View>

          <View style={styles.tableContainer}>
            <CreditNoteTableHeader
              client={client}
              creditNoteNumber={creditNoteNumber}
              creditNoteDate={creditNoteDate}
              invoiceProducts={creditNoteProducts}
            />
            <CreditNoteTableRow
              items={creditNoteProducts}
              comments={comments}
            />
          </View>

          <CreditNoteTableFooter
            items={creditNoteProducts}
            infos={infos}
            payments={payments}
          />
        </View>

        <Text
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            fontSize: 8,
          }}
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default React.memo(CreditNotePDF);
