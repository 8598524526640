import "../../css/page/newProduct.css";
import React, { useContext, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { endpoint } from "../../utils/API";
import { InputLabel, TextField } from "@mui/material";
import { Add, Refresh, Settings } from "@material-ui/icons";
import { FormContext } from "../../contexts/FormContext";
import { TEMPLATE_PRODUCT } from "../../templates";
import { handleOnKeyDownEnter } from "utils/misc";

const NewProduct = () => {
  const navigate = useNavigate();
  const { form, setForm } = useContext(FormContext);
  const [isItemNumberExists, setIsItemNumberExists] = useState(false);

  const updateForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setForm({
      ...form,
      maintenance: { ...form.maintenance, [field.name]: field.value },
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newMaintenance = {
      itemNumber: form.maintenance.itemNumber,
      name: form.maintenance.name,
      description: form.maintenance.description,
      sellingPrice: Number.parseFloat(form.maintenance.sellingPrice),
      sellingPriceWithTaxes: Number.parseFloat(
        form.maintenance.sellingPriceWithTaxes
      ),
    };

    await axiosInstance
      .post(`${endpoint}/maintenances`, newMaintenance)
      .then(() => {
        toast.success("Travail ajouté avec succès !");
        setForm({ ...form, maintenance: { ...TEMPLATE_PRODUCT } });
        navigate("/maintenances");
      })
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'ajout du travail !")
      );
  };

  const resetForm = () => {
    setForm({ ...form, maintenance: { ...TEMPLATE_PRODUCT } });
  };

  const checkItemNumberExists = async () => {
    setIsItemNumberExists(false);
    const itemNumber = form.maintenance.itemNumber;

    if (itemNumber) {
      await axiosInstance
        .get(`${endpoint}/maintenances/find/${itemNumber}`)
        .then((response) => {
          if (response.data.result) {
            setIsItemNumberExists(true);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-refresh-container">
          <h1 className="title">Nouveau travail</h1>
          <button onClick={resetForm} className="refresh-button">
            <div className="homeLinkIcons">
              <Refresh />
            </div>
          </button>
        </div>
      </div>
      <form
        className="addProductForm"
        onSubmit={onSubmit}
        onKeyDown={handleOnKeyDownEnter}
      >
        <div className="addProductItem">
          <InputLabel htmlFor="itemNumber">N° d'article</InputLabel>
          <TextField
            type="text"
            value={form.maintenance.itemNumber}
            name="itemNumber"
            onChange={updateForm}
            placeholder="motul-100000"
            onBlur={checkItemNumberExists}
            error={isItemNumberExists}
            helperText={
              isItemNumberExists
                ? "Cette référence a déjà été assignée !"
                : "Référence disponible !"
            }
            inputProps={{
              className: "upperCase",
            }}
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="name" required>
            Nom
          </InputLabel>
          <TextField
            type="text"
            value={form.maintenance.name}
            name="name"
            onChange={updateForm}
            placeholder="Apple Airpods"
            required
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="description">Description</InputLabel>
          <TextField
            type="text"
            value={form.maintenance.description}
            name="description"
            onChange={updateForm}
            placeholder="Écouteurs de la marque Apple"
          />
        </div>
        <div className="addProductItem">
          <InputLabel htmlFor="sellingPriceWithTaxes" required>
            Prix de vente unitaire TTC
          </InputLabel>
          <TextField
            inputProps={{ step: "0.01" }}
            type="number"
            value={form.maintenance.sellingPriceWithTaxes}
            name="sellingPriceWithTaxes"
            onChange={updateForm}
            required
          />
        </div>

        <button type="submit" className="create-button">
          <div className="homeLinkIcons">
            <Add />
            <Settings />
          </div>
          <span>Travail</span>
        </button>
      </form>
    </div>
  );
};

export default NewProduct;
