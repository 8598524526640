import React, { useEffect } from "react";
import Sidebar from "./components/Sidebar";
import "./css/component/topbar.css";
import "./App.css";
import Home from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Clients from "./pages/Client/Clients";
import Client from "./pages/Client/Client";
import NewClient from "./pages/Client/NewClient";
import Products from "./pages/Product/Products";
import Product from "./pages/Product/Product";
import NewProduct from "./pages/Product/NewProduct";
import Invoices from "./pages/Invoice/Invoices";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./helpers/ScrollToTop";
import Login from "./pages/Login/Login";
import { UserContext } from "./contexts/UserContext";
import Invoice from "./pages/Invoice/Invoice";
import Vehicles from "./pages/Vehicle/Vehicles";
import Vehicle from "./pages/Vehicle/Vehicle";
import NewVehicle from "./pages/Vehicle/NewVehicle";
import TicketsReport from "./pages/Tickets/TicketsReport";
import Tickets from "./pages/Tickets/Tickets";
import Ticket from "./pages/Tickets/Ticket";
import Chassis from "./pages/Chassis/Chassis";
import ClientInvoices from "./pages/ClientInvoices/ClientInvoices";
import NewMaintenance from "./pages/Maintenance/NewMaintenance";
import Maintenance from "./pages/Maintenance/Maintenance";
import Maintenances from "./pages/Maintenance/Maintenances";
import ClientCreditNotes from "./pages/ClientCreditNotes/ClientCreditNotes";
import CreditNote from "./pages/CreditNote/CreditNote";
import CreditNotes from "./pages/CreditNote/CreditNotes";
import VehiclesUsed from "./pages/VehicleUsed/VehiclesUsed";
import VehicleUsed from "./pages/VehicleUsed/VehicleUsed";
import NewVehicleUsed from "./pages/VehicleUsed/NewVehicleUsed";
import { FormContext } from "./contexts/FormContext";
import { TEMPLATE_PRODUCT } from "./templates";
import LabelProduct from "./components/LabelProduct/LabelProduct";

const App = () => {
  const from = window.location.pathname;
  const [user, setUser] = React.useState(null);
  const [form, setForm] = React.useState({
    product: { ...TEMPLATE_PRODUCT },
    maintenance: { ...TEMPLATE_PRODUCT },
    vehicle: { ...TEMPLATE_PRODUCT },
    vehicleUsed: { ...TEMPLATE_PRODUCT },
  });

  const logout = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    toast.success("Déconnexion réussie !");
  };

  const userContext = {
    user,
    setUser,
    logoutUser: logout,
  };

  const formContext = {
    form,
    setForm,
  };

  return (
    <Router>
      <UserContext.Provider value={userContext}>
        <FormContext.Provider value={formContext}>
          <ScrollToTop />
          {!user ? (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" state={from} />} />
            </Routes>
          ) : (
            <>
              <div className="container">
                <Sidebar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/clients/:clientId" element={<Client />} />
                  <Route
                    path="/clients/:clientId/invoices"
                    element={<ClientInvoices />}
                  />
                  <Route
                    path="/clients/:clientId/creditNotes"
                    element={<ClientCreditNotes />}
                  />
                  <Route path="/newClient" element={<NewClient />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/products/:productId" element={<Product />} />
                  <Route path="/labelProduct" element={<LabelProduct />} />
                  <Route path="/newProduct" element={<NewProduct />} />
                  <Route path="/maintenances" element={<Maintenances />} />
                  <Route
                    path="/maintenances/:maintenanceId"
                    element={<Maintenance />}
                  />
                  <Route path="/newMaintenance" element={<NewMaintenance />} />
                  <Route path="/vehicles" element={<Vehicles />} />
                  <Route path="/vehicles/:vehicleId" element={<Vehicle />} />
                  <Route path="/newVehicle" element={<NewVehicle />} />
                  <Route path="/vehiclesUsed" element={<VehiclesUsed />} />
                  <Route
                    path="/vehiclesUsed/:vehicleUsedId"
                    element={<VehicleUsed />}
                  />
                  <Route path="/newVehicleUsed" element={<NewVehicleUsed />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/invoices/:invoiceId" element={<Invoice />} />
                  <Route path="/creditNotes" element={<CreditNotes />} />
                  <Route
                    path="/creditNotes/:creditNoteId"
                    element={<CreditNote />}
                  />
                  <Route path="/chassis" element={<Chassis />} />
                  <Route path="/tickets" element={<Tickets />} />
                  <Route path="/tickets/:ticketId" element={<Ticket />} />
                  <Route path="/ticket" element={<Ticket />} />
                  <Route path="/ticketsReport" element={<TicketsReport />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </>
          )}
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
          />
        </FormContext.Provider>
      </UserContext.Provider>
    </Router>
  );
};

export default App;
