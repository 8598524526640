import "../../css/page/invoiceList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import SearchBar from "../../components/SearchBar";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { Add, TwoWheeler } from "@material-ui/icons";

const LIMIT = 10;

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [invoicesCount, setInvoicesCount] = useState(0);

  const fetchInvoicesCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/invoices/count`, {
      params: {
        search: search,
        fields: search ? ["itemNumber"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchInvoices = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/invoices`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: search,
        fields: search ? ["itemNumber"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getInvoicesCount = async () => {
      const count = await fetchInvoicesCount();
      setInvoicesCount(count);
    };

    const getInvoices = async () => {
      const invoices = await fetchInvoices();
      setLoading(false);
      setInvoices(invoices);
    };

    getInvoicesCount().catch(() => (err) => {
      console.log(err);
    });

    getInvoices().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: "invoiceNumber", headerName: "N° de facture", width: 120 },
    {
      field: "client",
      headerName: "Client",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/*{params.row.client.image && <img className="userListImg" src={params.row.client.image} alt="" />}*/}
            {params.row.client.firstname} {params.row.client.lastname}
          </div>
        );
      },
    },
    {
      field: "chassisNumbers",
      headerName: "N° de chassis",
      width: 400,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {params.row.products.map((product, index) => (
              <div key={index}>
                {product.itemNumber} {product.name}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {new Date(params.row.createdAt).toLocaleDateString()}
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    if (invoices.length <= page * LIMIT) {
      setLoading(true);
      const getInvoices = async () => {
        const nextInvoices = await fetchInvoices(page);
        setLoading(false);
        setInvoices((invoices) => [...invoices, ...nextInvoices]);
      };

      getInvoices().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const onSearchChassisNumberChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const getInvoicesCount = async () => {
      const count = await fetchInvoicesCount();
      setInvoicesCount(count);
    };

    const getInvoices = async () => {
      const invoices = await fetchInvoices();
      setLoading(false);
      setInvoices(invoices);
    };

    getInvoicesCount().catch(() => (err) => {
      console.log(err);
    });

    getInvoices().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Chassis</div>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar"
              style={{ width: 258 }}
              label="Numéro de chassis"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={search}
              onChange={onSearchChassisNumberChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
        </div>
      </div>

      <DataGrid
        rows={invoices}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        rowCount={invoicesCount}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Invoices;
