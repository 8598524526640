import "../../css/page/productList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import {
  Add,
  DeleteOutline,
  Settings,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { roundToDecimalPlaces } from "../../utils/Financials";

const LIMIT = 10;

const Maintenances = () => {
  const [maintenances, setMaintenances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [maintenancesCount, setMaintenancesCount] = useState(0);
  const [deleteMaintenanceID, setDeleteMaintenanceID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchMaintenancesCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/maintenances/count`, {
      params: {
        search: search,
        fields: search ? ["itemNumber"] : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchMaintenances = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/maintenances`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search,
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getMaintenancesCount = async () => {
      const count = await fetchMaintenancesCount();
      setMaintenancesCount(count);
    };

    const getMaintenances = async () => {
      const maintenances = await fetchMaintenances();
      setLoading(false);
      setMaintenances(maintenances);
    };

    getMaintenancesCount().catch((err) => {
      console.log(err);
      setLoading(false);
    });

    getMaintenances().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Référence",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "sellingPriceWithTaxes",
      headerName: "Prix de vente TTC",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="productList__price">
            {roundToDecimalPlaces(params.row.sellingPriceWithTaxes, 2, true)} €
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/maintenances/" + params.row._id}>
              <button className="productListEdit">Modifier</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              data-id={params.row._id}
              onClick={openDeleteDialog}
            />
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    if (maintenances.length <= page * LIMIT) {
      setLoading(true);
      const getMaintenances = async () => {
        const nextMaintenances = await fetchMaintenances(page);
        setLoading(false);
        setMaintenances((maintenances) => [
          ...maintenances,
          ...nextMaintenances,
        ]);
      };

      getMaintenances().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteMaintenanceID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/maintenances/${deleteMaintenanceID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Travai supprimé avec succès !");

      const maintenances = await fetchMaintenances();
      setMaintenances(maintenances);
    }

    setLoading(false);
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const getMaintenancesCount = async () => {
      const count = await fetchMaintenancesCount();
      setMaintenancesCount(count);
    };

    const getMaintenances = async () => {
      const maintenances = await fetchMaintenances();
      setLoading(false);
      setMaintenances(maintenances);
    };

    getMaintenancesCount().catch((err) => {
      console.log(err);
    });

    getMaintenances().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  return (
    <div className="content">
      <div className="above-table">
        <div className="title-search-container">
          <div className="title">Travaux</div>
          <form onSubmit={onSubmit}>
            <TextField
              id="search-bar"
              style={{ width: 258 }}
              label="Référence du travail"
              variant="outlined"
              size="small"
              type="text"
              name="search"
              value={search}
              onChange={onSearchChange}
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon style={{ fill: "#E4221D" }} />
            </IconButton>
          </form>
        </div>

        <Link to={"/newMaintenance"} className="create-button">
          <div className="create-button-icons">
            <Add />
            <Settings />
          </div>

          <span>Travail</span>
        </Link>
      </div>

      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        rows={maintenances}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={LIMIT}
        rowsPerPageOptions={[LIMIT]}
        rowCount={maintenancesCount}
        onPageChange={handlePageChange}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </div>
  );
};

export default Maintenances;
