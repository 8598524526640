import * as React from "react";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { frFR as localeDatePickerFR } from "@mui/x-date-pickers/locales/frFR";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { PictureAsPdf } from "@material-ui/icons";
import dayJs from "dayjs";
import "dayjs/locale/fr";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { CircularProgress } from "@mui/material";
import { Download } from "@mui/icons-material";
import TicketsMonthlyPaiementReportPDF from "../TicketsMonthlyPaiementReportPDF/TicketsMonthlyPaymentReportPDF";
import { toast } from "react-toastify";

dayJs.locale("fr");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportGeneratorDialog({ api }) {
  const [selectedForMonthReportDate, setSelectedForMonthReportDate] = useState(
    dayJs().subtract(1, "month")
  );

  const style = {
    fontFamily: "Staatliches, sans-serif",
    background: "#efefff",
    color: "#555555",
  };
  const [tickets, setTickets] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    axiosInstance
      .get(`${endpoint}${api}`, {
        params: {
          month: selectedForMonthReportDate.format("MM"),
          year: selectedForMonthReportDate.format("YYYY"),
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.result &&
          response.data.result.docs
        ) {
          setTickets((prevState) => {
            return response.data.result.docs;
          });
          setOpen(true);
        } else {
          setTickets((prevState) => {
            return [];
          });
          toast.error("Aucunes données trouvées pour le mois choisi!");
        }
      })
      .catch((error) => {
        setTickets((prevState) => {
          return [];
        });
        toast.error("Aucunes données trouvées pour le mois choisi!");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChangeForMonthReport = (date) => {
    dayJs.isDayjs(date) && setSelectedForMonthReportDate(date);
  };

  useEffect(() => {
    if (open) {
    }
  }, [open, api, selectedForMonthReportDate]);

  const pdfDatas = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 10,
            height: "100%",
          }}
        >
          <PDFViewer
            style={{ flex: 1, height: "100%", width: "100%" }}
            showToolbar={true}
          >
            <TicketsMonthlyPaiementReportPDF
              tickets={tickets}
              date={selectedForMonthReportDate}
            />
          </PDFViewer>{" "}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={"generate-month-report_container"}>
        <LocalizationProvider
          localeText={
            localeDatePickerFR.components.MuiLocalizationProvider.defaultProps
              .localeText
          }
          dateAdapter={AdapterDayjs}
        >
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Année et mois"
            helperText="Selectionnez une année et un mois"
            value={selectedForMonthReportDate}
            onChange={handleDateChangeForMonthReport}
            maxDate={dayJs()}
            minDate={dayJs().subtract(1, "year")}
            renderInput={(props) => <TextField {...props} />}
          />
        </LocalizationProvider>
        <div className="create-button" onClick={handleClickOpen}>
          <div className="create-button-icons">
            <PictureAsPdf />
          </div>
          <span>Ticket du mois</span>
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={style}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Rapport Mensuel par moyen de paiement -{" "}
              {selectedForMonthReportDate.format("MMMM YYYY")}
            </Typography>

            <PDFDownloadLink
              document={
                <TicketsMonthlyPaiementReportPDF
                  tickets={tickets}
                  date={selectedForMonthReportDate}
                />
              }
              fileName={
                selectedForMonthReportDate.format("YYYY-MMMM") + "-MPR.pdf"
              }
              style={{ textDecoration: "none" }}
            >
              {({ blob, url, loading, error }) => {
                if (loading) return <CircularProgress size={24} />;
                if (error) return <div>Une erreur est survenue</div>;

                return (
                  <div className="create-button">
                    <div className="create-button-icons">
                      <Download />
                    </div>
                    <span style={{ fontSize: 16 }}>
                      Télécharger le rapport mensuel par moyen de paiement
                    </span>
                  </div>
                );
              }}
            </PDFDownloadLink>
          </Toolbar>
        </AppBar>
        {(tickets.length > 0 && pdfDatas()) ||
          "Aucunes données trouvées pour le mois choisi"}
      </Dialog>
    </div>
  );
}
