const handleOnKeyDownEnter = (e) => {
  if (
    e.key === "Enter" &&
    e.target.type !== "button" &&
    e.target.type !== "submit" &&
    e.target.type !== "textarea"
  ) {
    e.preventDefault();

    return false;
  }
};

export { handleOnKeyDownEnter };
