const roundToDecimalPlaces = (number, decimalPlaces = 2, toFixed = false) => {
  const factorOfTen = 10 ** decimalPlaces;

  if (toFixed)
    return (Math.round(number * factorOfTen) / factorOfTen).toFixed(
      decimalPlaces
    );
  return Math.round(number * factorOfTen) / factorOfTen;
};

const roundForCash = (number) => {
  const value = roundToDecimalPlaces(number);
  const split = value.toString().split(".");
  if (split.length == 2 && split[1].length == 2) {
    const lastNumber = parseInt(split[1][1]);
    const newNumber = lastNumber < 3 ? 0 : (lastNumber < 8 && 5) || 10;
    return (value - lastNumber / 100 + newNumber / 100).toFixed(2);
  }
  return value.toFixed(2);
};

export { roundToDecimalPlaces, roundForCash };
