import "../../css/page/userList.css";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { Add, DeleteOutline, FolderSpecial } from "@material-ui/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { endpoint } from "../../utils/API";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import INFOS from "../../utils/CompanyInfos";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const LIMIT = 10;

const ClientCreditNotes = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [infos, setInfos] = useState({ ...INFOS });
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditNotes, setCreditNotes] = useState([]);
  const [search, setSearch] = useState("");
  const [creditNotesCount, setCreditNotesCount] = useState(0);
  const [deleteCreditNoteID, setDeleteCreditNoteID] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchClient = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/clients/${clientId}`);

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return null;
  };

  const fetchClientCreditNotesCount = async () => {
    const { data } = await axiosInstance.get(
      `${endpoint}/clients/${clientId}/creditNotes/count`,
      {
        params: {
          search: search,
          fields: search ? ["creditNoteNumber"] : "",
        },
      }
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchClientCreditNotes = async (page) => {
    const { data } = await axiosInstance.get(
      `${endpoint}/clients/${clientId}/creditNotes`,
      {
        params: {
          limit: LIMIT,
          offset: !page ? 0 : page * LIMIT,
          search,
          fields: search ? ["creditNoteNumber"] : "",
        },
      }
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  useEffect(() => {
    const getClient = async () => {
      const client = await fetchClient();
      setClient(client);
    };

    const getClientCreditNotesCount = async () => {
      const count = await fetchClientCreditNotesCount();
      setCreditNotesCount(count);
    };

    const getClientCreditNotes = async () => {
      const creditNotes = await fetchClientCreditNotes();
      setLoading(false);
      setCreditNotes(creditNotes);
    };

    getClient().catch((err) => {
      console.log(err);
    });

    getClientCreditNotesCount().catch((err) => {
      console.log(err);
    });

    getClientCreditNotes().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: "creditNoteNumber", headerName: "N°", width: 80 },
    {
      field: "client",
      headerName: "Client",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/*{params.row.client.image && <img className="userListImg" src={params.row.client.image} alt="" />}*/}
            {params.row.client.firstname} {params.row.client.lastname}
          </div>
        );
      },
    },
    {
      field: "totalWithTaxes",
      headerName: "Montant total (TVAC)",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="userListUser">{params.row.totalWithTaxes} €</div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {new Date(params.row.createdAt).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/creditNotes/" + params.row._id}>
              <button className="invoiceListEdit">Modifier</button>
            </Link>
            <DeleteOutline
              className="invoiceListDelete"
              data-id={params.row._id}
              onClick={openDeleteDialog}
            />
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    if (creditNotes.length <= page * LIMIT) {
      setLoading(true);
      const getCreditNotes = async () => {
        const nextCreditNotes = await fetchClientCreditNotes(page);
        setLoading(false);
        setCreditNotes((creditNotes) => [...creditNotes, ...nextCreditNotes]);
      };

      getCreditNotes().catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();

    const getCreditNotesCount = async () => {
      const count = await fetchClientCreditNotesCount();
      setCreditNotesCount(count);
    };

    const getCreditNotes = async () => {
      const creditNotes = await fetchClientCreditNotes();
      setLoading(false);
      setCreditNotes(creditNotes);
    };

    getCreditNotesCount().catch(() => (err) => {
      console.log(err);
    });

    getCreditNotes().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteCreditNoteID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);

    const { data } = await axiosInstance.delete(
      `${endpoint}/creditNotes/${deleteCreditNoteID}`
    );

    if (data) {
      if (!data.success) {
        toast.error(data.message);

        return;
      }

      toast.success("Note de crédit supprimée avec succès !");

      const creditNotes = await fetchClientCreditNotes();
      setCreditNotes(creditNotes);
    }

    setLoading(false);
  };

  const addNewCreditNote = async () => {
    if (!client._id || client._id === "") {
      toast.error("Veuillez sélectionner un client !");

      return;
    }

    await axiosInstance
      .post(`${endpoint}/creditNotes`, {
        client: client,
        products: [],
        infos: infos,
        payments: [],
        comments: [],
      })
      .then((response) => {
        if (response.data.success) {
          const { _id } = response.data.result;

          toast.success("Note de crédit créée avec succès !");
          navigate(`/creditNotes/${_id}`);

          return;
        }

        toast.error(response.data.message);
      })
      .catch(() =>
        toast.error(
          "Une erreur est survenue lors de la création de la note de crédit !"
        )
      );
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="content">
        <div className="above-table">
          <div className="title-search-container">
            <div className="title">
              {client?.clientNumber} - {client?.firstname} {client?.lastname}
            </div>
            <form onSubmit={onSearchSubmit}>
              <TextField
                id="search-bar"
                style={{ width: 258 }}
                label="Numéro de ticket"
                variant="outlined"
                size="small"
                type="number"
                name="search"
                value={search}
                onChange={onSearchChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
          </div>

          <div className="create-button" onClick={addNewCreditNote}>
            <div className="create-button-icons">
              <Add />
              <FolderSpecial />
            </div>

            <span>Note de crédit</span>
          </div>
        </div>

        <DataGrid
          rows={creditNotes}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          columns={columns}
          pageSize={LIMIT}
          rowsPerPageOptions={[LIMIT]}
          rowCount={creditNotesCount}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          onPageChange={handlePageChange}
        />
      </div>

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
      />
    </>
  );
};

export default ClientCreditNotes;
