import { Box, Fade, Modal } from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "services/axios";
import { endpoint } from "utils/API";
import { toast } from "react-toastify";
import { Add, Person } from "@material-ui/icons";
import { handleOnKeyDownEnter } from "utils/misc";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TEMPLATE_CLIENT = {
  firstname: "",
  lastname: "",

  street: "",
  postCode: "",
  city: "",
  country: "BE",

  phoneNumber: "",
  mobileNumber: "",

  birthDate: new Date().toISOString().split("T")[0],
  vatNumber: "",
  emailBusiness: "",
  emailPrivate: "",
  additionalInfo: "",
};

const NewClientModal = ({
  isModalCreateNewClientOpen,
  closeModalCreateNewClient,
}) => {
  const [newClientForm, setNewClientForm] = useState({ ...TEMPLATE_CLIENT });

  const addNewClient = async (e) => {
    e.preventDefault();

    const newClient = { ...newClientForm };

    await axiosInstance
      .post(`${endpoint}/clients`, newClient)
      .then(async () => {
        toast.success("Client ajouté avec succès !");
        setNewClientForm({ ...TEMPLATE_CLIENT });
        closeModalCreateNewClient();
      })
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'ajout du client !")
      );
  };

  const updateNewClientForm = (e) => {
    const field = {
      name: e.target.name,
      value: e.target.value,
    };

    setNewClientForm({ ...newClientForm, [field.name]: field.value });
  };

  const retrieveCity = async (e) => {
    e.preventDefault();

    if (newClientForm.postCode.length !== 4 || isNaN(newClientForm.postCode)) {
      return;
    }

    const { data } = await axiosInstance.get(`${endpoint}/belgiumCities`, {
      params: {
        postCode: newClientForm.postCode,
      },
    });

    if (data) {
      if (data.length === 0) {
        toast.error("Aucune ville trouvée pour ce code postal !");
        return;
      }

      setNewClientForm((prevState) => ({
        ...prevState,
        city: data.result.fields.column_2,
        country: "BE",
      }));
    }
  };

  return (
    <Modal
      open={isModalCreateNewClientOpen}
      onClose={closeModalCreateNewClient}
    >
      <Fade in={isModalCreateNewClientOpen}>
        <Box sx={boxStyle}>
          <h1 className="addProductTitle">Nouveau client</h1>
          <form
            className="addProductForm"
            onSubmit={addNewClient}
            onKeyDown={handleOnKeyDownEnter}
          >
            <div className="newUserItem">
              <label>Numéro de TVA (laissez vide si particulier)</label>
              <input
                type="text"
                placeholder="BE 0000.000.000"
                name="vatNumber"
                value={newClientForm.vatNumber}
                onChange={updateNewClientForm}
              />
            </div>
            <div className="newUserItem">
              <label>Prénom</label>
              <input
                type="text"
                placeholder="John"
                name="firstname"
                value={newClientForm.firstname}
                onChange={updateNewClientForm}
                required
              />
            </div>
            <div className="newUserItem">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Smith"
                name="lastname"
                value={newClientForm.lastname}
                onChange={updateNewClientForm}
                required
              />
            </div>

            <div className="newUserItem">
              <label>Rue n°</label>
              <input
                type="text"
                placeholder="Rue de la réussite, 7"
                name="street"
                value={newClientForm.street}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>Code Postal</label>
              <input
                type="text"
                placeholder="7000"
                name="postCode"
                value={newClientForm.postCode}
                onChange={updateNewClientForm}
                onBlur={retrieveCity}
              />
            </div>

            <div className="newUserItem">
              <label>Localité</label>
              <input
                type="text"
                placeholder="Mons"
                name="city"
                value={newClientForm.city}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>Pays</label>
              <input
                type="text"
                placeholder="BE"
                name="country"
                value={newClientForm.country}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>Téléphone</label>
              <input
                type="text"
                placeholder="069/00.00.00"
                name="phoneNumber"
                value={newClientForm.phoneNumber}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>GSM</label>
              <input
                type="text"
                placeholder="0475/00.00.00"
                name="mobileNumber"
                value={newClientForm.mobileNumber}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>Date de naissance</label>
              <input
                type="date"
                name="birthDate"
                value={newClientForm.birthDate}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>E-mail (business)</label>
              <input
                type="email"
                placeholder="john.smith@business.com"
                name="emailBusiness"
                value={newClientForm.emailBusiness}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>E-mail (privé)</label>
              <input
                type="email"
                placeholder="john.smith@gmail.com"
                name="emailPrivate"
                value={newClientForm.emailPrivate}
                onChange={updateNewClientForm}
              />
            </div>

            <div className="newUserItem">
              <label>Informations complémentaires</label>
              <textarea
                rows="5"
                name="additionalInfo"
                value={newClientForm.additionalInfo}
                onChange={updateNewClientForm}
              />
            </div>

            <button type="submit" className="create-button">
              <div className="homeLinkIcons">
                <Add />
                <Person />
              </div>
              <span>Client</span>
            </button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewClientModal;
