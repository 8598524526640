import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axios";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import { Add } from "@material-ui/icons";
import NewVehicleModal from "./NewVehicle";
import { roundToDecimalPlaces } from "../../utils/Financials";
import Product from "../../pages/Product/Product";
import Vehicle from "../../pages/Vehicle/Vehicle";

const LIMIT = 10;

const VehiclesTable = ({
  isModalAddVehicleOpen,
  closeModalAddVehicle,
  addItemToInvoice,
}) => {
  const [searchItemNumber, setSearchItemNumber] = useState("");
  const [searchLocalization, setSearchLocalization] = useState("");
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesCount, setVehiclesCount] = useState(0);
  const [isModalCreateNewVehicleOpen, setIsModalCreateNewVehicleOpen] =
    useState(false);
  const [isModalEditVehicleOpen, setIsModalEditVehicleOpen] = useState(false);
  const [vehicleToEditId, setVehicleToEditId] = useState("");

  const fetchVehiclesCount = async () => {
    const { data } = await axiosInstance.get(`${endpoint}/vehicles/count`, {
      params: {
        search: searchItemNumber || searchLocalization,
        fields: searchLocalization
          ? ["localization"]
          : searchItemNumber
          ? ["itemNumber"]
          : "",
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result;
    }

    return 0;
  };

  const fetchVehicles = async (page) => {
    const { data } = await axiosInstance.get(`${endpoint}/vehicles`, {
      params: {
        limit: LIMIT,
        offset: !page ? 0 : page * LIMIT,
        search: searchItemNumber || searchLocalization,
        fields: searchItemNumber ? ["itemNumber"] : ["localization"],
      },
    });

    if (data) {
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      return data.result.docs;
    }

    return [];
  };

  const onSubmitSearchVehicle = async (e) => {
    e.preventDefault();

    setLoadingVehicles(true);

    const getVehiclesCount = async () => {
      const vehiclesCount = await fetchVehiclesCount();
      setVehiclesCount(vehiclesCount);
    };

    const getVehicles = async () => {
      const vehicles = await fetchVehicles();
      setLoadingVehicles(false);
      setVehicles(vehicles);
    };

    getVehiclesCount().catch((err) => {
      console.log(err);
    });

    getVehicles().catch((err) => {
      console.log(err);
      setLoadingVehicles(false);
    });
  };

  const onSearchItemNumberChange = (e) => {
    setSearchLocalization("");
    setSearchItemNumber(e.target.value);
  };

  const onSearchLocalizationChange = (e) => {
    setSearchItemNumber("");
    setSearchLocalization(e.target.value);
  };

  const openModalCreateNewVehicle = (e) => {
    e.preventDefault();
    setIsModalCreateNewVehicleOpen(true);
  };

  const closeModalCreateNewVehicle = () =>
    setIsModalCreateNewVehicleOpen(false);

  const openModalEditVehicle = (e) => {
    e.preventDefault();

    const vehicleId = e.currentTarget.dataset.id;

    if (!vehicleId) return;

    setVehicleToEditId(vehicleId);
    setIsModalEditVehicleOpen(true);
  };

  const closeModalEditVehicle = () => {
    setIsModalEditVehicleOpen(false);
  };

  const columns = [
    {
      field: "itemNumber",
      headerName: "Référence",
      width: 180,
    },
    {
      field: "brand",
      headerName: "Marque",
      width: 80,
    },
    {
      field: "name",
      headerName: "Nom",
      width: 200,
    },
    {
      field: "sellingPriceWithTaxes",
      headerName: "Prix de vente unitaire TTC",
      width: 220,
      align: "right",
      renderCell: (params) => {
        return (
          <div className="productList__price">
            {roundToDecimalPlaces(params.row.sellingPriceWithTaxes, 2, true)} €
          </div>
        );
      },
    },
    { field: "stock", headerName: "Quantité en stock", width: 140 },
    {
      field: "localization",
      headerName: "Localisation",
      width: 120,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <button
              className="userListInvoices"
              data-params={JSON.stringify(params.row)}
              onClick={addItemToInvoice}
            >
              Ajouter
            </button>
            <button
              className="userListEdit"
              data-id={params.row.id}
              onClick={openModalEditVehicle}
            >
              Modifier
            </button>
          </>
        );
      },
    },
  ];

  const handlePageChangeVehicles = (page) => {
    if (vehicles.length <= page * LIMIT) {
      setLoadingVehicles(true);

      const getVehiclesCount = async () => {
        const productsCount = await fetchVehiclesCount();
        setVehiclesCount(productsCount);
      };

      const getVehicles = async () => {
        const nextVehicles = await fetchVehicles(page);
        setLoadingVehicles(false);
        setVehicles((vehicles) => [...vehicles, ...nextVehicles]);
      };

      getVehiclesCount().catch((err) => {
        console.error(err);
      });

      getVehicles().catch((err) => {
        console.log(err);
        setLoadingVehicles(false);
      });
    }
  };

  useEffect(() => {
    if (
      isModalAddVehicleOpen &&
      !isModalCreateNewVehicleOpen &&
      !isModalEditVehicleOpen
    ) {
      const getVehiclesCount = async () => {
        const vehiclesCount = await fetchVehiclesCount();
        setVehiclesCount(vehiclesCount);
      };

      const getVehicles = async () => {
        const nextVehicles = await fetchVehicles(0);
        setLoadingVehicles(false);
        setVehicles(nextVehicles);
      };

      getVehiclesCount().catch((err) => {
        console.error(err);
      });

      getVehicles().catch((err) => {
        console.log(err);
        setLoadingVehicles(false);
      });
    }
  }, [
    isModalAddVehicleOpen,
    isModalCreateNewVehicleOpen,
    isModalEditVehicleOpen,
  ]);

  return (
    <>
      <Dialog
        open={isModalAddVehicleOpen}
        onClose={closeModalAddVehicle}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Rechercher un véhicule</DialogTitle>
        <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
          <DialogContent>
            <form onSubmit={onSubmitSearchVehicle}>
              <TextField
                id="search-bar-item-number"
                style={{ width: 258 }}
                label="Référence du produit"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={searchItemNumber}
                onChange={onSearchItemNumberChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
            <form onSubmit={onSubmitSearchVehicle}>
              <TextField
                id="search-bar-item-localization"
                style={{ width: 258 }}
                label="Localisation"
                variant="outlined"
                size="small"
                type="text"
                name="search"
                value={searchLocalization}
                onChange={onSearchLocalizationChange}
              />
              <IconButton type="submit" aria-label="search">
                <SearchIcon style={{ fill: "#E4221D" }} />
              </IconButton>
            </form>
          </DialogContent>
          <div className="addProductItem">
            <div className="create-button" onClick={openModalCreateNewVehicle}>
              <div className="create-button-icons">
                <Add />
              </div>
              <span>Vehicule</span>
            </div>
          </div>
        </div>

        <DialogContent style={{ height: 800 }}>
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={loadingVehicles}
            rows={vehicles}
            getRowId={(row) => row._id}
            columns={columns}
            pageSize={LIMIT}
            rowsPerPageOptions={[LIMIT]}
            rowCount={vehiclesCount}
            onPageChange={handlePageChangeVehicles}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalAddVehicle}>Annuler</Button>
        </DialogActions>
      </Dialog>

      <NewVehicleModal
        isModalCreateNewVehicleOpen={isModalCreateNewVehicleOpen}
        closeModalCreateNewVehicle={closeModalCreateNewVehicle}
      />

      <Dialog
        open={isModalEditVehicleOpen}
        onClose={closeModalEditVehicle}
        fullWidth
        maxWidth="lg"
      >
        <Vehicle
          id={vehicleToEditId}
          closeModalEditVehicle={closeModalEditVehicle}
        />
      </Dialog>
    </>
  );
};

export default VehiclesTable;
