import { roundForCash } from "../../utils/Financials";

const calculateAmountDueByClient = (invoice) => {
  let totalWithTaxesRoundedCash = false;
  let totalPayment = 0;
  for (let i in invoice.payments) {
    const payment = invoice.payments[i];
    if (payment.paymentMethod === "Espèces") {
      totalWithTaxesRoundedCash = roundForCash(invoice.totalWithTaxes);
    }
    totalPayment += payment.paymentAmount;
  }
  return totalWithTaxesRoundedCash
    ? totalWithTaxesRoundedCash - totalPayment === 0
      ? 0
      : invoice.totalWithTaxes - totalPayment
    : invoice.totalWithTaxes - totalPayment;
};

const getStructuredCommunication = (documentDate, documentNumber) => {
  if (!documentDate || !documentNumber) {
    return "";
  }

  const invoiceDate = new Date(documentDate);

  if (isNaN(invoiceDate.getTime())) {
    return "";
  }

  const invoiceNumber = Number(documentNumber);

  if (isNaN(invoiceNumber)) {
    return "";
  }

  const paddedInvoiceNumber = invoiceNumber.toString().padStart(6, "0");
  const tenDigits = invoiceDate.getFullYear().toString() + paddedInvoiceNumber;

  if (tenDigits.length !== 10) {
    return "";
  }

  const lastTwoDigits = parseInt(tenDigits, 10) % 97;

  return (
    tenDigits.substring(0, 3) +
    "/" +
    tenDigits.substring(3, 7) +
    "/" +
    tenDigits.substring(7, 10) +
    lastTwoDigits.toString().padStart(2, "0")
  );
};

export { calculateAmountDueByClient, getStructuredCommunication };
