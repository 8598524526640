import FeaturedInfo from "../../components/FeaturedInfo";
import "../../css/page/home.css";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  BarChart,
  Folder,
  FolderOpen,
  Note,
  Person,
  Settings,
  ShoppingCart,
  ShoppingCartOutlined,
  TwoWheeler,
} from "@material-ui/icons";
import Ticket from "../Tickets/Ticket";
import { StickyNote2 } from "@mui/icons-material";

const Home = () => {
  return (
    <div className="home">
      <div className="homeTitleContainer">
        <div className="homeTitle">Actions rapides</div>
        <div className="homeSubtitle">Je souhaite créer un.e nouveau.lle</div>
      </div>
      <div className="homeLinksContainer">
        <Link to={"/clients"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <FolderOpen />
          </div>

          <span>Facture</span>
        </Link>
        <Link to={"/ticket"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <StickyNote2 />
          </div>

          <span>Ticket</span>
        </Link>
        <Link to={"/tickets"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <BarChart />
          </div>

          <span>Bilan quotidien</span>
        </Link>
        <Link to={"/newClient"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <Person />
          </div>

          <span>Client</span>
        </Link>
        <Link to={"/newProduct"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <ShoppingCartOutlined />
          </div>

          <span>Produit</span>
        </Link>
        <Link to={"/newMaintenance"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <Settings />
          </div>

          <span>Travail</span>
        </Link>
        <Link to={"/newVehicle"} className="homeLink">
          <div className="homeLinkIcons">
            <Add />
            <TwoWheeler />
          </div>

          <span>Véhicule</span>
        </Link>
      </div>
      {/*<FeaturedInfo />*/}
      {/*<Chart data={userData} title="Client Analytics" grid dataKey="Active Client"/>*/}
      {/*<div className="homeWidgets">*/}
      {/*  <WidgetSm/>*/}
      {/*  <WidgetLg/>*/}
      {/*</div>*/}
    </div>
  );
};

export default Home;
