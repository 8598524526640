import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Conditions from "../Conditions";
import VehicleConditions from "../VehicleConditions";
import Logo from "../../assets/img/TT2000_Logo.png";
import Suzuki from "../../assets/img/logo_suzuki.png";
import Benelli from "../../assets/img/logo_benelli.png";
import Sym from "../../assets/img/logo_sym.png";
import Peugeot from "../../assets/img/logo_peugeot.png";
import InvoiceTableHeader from "../InvoiceTable/InvoiceTableHeader";
import InvoiceTableRow from "../InvoiceTable/InvoiceTableRow";
import InvoiceTableFooter from "../InvoiceTable/InvoiceTableFooter";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    height: 84,
    backgroundColor: "#F90000",
    position: "relative",
  },
  headerLeft: {
    position: "absolute",
    top: 27,
    left: 169,
    width: "50%",
  },
  invoiceDate: {
    flexDirection: "row",
    // fontFamily: 'Roboto',
    // fontStyle: 'normal',
    // fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 12,
    marginBottom: 8,
    // lineHeight: 14,
  },
  clientNumber: {
    flexDirection: "row",
    // fontFamily: 'Roboto',
    // fontStyle: 'normal',
    // fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 12,
    // lineHeight: 14,
  },
  headerRight: {
    position: "absolute",
    top: 31,
    left: 454,
    width: "50%",
  },
  invoiceNumber: {
    // fontFamily: 'Roboto',
    // fontStyle: 'normal',
    fontWeight: 600,
    color: "#FFFFFF",
    fontSize: 18,
    // lineHeight: 21
  },
  page: {
    flexDirection: "column",
    fontSize: 10,
    padding: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section: {
    padding: 10,
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoicePDF = ({
  invoiceNumber,
  invoiceDate,
  client,
  infos,
  invoiceProducts,
  comments,
  payments,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {/*<View style={styles.header}>*/}
        {/*  <View style={styles.headerLeft}>*/}
        {/*    <Text style={styles.invoiceDate}>{invoiceDate}</Text>*/}
        {/*    <Text style={styles.clientNumber}>*/}
        {/*      Client N° {client.clientNumber}*/}
        {/*    </Text>*/}
        {/*  </View>*/}
        {/*  <View style={styles.headerRight}>*/}
        {/*    <Text style={styles.invoiceNumber}>*/}
        {/*      N° {generateInvoiceNumber()}*/}
        {/*    </Text>*/}
        {/*  </View>*/}
        {/*</View>*/}
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.section}>
              <Image src={Logo} style={{ width: 200, height: 100 }} />
              <View style={styles.row}>
                <Image src={Suzuki} style={{ width: 40, height: 40 }} />
                <Image src={Benelli} style={{ width: 40, height: 40 }} />
                <Image src={Sym} style={{ width: 40, height: 40 }} />
                <Image src={Peugeot} style={{ width: 40, height: 40 }} />
              </View>
              <Text>
                {infos.street}, {infos.streetNumber}
              </Text>
              <Text>
                {infos.postalCode} {infos.city}
              </Text>
              <Text>TVA : {infos.vatNumber}</Text>
              <Text>Tel : {infos.phone}</Text>
              <Text>&ensp;</Text>
              <Text>Web : {infos.website}</Text>
              <Text>E-mail : {infos.email}</Text>
            </View>
            <View style={styles.section}>
              <Text>{infos.titleText}</Text>
              <Text>&ensp;</Text>
              <Text>&ensp;</Text>
              {client && (
                <>
                  <Text>
                    {client.clientNumber &&
                      `N° de client : ${client.clientNumber}`}
                  </Text>
                  <Text>
                    {client.firstname} {client.lastname}
                  </Text>
                  <Text>{client.street}</Text>
                  <Text>
                    {client.postCode} {client.city}
                  </Text>
                  <Text>{client.vatNumber && `TVA : ${client.vatNumber}`}</Text>
                  <Text>
                    {client.phoneNumber && `Tel : ${client.phoneNumber}`}
                  </Text>
                  <Text>
                    {client.emailBusiness && `E-mail : ${client.emailBusiness}`}
                  </Text>
                </>
              )}
            </View>
          </View>

          <View style={styles.tableContainer}>
            <InvoiceTableHeader
              client={client}
              invoiceProducts={invoiceProducts}
              invoiceNumber={invoiceNumber}
              invoiceDate={invoiceDate}
            />
            <InvoiceTableRow items={invoiceProducts} comments={comments} />
          </View>

          <InvoiceTableFooter
            items={invoiceProducts}
            infos={infos}
            payments={payments}
          />

          <Conditions />
          <VehicleConditions />
        </View>
        <Text
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            fontSize: 8,
          }}
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default React.memo(InvoicePDF);
