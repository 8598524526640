import "../css/component/sidebar.css";

import {
  Add,
  LineStyle,
  PermIdentity,
  Storefront,
  BarChart,
  Settings,
  TwoWheeler,
  Motorcycle,
  FolderOpen,
  FormatListNumbered,
  NoteAdd,
  LibraryAddOutlined,
  PersonAdd,
  AddShoppingCart,
  Build,
  ArrowBack,
  FolderShared,
  FolderSpecial,
  TwoWheelerRounded,
  TwoWheelerOutlined,
  TwoWheelerTwoTone,
} from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import {
  ArrowBackIosNew,
  CarRepair,
  FolderOff,
  Logout,
  StickyNote2,
  TireRepair,
} from "@mui/icons-material";
import React, { useState } from "react";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const [logo, setLogo] = useState('');

  import(`assets/img/${process.env.REACT_APP_LOGO_PATH||""}TT2000_Logo.png`).then((module) => {
    setLogo(module.default);
  });
  const collapseSideBar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <UserContext.Consumer>
      {({ user, setUser, logoutUser }) => (
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <div className="sidebarWrapper">
            <div className="topbar">
              <div className="topbarWrapper">
                <div className="topLeft">
                  <Link
                    to={"/"}
                    className="link"
                    style={{ display: "flex", gap: 20 }}
                  >
                    <span className="top-bar-title">Facturation</span>
                    <img
                      src={logo}
                      alt="Logo TT2000"
                      className="top-bar-logo"
                    />
                  </Link>
                </div>
                <div className="topRight">
                  {/*<div className="topbarIconContainer">*/}
                  {/*  <NotificationsNone />*/}
                  {/*  <span className="topIconBadge">2</span>*/}
                  {/*</div>*/}
                  {/*<div className="topbarIconContainer">*/}
                  {/*  <Language />*/}
                  {/*  <span className="topIconBadge">2</span>*/}
                  {/*</div>*/}
                  {/*<div className="topbarIconContainer">*/}
                  {/*  <Settings />*/}
                  {/*</div>*/}
                  {/*<img src="https://via.placeholder.com/512x512" alt="" className="topAvatar" />*/}
                </div>
              </div>
            </div>
            <div className="sidebarMenuContainer">
              <div className="sidebarMenu" style={{ marginBottom: 40 }}>
                <ul className="sidebarList">
                  <Link to="/" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/" ? "active" : ""
                      }`}
                    >
                      <Add
                        className={`sidebarIcon ${
                          pathname === "/" ? "active" : ""
                        }`}
                      />
                      <span>Actions rapides</span>
                    </li>
                  </Link>
                  {/*<li className="sidebarListItem">*/}
                  {/*  <Timeline className="sidebarIcon" />*/}
                  {/*  Analytics*/}
                  {/*</li>*/}
                  {/*<li className="sidebarListItem">*/}
                  {/*  <TrendingUp className="sidebarIcon" />*/}
                  {/*  Sales*/}
                  {/*</li>*/}
                </ul>
              </div>
              <div
                className="sidebarMenu"
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                <ul className="sidebarList">
                  <Link to="/clients" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/clients" ? "active" : ""
                      }`}
                    >
                      <PermIdentity
                        className={`sidebarIcon ${
                          pathname === "/clients" ? "active" : ""
                        }`}
                      />
                      <span>Clients</span>
                    </li>
                  </Link>
                  <Link to="/products" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/products" ? "active" : ""
                      }`}
                    >
                      <Storefront
                        className={`sidebarIcon ${
                          pathname === "/products" ? "active" : ""
                        }`}
                      />
                      <span>Produits</span>
                    </li>
                  </Link>
                  <Link to="/maintenances" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/maintenances" ? "active" : ""
                      }`}
                    >
                      <Settings
                        className={`sidebarIcon ${
                          pathname === "/maintenances" ? "active" : ""
                        }`}
                      />
                      <span>Travaux</span>
                    </li>
                  </Link>
                  <Link to="/vehicles" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/vehicles" ? "active" : ""
                      }`}
                    >
                      <TwoWheeler
                        className={`sidebarIcon ${
                          pathname === "/vehicles" ? "active" : ""
                        }`}
                      />
                      <span>Véhicules</span>
                    </li>
                  </Link>
                  <Link to="/vehiclesUsed" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/vehiclesUsed" ? "active" : ""
                      }`}
                    >
                      <TireRepair
                        className={`sidebarIcon ${
                          pathname === "/vehiclesUsed" ? "active" : ""
                        }`}
                      />
                      <span>Véhicules d'occasion</span>
                    </li>
                  </Link>
                  <Link to="/tickets" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/tickets" ? "active" : ""
                      }`}
                    >
                      <StickyNote2
                        className={`sidebarIcon ${
                          pathname === "/tickets" ? "active" : ""
                        }`}
                      />
                      <span>Tickets</span>
                    </li>
                  </Link>
                  <Link to="/invoices" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/invoices" ? "active" : ""
                      }`}
                    >
                      <FolderOpen
                        className={`sidebarIcon ${
                          pathname === "/invoices" ? "active" : ""
                        }`}
                      />
                      <span>Factures</span>
                    </li>
                  </Link>
                  <Link to="/creditNotes" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/creditNotes" ? "active" : ""
                      }`}
                    >
                      <FolderSpecial
                        className={`sidebarIcon ${
                          pathname === "/creditNotes" ? "active" : ""
                        }`}
                      />
                      <span>Notes de crédit</span>
                    </li>
                  </Link>
                  <Link to="/chassis" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/chassis" ? "active" : ""
                      }`}
                    >
                      <FormatListNumbered
                        className={`sidebarIcon ${
                          pathname === "/chassis" ? "active" : ""
                        }`}
                      />
                      <span>Recherche de chassis</span>
                    </li>
                  </Link>
                  <Link to="/ticketsReport" className="link">
                    <li
                      className={`sidebarListItem ${
                        pathname === "/ticketsReport" ? "active" : ""
                      }`}
                    >
                      <BarChart
                        className={`sidebarIcon ${
                          pathname === "/ticketsReport" ? "active" : ""
                        }`}
                      />
                      <span>Bilan journalier des tickets</span>
                    </li>
                  </Link>
                  {/*<Link to="/ticket" className="link">*/}
                  {/*  <li*/}
                  {/*    className={`sidebarListItem ${*/}
                  {/*      pathname === "/ticket" ? "active" : ""*/}
                  {/*    }`}*/}
                  {/*  >*/}
                  {/*    <NoteAdd*/}
                  {/*      className={`sidebarIcon ${*/}
                  {/*        pathname === "/ticket" ? "active" : ""*/}
                  {/*      }`}*/}
                  {/*    />*/}
                  {/*    <span>Vente comptoir</span>*/}
                  {/*  </li>*/}
                  {/*</Link>*/}
                </ul>
              </div>

              <div className="sidebarMenu sidebarLogout">
                <Link to="/login" className="link" onClick={logoutUser}>
                  <li className="sidebarListItem">
                    <Logout className="sidebarIcon" />
                    <span>Se déconnecter</span>
                  </li>
                </Link>
                <ArrowBackIosNew
                  onClick={collapseSideBar}
                  className="sidebarIconLogout"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default Sidebar;
