import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rowTerms: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontSize: 8,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderLeftColor: "#bff0fd",
    borderRightColor: "#bff0fd",
    padding: 8,
  },
});

const VehicleConditions = () => {
  return (
    <View style={styles.rowTerms} break>
      <Text>
        Vos données à caractère personnel sont transmises au fabricant/à
        l’importateur du véhicule que vous avez acquis afin qu’il puisse
        respecter ses obligations légales et contractuelles. Pour plus
        d’informations concernant la manière dont le fabricant/l’importateur
        traite vos données à caractère personnel, vous devez vérifier la
        politique de confidentialité sur le site internet du fabricant/de
        l’importateur.
      </Text>
    </View>
  );
};

export default VehicleConditions;
