import { roundToDecimalPlaces } from "utils/Financials";

const computePurchasePrice = (item) => {
  const sellingPrice = roundToDecimalPlaces(item.sellingPrice);
  const margin = roundToDecimalPlaces(item.margin);
  const difference = roundToDecimalPlaces((sellingPrice * margin) / 100);

  return roundToDecimalPlaces(sellingPrice - difference);
};

const computeSellingPrice = (item) => {
  const purchasePrice = roundToDecimalPlaces(item.purchasePrice);
  const margin = roundToDecimalPlaces(item.margin);
  const vatPercentage = roundToDecimalPlaces(item.vatPercentage);
  const sellingPriceWithTaxes = roundToDecimalPlaces(
    item.sellingPriceWithTaxes
  );

  if (sellingPriceWithTaxes && sellingPriceWithTaxes > 0) {
    return roundToDecimalPlaces(
      sellingPriceWithTaxes / (1 + vatPercentage / 100)
    );
  }

  return roundToDecimalPlaces(purchasePrice * (1 + margin / 100));
};

const computeMargin = (item) => {
  const purchasePrice = roundToDecimalPlaces(item.purchasePrice);
  const sellingPrice = roundToDecimalPlaces(item.sellingPrice);

  if (
    !purchasePrice ||
    !sellingPrice ||
    purchasePrice <= 0 ||
    sellingPrice <= 0
  ) {
    return 0;
  }

  const difference = roundToDecimalPlaces(sellingPrice - purchasePrice);

  return roundToDecimalPlaces((difference / sellingPrice) * 100);
};

const computeSellingPriceWithTaxes = (item) => {
  const sellingPrice = roundToDecimalPlaces(item.sellingPrice);
  const vatPercentage = roundToDecimalPlaces(item.vatPercentage);

  if (!sellingPrice || sellingPrice <= 0 || isNaN(vatPercentage)) {
    return 0;
  }

  return roundToDecimalPlaces(sellingPrice * (1 + vatPercentage / 100));
};

export {
  computePurchasePrice,
  computeSellingPrice,
  computeMargin,
  computeSellingPriceWithTaxes,
};
