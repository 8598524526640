const infos = {
  titleText:
    "40 ans d'expérience\nSuzuki Benelli Sym Peugeot\nVente véhicules neufs et occasions\nVêtements, accessoires, réparation...",
  street: "Route de Mons",
  streetNumber: "153",
  postalCode: "7390",
  city: "Wasmuel",
  vatNumber: "BE 0793.991.619",
  phone: "065/77.07.94",
  website: "www.tt2000.be",
  email: "tt2000-gestion@hotmail.com",
  iban: "BE64 7320 6732 7852",
  bic: "CREGBEBB",
  structuredCommunication: "",
};

export default infos;
