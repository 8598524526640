import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { roundForCash, roundToDecimalPlaces } from "../../utils/Financials";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 18,
    // fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  infos: {
    flexDirection: "column",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    justifyContent: "space-between",
    flexGrow: 1,
    marginTop: 10,
    padding: 8,
  },
});

const TotalForCash = ({ value }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.description}>
        Total arrondi (Paiement en espèces){" "}
      </Text>
      <Text style={styles.total}>{roundForCash(value)} €</Text>
    </View>
  );
};

export default TotalForCash;
