import axiosInstance from "../../services/axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../css/page/newUser.css";
import { useNavigate } from "react-router-dom";
import { endpoint } from "../../utils/API";
import { CircularProgress, TextField } from "@mui/material";
import { Add, Person, TwoWheeler } from "@material-ui/icons";
import { TEMPLATE_CLIENT } from "../../templates";
import { handleOnKeyDownEnter } from "utils/misc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { frFR as localeDatePickerFR } from "@mui/x-date-pickers/locales/frFR";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayJs from "dayjs";

const NewClient = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ ...TEMPLATE_CLIENT });
  const [loadingNextClientNumber, setLoadingNextClientNumber] = useState(true);

  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateBirthdayForm = (date) => {
    date = dayJs(date).format("YYYY-MM-DD");
    setForm({ ...form, birthDate: date });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    const newUser = { ...form };

    await axiosInstance
      .post(`${endpoint}/clients`, newUser)
      .then(() => {
        toast.success("Client ajouté avec succès !");
        setForm({ ...TEMPLATE_CLIENT });
        navigate("/clients");
      })
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'ajout du client !")
      );
  };

  useEffect(() => {
    const getNextClientNumber = async () => {
      const { data } = await axiosInstance.get(
        `${endpoint}/clients/nextClientNumber`
      );

      if (data) {
        if (!data.success) {
          toast.error(data.message);
          return;
        }

        setForm((prevState) => ({
          ...prevState,
          clientNumber: data.result,
        }));
      }

      setLoadingNextClientNumber(false);
    };

    getNextClientNumber().catch((err) => console.log(err));
  }, []);

  const retrieveCity = async (e) => {
    e.preventDefault();

    if (form.postCode.length !== 4 || isNaN(form.postCode)) {
      return;
    }

    const { data } = await axiosInstance.get(`${endpoint}/belgiumCities`, {
      params: {
        postCode: form.postCode,
      },
    });

    if (data) {
      if (data.length === 0) {
        toast.error("Aucune ville trouvée pour ce code postal !");
        return;
      }

      setForm((prevState) => ({
        ...prevState,
        city: data.result.fields.column_2,
        country: "BE",
      }));
    }
  };

  return (
    <div className="content">
      <h1 className="title">Nouveau client</h1>
      <form
        className="addProductForm"
        onSubmit={onSubmit}
        onKeyDown={handleOnKeyDownEnter}
      >
        <div className="newUserItem">
          <label>Prochain numéro de client (non modifiable)</label>
          {loadingNextClientNumber ? (
            <CircularProgress size={16} />
          ) : (
            <TextField
              name="clientNumber"
              defaultValue={form.clientNumber}
              disabled
              inputProps={{ style: { textAlign: "center" } }}
            ></TextField>
          )}
        </div>
        <div className="newUserItem">
          <label>Numéro de TVA (laissez vide si particulier)</label>
          <TextField
            type="text"
            placeholder="BE 0000.000.000"
            name="vatNumber"
            value={form.vatNumber}
            onChange={updateForm}
          />
        </div>
        <div className="newUserItem">
          <label>Prénom</label>
          <TextField
            type="text"
            placeholder="John"
            name="firstname"
            value={form.firstname}
            onChange={updateForm}
            required
          />
        </div>
        <div className="newUserItem">
          <label>Nom</label>
          <TextField
            type="text"
            placeholder="Smith"
            name="lastname"
            value={form.lastname}
            onChange={updateForm}
            required
          />
        </div>

        <div className="newUserItem">
          <label>Rue n°</label>
          <TextField
            type="text"
            placeholder="Rue de la réussite, 7"
            name="street"
            value={form.street}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>Code Postal</label>
          <TextField
            type="text"
            placeholder="7000"
            name="postCode"
            value={form.postCode}
            onChange={updateForm}
            onBlur={retrieveCity}
          />
        </div>

        <div className="newUserItem">
          <label>Localité</label>
          <TextField
            type="text"
            placeholder="Mons"
            name="city"
            value={form.city}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>Pays</label>
          <TextField
            type="text"
            placeholder="BE"
            name="country"
            value={form.country}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>Téléphone</label>
          <TextField
            type="text"
            placeholder="069/00.00.00"
            name="phoneNumber"
            value={form.phoneNumber}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>GSM</label>
          <TextField
            type="text"
            placeholder="0475/00.00.00"
            name="mobileNumber"
            value={form.mobileNumber}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>Date de naissance</label>
          <LocalizationProvider
            localeText={
              localeDatePickerFR.components.MuiLocalizationProvider.defaultProps
                .localeText
            }
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              variant="inline"
              openTo="year"
              views={["day", "month", "year"]}
              label=""
              helperText="Selectionnez un jour, un mois et  une année "
              value={form.birthDate}
              onChange={updateBirthdayForm}
              renderInput={(props) => <TextField {...props} />}
            />
          </LocalizationProvider>
        </div>

        <div className="newUserItem">
          <label>E-mail (business)</label>
          <TextField
            type="email"
            placeholder="john.smith@business.com"
            name="emailBusiness"
            value={form.emailBusiness}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>E-mail (privé)</label>
          <TextField
            type="email"
            placeholder="john.smith@gmail.com"
            name="emailPrivate"
            value={form.emailPrivate}
            onChange={updateForm}
          />
        </div>

        <div className="newUserItem">
          <label>Informations complémentaires</label>
          <TextField
            multiline
            name="additionalInfo"
            value={form.additionalInfo}
            onChange={updateForm}
          />
        </div>

        <button type="submit" className="create-button">
          <div className="homeLinkIcons">
            <Add />
            <Person />
          </div>
          <span>Client</span>
        </button>
      </form>
    </div>
  );
};

export default NewClient;
